.externalWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

.wrapper {
  position: fixed;
  width: 90%;
  max-width: 750px;
  background-color: #fefefe;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  border: 2px solid #64acff;
  box-sizing: border-box;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.09);

  .title {
    color: #13171c;
    font-weight: 600;
    font-size: 24px;
    margin-top: 0;
  }

  .middle {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;

    @media all and (max-width: 750px) {
      flex-direction: column;
    }

    .leftBlock {
      width: 50%;
      display: flex;
      flex-direction: column;

      @media all and (max-width: 388px) {
        width: 100%;
      }

      .errorsBlock {
        display: flex;
        flex-direction: column;
        color: red;
        margin-top: 15px;

        span {
          margin-bottom: 5px;
        }
      }

      .infoBlock {
        h4 {
          font-weight: bold;
          font-size: 15px;
        }

        .errorSpan {
          display: flex;
          margin-top: 10px;
          color: #ff7da0;
          font-size: 15px;
        }

        .descInput {
          font: 400 13px Arial;
          height: 100px;
          width: 260px;
          font-size: 15px;
          background-color: #eceff1;
          border: none;
          resize: none;
          padding: 10px;
          box-sizing: border-box;
        }
      }
    }

    .rightBlock {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 30px;

      .errorSpan {
        display: flex;
        margin-top: 10px;
        color: #ff7da0;
        font-size: 15px;
      }

      @media all and (max-width: 750px) {
        margin-top: 30px;
      }
    }
  }

  .linkBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;

    .btnGenerate {
      margin-right: 30px;
    }

    .inputLink {
      width: 90%;
    }

    .copyLink {
      margin-left: 30px;
      color: #64acff;
      font-weight: normal;
      font-size: 16px;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  .endButtons {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;

    .btnCancel {
      background-color: #d2d2d2;
      margin-right: 30px;
    }
  }
}
