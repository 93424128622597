.logoTickerNetwork {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #1B1E22;
}
.logoTickerNetwork sup {
  font-size: 8px;
  line-height: 10px;
}
