@import "../../css/variables";

.paymentHistoryTableBox {
  overflow-x: auto;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 24px;
}
.paymentHistoryTableTitle {
  width: 100%;
  min-width: 340px;
  font-size: 20px;
  line-height: 22px;
  color: #13171C;
  background-color: #ffffff;
  padding: 20px 25px 0;
  border-radius: 8px 8px 0 0;
  box-sizing: border-box;
  margin-bottom: -6px;
}
.paymentHistoryTable {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  overflow: visible;
  min-width: 340px;
  border-radius: 8px;
  margin-top: 0;
}
.paymentHistoryTable table[class*="historyTable"] {
  min-width: auto;
}
.paymentHistoryTable tbody:before {
  content: '';
  display: table-row;
  height: 15px;
}
.paymentHistoryTable th:nth-child(1),
.paymentHistoryTable td:nth-child(1) {
  min-width: 105px;
  width: 25%;
}
.paymentHistoryTable th:nth-child(2),
.paymentHistoryTable td:nth-child(2) {
  min-width: 113px;
  width: 50%;
  text-align: center;
}
.paymentHistoryTable th:nth-child(3),
.paymentHistoryTable td:nth-child(3) {
  min-width: 80px;
  width: 25%;
  text-align: right;
}
.paginator {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
button.emptyBlockButton {
  width: 180px;
  margin-top: 16px;
}

@media screen and (min-width: 370px) {
  .paymentHistoryTable [class*="historyTableEmptyTr"],
  .paymentHistoryTable [class*="historyTableLoaderTr"],
  .paymentHistoryTable [class*="historyTableError"] {
    text-align: center;
  }
  button.emptyBlockButton {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .paymentHistoryTableTitle,
  .paymentHistoryTable {
    min-width: 840px;
  }
  .paymentHistoryTable th:nth-child(1),
  .paymentHistoryTable td:nth-child(1) {
    min-width: 105px;
    width: auto;
  }
  .paymentHistoryTable th:nth-child(2),
  .paymentHistoryTable td:nth-child(2) {
    min-width: 113px;
    width: auto;
    text-align: left;
  }
  .paymentHistoryTable th:nth-child(3),
  .paymentHistoryTable td:nth-child(3) {
    min-width: 135px;
    width: auto;
    text-align: left;
  }
  .paymentHistoryTable th:nth-child(4),
  .paymentHistoryTable td:nth-child(4) {
    min-width: 200px;
  }
  .paymentHistoryTable th:nth-child(5),
  .paymentHistoryTable td:nth-child(5) {
    min-width: 104px;
  }
  .paymentHistoryTable th:last-child,
  .paymentHistoryTable td:last-child {
    min-width: 141px;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .paymentHistoryTableTitle,
  .paymentHistoryTable {
    min-width: 820px;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .historyTableEmptyTr td,
  .historyTableTimeoutErrorTr td,
  .historyTableTimeoutErrorTr td a {
    font-size: 16px;
    line-height: 20px;
  }
}
