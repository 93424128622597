// TODO: move button styles to common or global
button {
  height: 35px;
  background-color: #ff7da0;
  color: #fff;
  border: none;
  cursor: pointer; 
  font-weight: 600;
  font-size: 13px;
  padding: 0 20px;

  &:disabled {
    opacity: 0.4;
  }
}

.saveStatus {
  display: inline-block;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  padding-left: 10px;
  padding-right: 10px;
}

.saveStatusInvalid {
  background-color: rgba(#ff7da0, 0.1);
  color: #ff7da0;
}

.saveStatusSuccess {
  background-color: rgba(#4ee488, 0.1);
  color: #4ee488;
}

.inactive {
  opacity: 0.4;
}
