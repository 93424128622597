@import "../../../../css/variables";

.whitelistTabs {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 12px;
  padding: 4px;
  border-radius: 8px;
  background-color: #EAEEF3;
}
.whitelistTab {
  display: block;
  width: 50%;
  height: 30px;
  border-radius: 4px;
  background-color: transparent;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: #13171C;
  border: 0;
  padding: 0;
  cursor: pointer;
  transition: all ease .3s;
}
.whitelistTabActive {
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 #858F9B4D;
}
