@import "../../css/variables";

.billingPlanPageLoader {
  display: block;
  margin: 0 auto;
}
.billingPlanPageHeader {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #13171c;
  word-break: break-word;
}
.billingPlanPageBack {
  margin-right: 8px;
}
.billingPlanPageInfo {
  margin-top: 24px;
}

@media screen and (min-width: $screenMinWidthM) {
  .billingPlanPageInfo {
    margin-top: 28px;
  }
  .billingPlanPageInfo [data-header="item"]:nth-child(1),
  .billingPlanPageInfo [data-body="item"]:nth-child(1) {
    width: 20%;
  }
  .billingPlanPageInfo [data-header="item"]:nth-child(2),
  .billingPlanPageInfo [data-body="item"]:nth-child(2) {
    width: 30%;
  }
  .billingPlanPageInfo [data-header="item"]:nth-child(3),
  .billingPlanPageInfo [data-body="item"]:nth-child(3) {
    width: 20%;
  }
  .billingPlanPageInfo [data-header="item"]:nth-child(4),
  .billingPlanPageInfo [data-body="item"]:nth-child(4) {
    width: 15%;
  }
  .billingPlanPageInfo [data-header="item"]:nth-child(5),
  .billingPlanPageInfo [data-body="item"]:nth-child(5) {
    width: 20%;
  }
}
