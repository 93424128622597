@import "../../../../css/variables.scss";

.popup {
  max-width: 450px;
}
.button {
  margin-top: 20px;
}
.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #A4A4A4;
  margin-top: 20px;
}

@media screen and (min-width: $screenMinWidthM) {
  .popup {
    padding: 30px;
  }
  .button {
    width: 100%;
    margin-top: 40px;
  }
  .text {
    font-size: 20px;
    line-height: 26px;
  }
}
