@import "src/css/variables";

.label {
  display: block;
  width: 100%;
  height: 37px;
  background-color: #ffffff;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  padding: 0 32px 0 15px;
}
.labelRightPadding {
  padding-right: 65px;
}
.input {
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #13171C;
  background-color: transparent;
  border: none;
  box-sizing: border-box;
}
.input::placeholder {
  color: #A4A4A4;
}
.search {
  position: absolute;
  width: 14px;
  height: 14px;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.imageAdditional {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  right: 40px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
}
