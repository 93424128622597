.emptySearch {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.emptySearch img {
  display: block;
}
.emptySearchText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #A4A4A4;
  margin-top: 4px;
}
