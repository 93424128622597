@import "../../css/variables";

.balanceTopUpForm {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 173px);
}
.balanceTopUpFormSelect > [class*="currenciesSelectError"] {
  top: auto;
  left: auto;
  bottom: 75px;
  right: 0;
  text-align: right;
}
.balanceTopUpFormSelectTo {
  margin-top: 20px;
}
.balanceTopUpFormSelectFrom > [class*="currenciesSelectError"] {
  max-width: 49%;
}
.balanceTopUpFormSelectTo > [class*="currenciesSelectError"] {
  max-width: 68%;
}
.balanceTopUpFormInput {
  margin-top: 20px;
}
.balanceTopUpFormInput input,
.balanceTopUpFormInput [class*="fieldTicker"] {
  height: 48px;
}
.balanceTopUpFormInput > [class*="error"] {
  max-width: 62%;
  top: auto;
  left: auto;
  bottom: 56px;
  right: 0;
  text-align: right;
}
.balanceTopUpFormSelect [class*="currenciesSelectFieldLabel"],
.balanceTopUpFormInput [class*="fieldTitle"] {
  font-size: 18px;
  line-height: 24px;
}
.balanceTopUpFormSelect [class*="currenciesSelectErrorText"],
.balanceTopUpFormInput [class*="errorText"] {
  padding: 0 5px 0 0;
}
.balanceTopUpFormError {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 35px;
  margin-top: auto;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF7DA0;
}
.balanceTopUpFormButton {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  margin-top: 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.balanceTopUpFormDescription {
  min-height: 20px;
  margin-top: 10px;
}
.balanceTopUpFormDescription img {
  display: block;
}
.balanceTopUpFormDescriptionItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7E7E7E;
}
.balanceTopUpFormBlueColor {
  color: #64ACFF;
}

@media screen and (min-width: $screenMinWidthM) {
  .balanceTopUpForm {
    min-height: unset;
  }
}
