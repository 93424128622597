@import "../../../../css/variables.scss";

.button {
  height: 44px;
}
.searchInput {
  height: 44px;
  margin-top: 15px;
}

@media screen and (min-width: $screenMinWidthM) {
  .subscriptionFilter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .button {
    max-width: 216px;
  }
  .searchInput {
    max-width: 320px;
    margin-top: 0;
  }
}
