@import "src/css/variables";
.content{
  display: flex;
  align-items: baseline;
  position: relative;
}


.container{
  position: absolute;
  transition: .3s;
  font-size: 12px;
  padding: 15px;
  background-color: $colorWhite;
  max-width: 260px;
  width: max-content;
  box-sizing: border-box;
  border-radius: 8px;
  visibility: hidden;
  opacity: 0;
  line-height: 14px;
  transform: translate(20px,-50px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  color: lighten($colorBlack,50%);
}

.info{
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    .container{
      opacity: 1;
      visibility: visible;
    }
  }
}

.small{
  .info{
    margin-left: 4px;
  }
}
