@font-face {
  font-family: 'Syne';
  src: url('../../fonts/Syne/Syne-ExtraBold.ttf');
  url:('../fonts/Syne/Syne-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('../../fonts/Syne/Syne-Bold.ttf');
  url:('../fonts/Syne/Syne-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('../../fonts/Syne/Syne-SemiBold.ttf');
  url:('../fonts/Syne/Syne-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('../../fonts/Syne/Syne-Medium.ttf');
  url:('../fonts/Syne/Syne-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('../../fonts/Syne/Syne-Regular.ttf');
  url:('../fonts/Syne/Syne-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}