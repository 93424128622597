@import "../../../../css/variables";

.paymentDetailsTab {
  background-color: #FFFFFF;
  border-radius: 8px;
}
.paymentDetailsTab:not(:last-child) {
  margin-bottom: 32px;
}
.paymentDetailsTabHeader {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 12px 16px;
  border-radius: 8px 8px 0 0;
  background-color: #FAFAFA;
  font-size: 24px;
  line-height: 32px;
  color: #13171C;
}
.paymentDetailsTabContent {
  padding: 16px 16px 28px;
}
.paymentDetailsTabContent h3 {
  margin: 0 0 4px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #13171C;
}
.paymentDetailsTabContent p {
  font-size: 14px;
  line-height: 20px;
  color: #7E7E7E;
}
.paymentDetailsTabContent > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.paymentDetailsTabContent > ul > li:not(:last-child):after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  margin: 24px 0;
  background-color: #EEEEEE;
}

@media screen and (min-width: $screenMinWidthL) {
  .paymentDetailsTab {
    border: 1px solid #EEEEEE;
  }
}
