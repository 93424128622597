@import "../../css/variables";

.infoBlock {
  margin-top: -16px;
  margin-bottom: 32px;
}
.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7E7E7E;
}
.description > p {
  margin-top: 4px;
}
.searchInput {
  max-width: 300px;
  margin-top: 20px;
}
.coinsContent {
  max-width: 1020px;
  min-height: 30px;
  margin-top: 30px;
}
.loader {
  display: block;
  margin: 0 auto;
}
.group {
  margin-top: 35px;
}
.group:first-child {
  margin-top: 0;
}
.notFound {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #13171C;
}
.link {
  font-weight: 600;
  font-size: 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .description {
    font-size: 16px;
  }
  .group {
    margin-top: 60px;
  }
}
