@import "../../../../css/variables.scss";

.customerFullFilter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
}
.searchInput {
  max-width: 320px;
  height: 44px;
}
.customerFullFilterButton {
  flex-shrink: 0;
  margin-left: 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .customerFullFilter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
