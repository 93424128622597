@import "../../../css/variables";

.popupSuccess {
  max-width: 843px;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  font-weight: 400;
  color: #000000;
}
.text {
  margin-top: 16px;
}
.text a {
  font-size: 20px;
  line-height: normal;
  text-align: center;
  font-weight: 400;
}
.button {
  width: auto;
  min-width: 76px;
  margin: 20px auto 0;
}

@media screen and (min-width: $screenMinWidthM) {
  .popupSuccess {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
