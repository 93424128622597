@import "../../../../css/variables";

.whitelistUploadCSV {
  margin-top: auto;
}
.button {
  height: 48px;
  margin-top: 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .whitelistUploadCSV {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  .button {
    width: 120px;
    margin-top: 0;
  }
}
