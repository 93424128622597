.box {
  color: #13171C;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}
.description {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  margin: 15px 0;
}
.formCurrencies > [class*="currenciesSelectError"] {
  top: auto;
  left: auto;
  right: 0;
  bottom: 76px;
}
.formInput {
  margin-top: 16px;
}
.formInput > [class*="errorShow"] {
  top: auto;
  left: auto;
  right: 0;
  bottom: 45px;
}
.apiError {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 40px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF7DA0;
  text-align: center;
  margin-top: 5px;
}
.button {
  width: 100%;
  margin-top: 5px;
}
.warning {
  padding: 8px 12px;
  margin-top: 24px;
  border-radius: 4px;
  border: 1px solid #FF7DA0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
