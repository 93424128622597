@import "../../css/variables";

.ipnList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.ipnList > li,
.ipnDocsLinkContainer {
  padding: 16px;
  border-radius: 8px;
  background-color: #FFFFFF;
}
.ipnList > li {
  padding-bottom: 24px;
  margin-bottom: 16px;
}
.ipnList > li > h3 {
  margin-top: 0;
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #13171C;
}
.ipnList p {
  font-size: 14px;
  line-height: 20px;
  color: #7E7E7E;
}
.ipnDocsLink {
  width: 100%;

  &:after {
    margin-left: auto;
  }
}
.ipnDocsLink:hover:before {
  width: 60px;
}

@media screen and (min-width: $screenMinWidthXL) {
  .ipnSettings {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
  }
  .ipnList {
    margin-bottom: 16px;
  }
  .ipnList > li {
    margin-bottom: 0;
    padding: 0;
    border-radius: unset;
  }
  .ipnDocsLinkContainer {
    margin-top: auto;
    padding: 0;
    border-radius: unset;
  }
  .ipnList > li:not(:last-child):after {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    margin: 24px 0;
    background-color: #EEEEEE;
  }
  .ipnDocsLink {
    width: auto;

    &:after {
      margin-left: 10px;
    }
  }
  .ipnDocsLink:hover:before {
    width: 100%;
  }
}
