.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  font-weight: 600;
  font-size: 13px;

  tr {
    height: 50px;

    td {
      color: #2b2b37;
      text-align: center;
      border-bottom: 1px solid #ceccd9;
    }
  }
}

.thead {
  width: 100%;
}

.tbody {
  width: 100%;
}

.addBtn {
  margin-top: 20px;
}
