@import "../../../../css/variables.scss";

.providers {
  padding: 24px 16px;
}
.providers h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}
.providersDescription {
  margin: -12px 0 16px;
}
.providersBlockTitle {
  margin: 0 0 8px;
  color: #A4A4A4;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.providers .tooltip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 230px;
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid #64ACFF;
  background-color: #FFF;
  opacity: 1 !important;
  color: #000000;

  &::before,
  &::after {
    display: none;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .providers {
    padding: 16px 24px 24px;
  }
  .providersDescription {
    margin-bottom: 20px;
  }
}
