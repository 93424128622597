@import "../../../../css/variables.scss";

.billingPlansTable {
  margin-top: 24px;
}
.billingPlansTable th,
.billingPlansTable td {
  width: 37.5%;
}
.billingPlansTable th:nth-child(3),
.billingPlansTable td:nth-child(3) {
  width: 25%;
}
.billingPlansTable [data-overflow="hidden"] {
  max-width: 190px;
}
button.emptyBlockButton {
  max-width: 250px;
  margin-top: 20px;
}

@media screen and (min-width: 480px) {
  .billingPlansTable [class*="historyTableEmptyTr"],
  .billingPlansTable [class*="historyTableLoaderTr"] {
    text-align: center;
  }
  button.emptyBlockButton {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .billingPlansTable [data-overflow="hidden"] {
    max-width: 240px;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  button.emptyBlockButton {
    width: 127px;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .billingPlansTable [data-overflow="hidden"] {
    max-width: 50px;
  }
  .billingPlansTable th:nth-child(1),
  .billingPlansTable td:nth-child(1) {
    width: 16%;
  }
  .billingPlansTable th:nth-child(2),
  .billingPlansTable td:nth-child(2) {
    width: 35%;
  }
  .billingPlansTable th:nth-child(3),
  .billingPlansTable td:nth-child(3) {
    width: 15%;
  }
  .billingPlansTable th:nth-child(4),
  .billingPlansTable td:nth-child(4) {
    width: 10%;
  }
  .billingPlansTable th:nth-child(5),
  .billingPlansTable td:nth-child(5) {
    width: 18%;
  }
}
