@import "src/css/variables";

.checkboxNew {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.checkboxNewInput {
  display: none;
}
.checkboxNewBox {
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  cursor: pointer;
}
.checkboxNewInput:checked + .checkboxNewBox {
  border: none;
  background: url('../../../images/svg/checkbox.svg');
}
.checkboxNewError .checkboxNewBox {
  border: 1px solid $colorPink;
}
