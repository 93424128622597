.field {
  position: relative;
  display: block;
  width: 100%;
}
.fieldTitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #13171C;
  margin-bottom: 8px;
}
.fieldBox {
  position: relative;
}
.fieldInputWithIcon {
  padding-right: 30px;
}
.fieldTicker {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  background-color: transparent;
  position: absolute;
  bottom: 1px;
  right: 1px;
  margin: auto;
  padding-right: 15px;
  padding-left: 5px;
  border-radius: 8px;
}
.fieldIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  background-color: transparent;
  position: absolute;
  bottom: 1px;
  right: 1px;
  margin: auto;
  padding-right: 10px;
  padding-left: 5px;
  border-radius: 8px;
}
.error {
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 0;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  transition: max-height ease .3s;
}
.errorShow {
  max-height: 200px;
}
.errorText {
  font-size: 12px;
  line-height: 14px;
  color: #FF7DA0;
  padding-top: 4px;
  padding-left: 15px;
}
.errorTopRight {
  max-width: 50%;
  top: auto;
  left: auto;
  right: 0;
  text-align: right;
  bottom: calc(100% - 22px);
}
.errorTopRight .errorText {
  padding: 0;
}
.fieldWithCurrencies .fieldBox {
  display: flex;
  position: relative;
  height: 48px;
}
.fieldWithCurrencies .fieldInput {
  height: inherit;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.fieldWithCurrencies .fieldTitle {
  padding-left: 0;
  padding-right: 0;
}
.fieldCurrenciesSelect {
  position: static;
}
.fieldWithCurrencies .error {
  top: auto;
  left: auto;
  right: 0;
  bottom: calc(100% - 20px);
}
.fieldWithCurrencies .errorText {
  padding-top: 0;
  padding-left: 0;
}
