@import "../../css/variables.scss";

.cookiesAlert {
  box-sizing: border-box;
  z-index: 200;
  position: fixed;
  bottom: 0;
  padding: 20px 50px;
  width: 100%;
  max-width: 790px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #eceff1;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);

  .row {
    display: flex;

    @media all and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .text {
    letter-spacing: 0.2px;

    @media all and (max-width: 379px) {
      font-size: 13px;
      line-height: 18px;
      margin-bottom: 15px;
    }

    @media all and (min-width: 380px) and (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 25px;
    }

    @media all and (min-width: 768px) {
      font-size: 15px;
      line-height: 22px;
      margin-right: 25px;
    }
  }

  .link {
    color: #64acff;
  }

  .button {
    height: auto;
    font-weight: bold;
    position: relative;
    border-radius: 0;
    transition: all 0.5s ease;
    box-sizing: border-box;
    display: inline-block;
    background-color: transparent;
    border: 2px solid #ff7da0;
    color: #ff7da0;

    @media all and (max-width: 379px) {
      font-size: 15px;
      padding: 7px 35px;
    }

    @media all and (min-width: 380px) {
      font-size: 18px;
      padding: 7px 45px;
    }

    @media all and (max-width: 767px) {
      align-self: flex-start;
    }

    &:hover {
      background: transparent;
      border: 2px solid #e84672;
      color: #e84672;
    }

    &:active,
    &:focus {
      border: 2px solid #e84672;
    }
  }
}
