@import "../../../../css/variables";

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #DCE1EB;
}
.cardMain {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}
.cardText {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #13171C;
}
.cardText span {
  font-weight: 600;
  color: #64ACFF;
}
.soon .cardText {
  opacity: 0.3;
}
.cardExplanation {
  color: #7E7E7E;
}
.cardInfo {
  margin-bottom: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.cardInfoContent {
  display: flex;
  align-items: center;
  gap: 4px;
}
.cardVerification {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.cardPaymentSystems {
  display: flex;
  gap: 8px;
}
.cardMainProviderName {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #13171C;
}
.cardMainProvider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cardButton {
  margin-top: 20px;
  padding: 0 12px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
}
.soon {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 12px;
    right: 12px;
    display: block;
    width: 33px;
    height: 20px;
    background-image: url("../../../../../public/images/soon.svg");
  }
}
.cardInfoContent img {
  width: 12px;
  height: 12px;
}
.cardButtonActivated {
  display: none;
}
.cardHighlightedInfo {
  position: absolute;
  right: 16px;
  top: -10px;
  padding: 2px 8px;
  background-color: #DAFF7C;
  border-radius: 4px;
  color: #13171C;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.cardInfoSmall,
.cardVerificationSmall {
  display: none;
}
.cardSmall {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: #FFF;
}
.cardMainSmall {
  margin-bottom: 0;
}
.cardMainSmall .cardPaymentSystems {
  flex-wrap: wrap;
}
.cardButtonSmall {
  margin-top: 0;
  height: 32px;
  width: 73px;
}

@media screen and (min-width: $screenMinWidthXL) {
  .card {
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
  }
  .cardMain {
    width: 229px;
    margin-bottom: 0;
  }
  .cardInfo {
    width: 202px;
    margin-bottom: 0;
    flex-direction: column;
  }
  .cardInfoContent {
    flex-direction: row;
  }
  .cardVerification {
    width: 215px;
  }
  .cardButton {
    align-self: center;
    height: 32px;
    margin-top: 0;
  }
  .soon {
    &:after {
      position: relative;
      right: 0;
      top: 0;
      width: 74px;
      background-image: url("../../../../../public/images/coming-soon.svg");
    }
  }
  .cardButtonActivated {
    display: block;
    opacity: 0;
    pointer-events: none;
  }
}
