@import "../../css/variables";

.cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

@media screen and (min-width: $screenMinWidthL) {
  .cards {
    margin-bottom: 24px;
  }
}


