@import "../../css/variables";

.apiKeysSettingsTitle {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

@media screen and (min-width: $screenMinWidthXL) {
  .apiKeysSettingsWrapper {
    justify-content: space-between;
    align-items: center;
  }
  .apiKeysSettingsWrapper > button {
    width: 161px;
    margin: 0;
    order: unset;
  }
}
