@import "../../css/variables.scss";

.body {
  font-weight: 600;
  font-size: 13px;

  display: flex;
  flex-direction: row;

  width: 120px;
  margin-bottom: 30px;

  div {
    width: 36px;
    height: 36px;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
  }

  .active {
    border-color: $colorBlue;
    color: $colorBlue;
    border: 2px solid $colorBlue;
  }

  .inactive {
    border-color: $colorLightBlue;
    color: $colorLightBlue;
    border: 2px solid $colorLightBlue;
  }
}
