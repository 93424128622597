@import "../../../../css/variables.scss";

.popupWrapper .popup {
  padding-bottom: 40px;
}
.content {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 165px);
}

@media screen and (min-width: $screenMinWidthM) {
  .content {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}
