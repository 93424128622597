@import "../../../css/variables.scss";

.profileItem {
  width: 100%;
}
.profileItemTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.profileItemIcon {
  margin-top: 2px;
  margin-left: 6px;
  cursor: pointer;
}
.profile input.profileCompanyInput {
  display: block;
  width: calc(100% - 20px);
  margin-right: 0;
}
.profile textarea.profileItemTextarea {
  display: block;
  width: calc(100% - 20px);
  margin-right: 0;
  height: auto;
  min-height: 100px;
  resize: vertical;
}
.profileItemStore {
  margin-top: 25px;
}
.profileItemError {
  min-height: 40px;
  font-size: 14px;
  line-height: 20px;
  color: #ff7da0;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media screen and (min-width: $screenMinWidthM) {
  .profileBox {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .profileItem {
    width: 48%;
  }
  .profileItemStore {
    margin-top: 0;
  }
}
