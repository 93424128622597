.activateCustody {
  color: #13171C;
}
.activateCustodyTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}
.activateCustodyText {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  margin-top: 32px;
}
.activateCustodyList {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  list-style-type: none;
  padding: 0;
  margin: 24px 0 0;
}
.activateCustodyList li {
  position: relative;
  padding-left: 16px;
  margin-top: 16px;
}
.activateCustodyList li:first-child {
  margin-top: 0;
}
.activateCustodyList li img {
  width: 10px;
  height: 8px;
  position: absolute;
  top: 8px;
  left: 0;
}
.activateCustodyCheckbox {
  margin-top: 48px;
}
.activateCustodyButtonBox {
  margin-top: 24px;
}
.activateCustodyLink {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
}
.activateCustodyButtonError {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #959595;
  text-align: center;
  margin-top: 15px;
}
.activateCustodyButtonError img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

@media screen and (min-width: 480px) {
  .activateCustodyButtonBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .activateCustodyButton {
    width: 175px;
  }
  .activateCustodyButtonError {
    margin-left: 25px;
    margin-top: 0;
  }
}
