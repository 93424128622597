@import '../../css/variables.scss';

.title {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #13171C;
}
.select {
  margin-top: 20px;
}
.field {
  margin-top: 20px;
}
.details {
  margin-top: 20px;
  padding-bottom: 12px;
}
.detailsItem:last-child {
  margin-top: 10px;
}
.detailsItemTitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #64ACFF;
  white-space: nowrap;
}
.detailsItemText {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #13171C;
  word-break: break-all;
}
.error {
  min-height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF7DA0;
  text-align: center;
  margin-top: 5px;
}
.errorActive {
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.errorActive a {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.button {
  width: 100%;
  margin-top: 5px;
}
.selectField [class*="select_"] {
  height: 56px;
}
.formSwitcher {
  position: relative;
  width: 100%;
  height: 39px;
  padding: 4px;
  margin-top: 32px;
  border-radius: 8px;
  border: none;
  background-color: #EAEEF3;
}
.formSwitcherActive {
  background-color: #EAEEF3;
}
.formSwitcher [class*="switcherBox"] {
  width: calc(50% - 5px);
  height: 31px;
  border-radius: 4px;
}
.formSwitcherActive [class*="switcherBox"] {
  margin-left: calc(50% + 5px);
}
.formSwitcherTabs {
  position: absolute;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: calc(100% - 8px);
}
.formSwitcherTab {
  width: 50%;
  text-align: center;
}
.addAccount {
  position: relative;
  padding: 12px;
  margin-top: 20px;
  border-radius: 8px;
  background: #F4F6F9;
  cursor: pointer;

  &:after {
    position: absolute;
    top: 12px;
    right: 12px;
    content: "";
    width: 24px;
    height: 24px;
    display: block;
    background-image: url("../../../public/images/arrow-circle-icon.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.addAccountTitle {
  margin: 0 0 8px;
  color: #13171C;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.addAccountDescription {
  color: #13171C;
  font-size: 16px;
  font-weight: 400;
}
.addAccountError {
  padding: 11px;
  border: 1px solid #FF7DA0;
}
.form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 50px);
}
.estimate {
  display: flex;
  height: 0;
  padding-top: 12px;
  border-top: 1px solid #F4F4F4;
  opacity: 0;
  line-height: 30px;
  transition: height 0.5s, opacity 0.2s;
}
.estimateAvailable {
  height: 43px;
  opacity: 1;
}
.estimateTitle {
  font-size: 18px;
  font-weight: 400;
  color: #13171C;
}
.estimateValue {
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  color: #64ACFF;
}
div.errorActive .errorLink {
  position: relative;
  bottom: 0;

  &:before {
    content: "";
    position: absolute;
    bottom: -1px;
    width: 0;
    height: 1px;
    background-color: #64ACFF;
    transition: width 0.5s;
  }
}
div.errorActive .errorLink:hover {
  &:before {
    width: 100%;
  }
}

@media screen and (min-width: 375px) {
  .details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .detailsItem:last-child {
    margin-top: 0;
    text-align: right;
  }
}

@media screen and (min-width: 375px) {
  .details {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .detailsItem:last-child {
    margin-top: 0;
    text-align: right;
  }
}

@media screen and (min-width: 400px) {
  .form [class*="styles_error"] {
      top: auto;
      left: auto;
      right: 0;
      bottom: 45px;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .form {
    position: static;
    display: block;
    min-height: unset;
  }
}
