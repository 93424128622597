@import "../../../../css/variables";

.ipnKey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 56px;
  margin: 16px 0;
  padding: 6px 16px;
  border-radius: 8px;
  background-color: #FAFAFA;
}
.setUpIpn .ipnTitle {
  color: #13171C;
}
.ipnTitle span {
  color: #7E7E7E;
}
.setUpIpn .ipnKeyData {
  font-size: 16px;
  line-height: 24px;
  color: #13171C;
}
.ipnKeyRegenerate {
  position: relative;
  padding: 0;
  background-color: transparent;
  color: #64ACFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;

  &:before {
    content: "";
    position: absolute;
    bottom: 7px;
    width: 0;
    height: 1px;
    background-color: #64ACFF;
    transition: width 0.5s;
  }
}
.ipnKeyRegenerate:hover {
  &:before {
    width: 100%;
  }
}
.setUpIpn .ipnUrlTitle {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.ipnUrlField {
  margin: 4px 0;
}
.ipnUrlField input {
  height: 48px;
  padding-right: 0;
}
.ipnAttention {
  display: flex;
  align-items: flex-start;
  margin: 16px 0;
}
.setUpIpn .ipnAttentionText {
  margin-left: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #13171C;
}
.ipnUrlButton {
  height: 48px;
  margin-top: 16px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  transition: all ease 0.3s;
}
.ipnUrlButtonSaved,
.ipnUrlButtonSaved:hover {
  background-color: #DAFF7C;
}
.ipnUrlField [class*='errorShow_'] {
  display: none;
}
.ipnUrlFieldContainer .ipnUrlTextError {
  color: #FF7DA0;
  font-size: 16px;
  font-weight: 600;
}
.setUpIpnLoader {
  display: block;
  margin: 16px auto 0;
}

@media screen and (min-width: $screenMinWidthL) {
  .setUpIpn .ipnAttentionText {
    font-size: 16px;
    line-height: 24px;
  }
  .ipnAttention {
    margin-bottom: 24px;
  }
  .ipnAttentionImage {
    margin-top: 2px;
  }
  .generateFirstIpnButton {
    min-width: 211px;
  }
  .ipnUrlField {
    max-width: 328px;
  }
  .ipnUrlFieldContainer {
    position: relative;
  }
  .ipnUrlButton {
    position: absolute;
    top: 0;
    left: 336px;
    min-width: 104px;
    margin-top: unset;
  }
}
