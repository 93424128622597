@import "../../../../css/variables.scss";

.apiKeysTable {
  margin-top: 12px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
}
.apiKeysTableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #A4A4A4;
  padding: 16px 16px 12px;
  border-bottom: 1px solid #eeeeee;
}
.apiKeysTableBody {
  padding: 4px 8px 12px;
}
.apiKeysTableBodyEmpty {
  padding: 56px 16px;
}
.apiKeysTableTd {
  position: relative;

  &:first-child {
    flex-shrink: 0;
  }

  &:first-child,
  &:nth-child(2) {
    width: 40%;
  }

  &:last-child {
    width: 44px;
    flex-shrink: 0;
  }
}
.apiKeysTable .apiKeysTableOptionsTooltip {
  padding: 7px 0;
}
.apiKeysTableTitleTooltip {
  word-break: break-all;
}
.apiKeysTableBodyLoader {
  text-align: center;
}
.apiKeysTableBodyError {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FF7DA0;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.apiKeysTableBodyAdd {
  width: 100%;
  margin-top: 16px;
}
.apiKeysTableBodyAddText {
  padding-top: 4px;
  font-size: 16px;
  line-height: 24px;
}
.apiKeysTablePagination {
  margin-top: 16px;
}
.apiKeysTablePagination [class*='newPaginationButton'] {
  background-color: transparent;
  border: none;
}
.apiKeysTablePagination [class*='newPaginationButtonActive'] {
  border: 1px solid #64ACFF;
}

@media screen and (min-width: $screenMinWidthXXS) {
  .apiKeysTableTd:first-child,
  .apiKeysTableTd:nth-child(2) {
    margin-right: 16px;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .apiKeysTableHeader {
    justify-content: flex-start;
    padding: 16px 24px 12px;
  }
  .apiKeysTableBody {
    padding: 12px 8px;
  }
  .apiKeysTableTd {
    &:first-child,
    &:nth-child(2) {
      width: 110px;
      margin-right: 68px;
      flex-shrink: 0;
    }

    &:nth-child(3) {
      margin-right: auto;
    }

    &:last-child {
      flex-shrink: 0;
    }
  }
  .apiKeysTableBodyAdd {
    width: 140px;
    margin: 16px auto 0;
  }
}
