.mainMenu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.mainMenuItem {
  margin-top: 12px;
}
.mainMenuItem:first-child {
  margin-top: 0;
}
