@import "../../css/variables.scss";

.info {
  display: block;
  width: 100%;
}
.infoHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}
.infoTitle {
  min-width: 130px;
  color: #A4A4A4;
  flex-shrink: 0;
}
.infoId {
  color: #13171C;
  flex-grow: 1;
}
.infoList {
  margin-top: 20px;
}
.infoListItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
}
.infoListItem:first-child {
  margin-top: 0;
}
.infoListItemKey {
  min-width: 130px;
  color: #A4A4A4;
  flex-shrink: 0;
}
.infoListItemKey img {
  margin-left: 4px;
  margin-bottom: -1px;
}
.infoListItemKeyFlex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.infoListItemValue {
  color: #13171C;
  flex-grow: 1;
  word-break: break-all;
}
.infoListItemValue strong {
  font-weight: 700;
}
.infoListItemValueFlex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.infoListItemValueFlex > span {
  margin-right: 4px;
}

@media screen and (min-width: $screenMinWidthM) {
  .infoHeader {
    font-size: 24px;
    line-height: 30px;
  }
  .infoTitle,
  .infoListItemKey {
    min-width: 170px;
  }
  .infoListItem,
  .infoListItem a {
    font-size: 18px;
    line-height: 23px;
  }
}
