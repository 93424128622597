@import "../../../../css/variables";

.manualPayoutFormFields {
  display: none;
}
.manualPayoutFormFieldsActive {
  display: block;
}
.formCurrenciesBox {
  width: 100%;
}
.formCurrenciesText {
  min-height: 16px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: #757575;
  margin-top: 4px;
}
.formField {
  margin-top: 20px;
}
.formCurrencies [class*="currenciesSelectFieldHeader"],
.formField [class*="fieldTitle"] {
  padding: 0;
}
.formCurrencies [class*="currenciesSelectError"],
.formField > [class*="error"] {
  top: auto;
  left: auto;
  right: 0;
  bottom: 48px;
}
.formField input {
  height: 40px;
}
.formButtonDelete {
  width: auto;
  height: auto;
  font-size: 20px;
  line-height: 24px;
  color: #64ACFF;
  font-weight: 400;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 24px 0;
}
.formButtonDelete:hover {
  color: #64ACFF;
  background: transparent;
}
.buttons {
  margin-top: auto;
}
.error {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 26px;
  font-size: 14px;
  line-height: 16px;
  color: #FF7DA0;
  text-align: center;
  margin-top: 3px;
}
.buttonSubmit {
  margin-top: 3px;
}
.buttonAppendPaginationItem {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: #13171C;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  background-color: #F4F4F4;
  border-radius: 8px;
  margin-right: 8px;
  padding: 0;
  outline: 0;
}
.buttonAppendPaginationItemActive {
  border: 1px solid #64ACFF;
}

@media screen and (min-width: $screenMinWidthM) {
  .manualPayoutFormFields {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 24px;
  }
  .manualPayoutFormFields:first-child {
    margin-top: 0;
  }
  .formCurrenciesBox {
    max-width: 210px;
    flex-shrink: 0;
  }
  .formCurrencies > [class*="currenciesSelect"] > [class*="currency"] {
    padding-left: 8px;
    padding-right: 8px;
  }
  .formCurrencies [class*="currenciesSelectBody"] {
    right: auto;
    width: 300px;
  }
  .formField {
    margin-top: 0;
    margin-left: 10px;
  }
  .formField input {
    padding: 0 8px;
  }
  .formFieldAddress {
    width: 40%;
    flex-grow: 1;
  }
  .formFieldExtraId {
    min-width: 130px;
    width: 20%;
  }
  .formFieldAmount {
    min-width: 130px;
    width: 20%;
  }
  .formButtonDelete {
    margin: 35px 0 0 20px;
  }
  .buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .buttonAppendBox {
    width: 100%;
    order: 1;
    margin-bottom: 40px;
  }
  .error {
    width: calc(100% - 176px);
    min-height: auto;
    justify-content: flex-start;
    text-align: left;
    order: 3;
    margin-left: 16px;
    margin-top: 0;
  }
  .buttonSubmit {
    width: 160px;
    margin-top: 0;
    padding-left: 5px;
    padding-right: 5px;
    order: 2;
  }
  .buttonAppendPaginationItem {
    display: none;
  }
}
