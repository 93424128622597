@import "src/css/variables";

.buttons {
  margin-top: 25px;
}
.button {
  margin-top: 15px;
}
.button:first-child {
  margin-top: 0;
}
.button:disabled {
  opacity: 0.7;
}

@media screen and (min-width: $screenMinWidthM) {
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button {
    width: calc(33.3% - 12px);
    margin-top: 0;
    margin-left: 12px;
  }
  .button:first-child {
    margin-left: 0;
  }
}
