.wrapper {
  display: flex;
  flex-direction: column;
  .errorBlock {
    padding: 10px 15px 10px 15px;
    margin: 15px 0 0 0;
    background-color: #fff2f2;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      color: #ef2c2c;
    }
  }

  .resultLinkBlock {
    background-color: #dff0d8;
    border: 1px solid #d0e9c6;
    padding: 10px 15px 10px 15px;
    margin: 15px 0 0 0;
    font-weight: 400;
    font-size: 14px;
    color: #3c763d;
    display: flex;
    flex-direction: row;
    align-items: center;
    word-break: break-all;

    p {
      font-weight: 600;
      font-size: 14px;
      color: #3c763d;
      margin-right: 10px;
    }
  }

  .linkBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin-right: 5px;
    }

    .selectApiKey {
      display: flex;
      width: 100%;
      max-width: 400px;
      margin-bottom: 15px;
    }

    .inputBlock {
      display: flex;
      flex-direction: row;
      align-items: center;

      @media all and (max-width: 425px) {
        flex-direction: column;
      }
    }

    .inputField {
      margin-right: 0;
      width: 100%;
      max-width: 400px;
      box-sizing: border-box;
      height: 35px;

      @media all and (max-width: 425px) {
        margin-top: 10px;
      }
    }

    .statusLinkAllow {
      margin-left: 10px;
      color: #3c763d;
    }

    .statusLinkNoAllow {
      margin-left: 10px;
      color: #ff7da0;
    }

    .table {
      margin-top: 20px;

      &__head {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 700;

        &__row {
          display: flex;
          align-items: center;
          padding: 10px 15px;

          &__item {
            display: flex;
            min-width: 100px;
            flex: 1 0 33.3%;
            align-items: center;
            justify-content: center;

            &:first-child {
              justify-content: flex-start;
            }
          }
        }
      }

      &__body {
        &__row {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          border: 1px solid #64acff;
          border-radius: 8px;
          padding: 10px 15px;

          &__item {
            display: flex;
            min-width: 100px;
            flex: 1 0 33.3%;
            align-items: center;
            justify-content: center;

            &:first-child {
              justify-content: flex-start;
            }
          }
        }
      }

      &__row {
        border: 1px solid #64acff;
        border-radius: 8px;
      }
    }
  }

  .buttonsBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
  }
}

.titleWrapper {
  display: flex;
  align-items: center;

  a {
    margin: 0 0 20px 10px;
    cursor: pointer;
  }
}
