.permission {
  padding: 32px;
  max-width: 424px;
}
.permission [class*="title"] {
  font-size: 24px;
  font-weight: 600;
  color: #13171C;
}
.permissionDescription {
  margin-bottom: 24px;
  color: #13171C;
  font-size: 15px;
  font-weight: 400;
}
.permissionProceed {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #64ACFF;
  font-size: 18px;
  font-weight: 600;
  color: #FFF;
}
.permissionProceed:hover {
  background-color: #73b1fa;
}
.permissionCancel {
  width: 100%;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
}
