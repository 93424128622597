@import "../../css/variables";

.formField {
  margin-top: 24px;
}
.formField:first-child {
  margin-top: 0;
}
.formCustomGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.formField [class*="fieldTitle"] {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding: 0;
}
.formField [class*="input"] {
  height: 48px;
  font-size: 18px;
  line-height: 22px;
}
.formField [class*="inputError"] {
  border-color: #D2D2D2;
}
.formField > [class*="error"],
.formFieldCost > [class*="error"],
.formFieldPeriod > [class*="error"] {
  max-width: calc(100% - 125px);
  left: auto;
  top: auto;
  right: 0;
  bottom: 59px;
  text-align: right;
}
.formField [class*="errorText"] {
  padding-left: 0;
  padding-top: 0;
}
.formFieldPeriod {
  flex-grow: 1;
}
.formFieldPeriod [class*="input"],
.formFieldCost [class*="input"] {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.formFieldPeriodSelect {
  width: 150px;
  flex-shrink: 0;
  margin-top: 30px;
  margin-left: -6px;
}
.formFieldPeriodSelect > [class*="simpleSelect"] {
  height: 48px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-top: 0;
  box-sizing: border-box;
}
.formFieldPeriodSelect > [class*="simpleSelect"]:hover {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-color: #64ACFF;
}
.formFieldPeriodSelect > [class*="simpleSelectActive"] {
  border-top-left-radius: 8px;
}
.formFieldPeriodSelect > [class*="simpleSelectActive"]:hover {
  border-bottom-left-radius: 0;
}
.formFieldPeriodSelect > [class*="simpleSelectActive"] > [class*="simpleSelectValue"] {
  border-color: #64ACFF;
}
.formFieldPeriodSelect > [class*="simpleSelectActive"] [class*="simpleSelectList"]:before {
  background-color: #64ACFF;
  border-top-left-radius: 8px;
}
.formFieldPeriodSelect [class*="simpleSelectValue"] {
  font-size: 18px;
  line-height: 48px;
  padding-left: 12px;
  padding-right: 12px;
}
.formFieldPeriodSelect [class*="simpleSelectList"] {
  padding-left: 0;
  padding-right: 0;
}
.formFieldPeriodSelect [class*="simpleSelectList"] li {
  font-size: 18px;
  line-height: 22px;
  padding: 4px 12px;
  margin: 0;
}
.formFieldCost,
.formFieldPeriod {
  position: static;
}
.formFieldCostCurrency {
  width: 150px;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-size: 18px;
  line-height: 22px;
  color: #13171C;
  flex-shrink: 0;
  border: 1px solid #D2D2D2;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 30px;
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 5px;
  cursor: default;
}
.formFieldCostCurrency img {
  display: block;
}
.formSettingsButton {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #13171C;
  margin-top: 40px;
  margin-bottom: 20px;
  cursor: pointer;
}
.formSettingsButtonImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: #ecf5ff;
  border-radius: 4px;
  margin-right: 8px;
}
.formSettingsButtonImg img {
  display: block;
  transition: transform ease .3s;
}
.formSettingsButtonActive .formSettingsButtonImg img {
  transform: rotate(45deg);
}
.formSettingsBox {
  max-height: 0;
  overflow: hidden;
  transition: max-height linear .3s;
}
.formSettingsBoxActive {
  max-height: 500px;
}
.formSettingsBox .formField:last-child {
  margin-bottom: 20px;
}
.formApiError {
  min-height: 16px;
  font-size: 14px;
  line-height: 16px;
  color: #FF7DA0;
  text-align: center;
  margin-top: auto;
}
.formApiErrorHeight {
  min-height: 40px;
  padding-top: 4px;
}
.formButton {
  width: 100%;
  margin-top: 4px;
}


@media screen and (min-width: $screenMinWidthXXS) {
  .formField [class*="fieldTitle"] {
    font-size: 20px;
    line-height: 24px;
  }
  .formField [class*="input"] {
    font-size: 20px;
    line-height: 24px;
  }
  .formField > [class*="error"],
  .formFieldCost > [class*="error"],
  .formFieldPeriod > [class*="error"] {
    max-width: calc(100% - 150px);
    bottom: 59px;
  }
  .formFieldPeriodSelect {
    width: 160px;
    margin-top: 32px;
  }
  .formFieldPeriodSelect [class*="simpleSelectValue"] {
    font-size: 20px;
  }
  .formFieldPeriodSelect [class*="simpleSelectList"] li {
    font-size: 20px;
    line-height: 24px;
  }
  .formFieldCostCurrency {
    width: 160px;
    font-size: 20px;
    line-height: 24px;
    margin-top: 32px;
  }
  .formFieldCostCurrency img {
    width: 30px;
    height: 30px;
  }
}
