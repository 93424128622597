@import "../../css/variables.scss";

.form {
  display: block;
}
.formTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #13171C;
}
.formContent {
  border-top: 1px solid #eeeeee;
  padding-top: 24px;
  margin-top: 16px;
}
.formInput {
  margin-top: 16px;
}
.formInput:first-child {
  margin-top: 0;
}
.formInput [class*="input"] {
  height: 48px;
  font-size: 20px;
  line-height: 24px;
}
.formInput [class*="fieldTitle"] {
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  padding: 0;
}
.formInput [class*="fieldTicker"] {
  height: 46px;
  font-size: 20px;
  line-height: 24px;
}
.formInputCurrencyLabel {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #13171C;
  margin-bottom: 8px;
}
.formInputCurrencySelect [class*="currenciesSelectFieldHeader"] {
  font-size: 20px;
  line-height: 24px;
  padding-left: 0;
  padding-right: 0;
}
.formInputDescription > [class*="error"] {
  max-width: 48%;
}
.formInputOrderId > [class*="error"] {
  max-width: 70%;
}
.formInputPrice > [class*="error"] {
  max-width: 77%;
}
.formInputDescription > [class*="error"],
.formInputOrderId > [class*="error"],
.formInputPrice > [class*="error"] {
  top: auto;
  left: auto;
  bottom: 55px;
  right: 0;
}
.formInputDescription [class*="errorText"],
.formInputOrderId [class*="errorText"],
.formInputPrice [class*="errorText"] {
  text-align: right;
  padding-top: 0;
  padding-left: 0;
  padding-right: 5px;
}
.formButton {
  width: 100%;
  height: 64px;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 20px;
}
.errorMessage {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #fc0606;
  margin-top: 10px;
}
.formCheckboxes {
  margin-top: 16px;
}
.formCheckbox {
  margin-top: 10px;
}
.formCheckbox:first-child {
  margin-top: 0;
}
.formCheckbox span {
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.formCheckbox img {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 4px;
}

@media screen and (min-width: 460px) {
  .formInputDescription > [class*="error"] {
    max-width: 67%;
  }
  .formInputOrderId > [class*="error"] {
    max-width: 80%;
  }
  .formInputPrice > [class*="error"] {
    max-width: 80%;
  }
}

@media screen and (min-width: $screenMinWidthS) {
  .formTitle {
    font-size: 28px;
    line-height: 36px;
  }
  .formCheckboxes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .formCheckbox {
    width: 50%;
    margin-top: 0;
  }
}
