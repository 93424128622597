@import "../../css/variables";

.custody * {
  box-sizing: border-box;
}
.loaderBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoBlock {
  margin-top: -16px;
  margin-bottom: 32px;
}
.balanceErrorBox {
  max-width: 750px;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 5px;
}
.balanceErrorBoxFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.balanceErrorBox a {
  color: #64ACFF;
}
.balanceErrorBox [class*="sectionContent"] {
  margin: 0;
}
.balanceErrorBox img {
  display: block;
  margin-left: 15px;
}
