.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  &:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    margin-left: 10px;
    margin-top: 3px;
    border: 2px solid #64ACFF;
    border-left: 0;
    border-top: 0;
    transform: rotate(-45deg);
  }

  &:before {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 0;
    height: 2px;
    background-color: #64ACFF;
    transition: width 0.5s;
  }
}
.link:hover {
  &:before {
    width: 100%;
  }
}

