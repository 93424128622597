.listItemName {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 12px 0;
  color: #13171C;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}
.listItem {
  display: flex;
  align-items: center;
  gap: 8px;
}
.listItemSmall {
  gap: 10px;
}
.listItemSmall .listItemName {
  min-height: 40px;
  padding: 8px 0;
}
.listItemSmall img {
  width: 28px;
  height: 28px;
}
