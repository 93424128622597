.wrapper {
  font: 400 13px Arial;
  height: 100px;
  width: 260px;
  font-size: 15px;
  background-color: #eceff1;
  border: none;
  resize: none;
  padding: 10px;
  box-sizing: border-box;
}
