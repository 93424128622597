@import "src/css/variables";

.infoBlock {
  margin-top: -16px;
  margin-bottom: 32px;
}
.exportPopup {
  max-width: 300px;
  padding: 20px;
}
.popupPaymentInfoError {
  min-height: 20px;
  font-size: 14px;
  line-height: 16px;
  color: $colorPink;
  text-align: center;
  margin-top: 5px;
  margin-bottom: -16px;
}
