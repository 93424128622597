@import "../../../../css/variables";

.radioButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
}
.radioButton {
  position: relative;
  display: block;
  padding: 12px;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  color: #13171C;
  transition: all ease 0.3s;
}
.radioButtonActive,
.radioButton:hover {
  border-color: #64ACFF;
}
.radioButtonLoading [class*='radioBox_'] {
  border-color: #64ACFF;
  border-left-color: transparent;
  animation: rotate 1s linear infinite;
}
.radioButtonError [class*='radioBox_'] {
  border-color: #FF7DA0;
}
.radioButton .radioButtonTitle {
  position: absolute;
  top: 9px;
  left: 34px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #13171C;
}
.radioButtonText {
  margin-top: 4px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .radioButtons {
    flex-direction: row;
  }
  .radioButton {
    width: 50%;
  }
}
