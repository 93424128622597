.conversionForm {}
.conversionFormTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #13171C;
}
.conversionFormSelectFrom {
  margin-top: 20px;
}
.conversionFormSelectTo {
  margin-top: 16px;
}
.conversionFormInput {
  margin-top: 16px;
}
.conversionFormEstimateBox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
}
.conversionFormCurrentBalance {
  margin-right: 3px;
}
.conversionFormEstimate {
  margin-left: 3px;
}
.conversionFormEstimate {
  visibility: hidden;
  opacity: 0;
  transition: opacity ease .2s;
}
.conversionFormEstimateShow {
  visibility: visible;
  opacity: 1;
}
.conversionFormCurrentBalanceTitle,
.conversionFormEstimateTitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #64ACFF;
  white-space: nowrap;
}
.conversionFormCurrentBalanceText,
.conversionFormEstimateText {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #13171C;
  word-break: break-all;
}
.conversionFormEstimateText img {
  display: block;
}
.conversionFormError {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF7DA0;
  text-align: center;
  margin-top: 5px;
}
.conversionFormButton {
  width: 100%;
  margin-top: 5px;
}
