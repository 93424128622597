@import "../../../../../../css/variables";

.activateCustodyStep {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 144px);
}
.activateCustodyStepSuccess,
.activateCustodyStepStart {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 213px);
}
.activateCustodyStepSuccess {
  align-items: center;
}
.activateCustodyStepSuccessTitle {
  margin: 16px 0 8px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #13171C;
}
.activateCustodyStepSuccessDescription {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #13171C;
}
.activateCustodyStepButton {
  height: 48px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.activateCustodyStepStartButtons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.activateCustodyStepStartCheckbox {
  margin-bottom: 16px;
}
.activateCustodyStepStartInteractive {
  margin-top: auto;
}
.activateCustodyStepSuccessButton {
  margin-top: auto;
}
.activateCustodyStepSuccessImage {
  max-width: 372px;
  min-height: 200px;
  width: 100%;
}
.activateCustodyStepStartDescription,
.activateCustodyStepStartListItem {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #13171C;
}
.activateCustodyStepStartListTitle {
  display: inline-block;
  padding: 0 12px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}
.activateCustodyStepStartListTitleBlue {
  background-color: #64ACFF;
  color: #FFFFFF;
}
.activateCustodyStepStartListTitleGreen {
  background-color: #DAFF7C;
  color: #13171C;
}
.activateCustodyStepStartFeatures {
  margin-top: 12px;
}
.activateCustodyStepStartList {
  padding: 0;
  margin: 8px 0 0;
  list-style: none;
}
.activateCustodyStepStartListItem {
  position: relative;
  padding-left: 24px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.activateCustodyStepStartListItem:not(:last-child) {
  margin-bottom: 8px;
}
.activateCustodyStepStartListItemStorage:before {
  background-image: url('../../../../../../../public/images/dashboard-page/custody-icons/custody-storage.svg');
}
.activateCustodyStepStartListItemRisk:before {
  background-image: url('../../../../../../../public/images/dashboard-page/custody-icons/custody-risk.svg');
}
.activateCustodyStepStartListItemConversions:before {
  background-image: url('../../../../../../../public/images/dashboard-page/custody-icons/custody-conversions.svg');
}
.activateCustodyStepStartListItemCosts:before {
  background-image: url('../../../../../../../public/images/dashboard-page/custody-icons/custody-costs.svg');
}

@media screen and (min-width: $screenMinWidthM) {
  .activateCustodyStep {
    min-height: 523px;
  }
  .activateCustodyStepSuccess,
  .activateCustodyStepStart {
    height: 454px;
  }
  .activateCustodyStepButton {
    width: 100%;
  }
}
