@import "src/css/variables";

.checkbox {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.checkboxInput {
  display: none;
}
.checkboxBox {
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  cursor: pointer;
}
.checkboxInput:checked + .checkboxBox {
  border: none;
  background: url('../../../images/svg/checkbox.svg');
}
.error .checkboxBox {
  border: 1px solid $colorPink;
}

