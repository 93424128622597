@import "src/css/variables";

.checkboxWithText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A4A4A4;
}
.checkboxWithText a {
  color: #64ACFF;
}
.checkboxWithTextItem {
  flex-shrink: 0;
}
.checkboxWithTextContent {
  margin-left: 10px;
}
