@import "../../../../css/variables.scss";

.payoutWalletsPopupContent {
  padding: 24px 16px;
}
.payoutWalletsPopupContent [class*="title"] {
  line-height: 30px;
}
.payoutWalletsPopupContent [class*="currenciesSelectSmallTheme_"],
.payoutWalletsPopupContent [class*="fieldInput_"] {
  height: 48px;
}
.payoutWalletsPopupContent form {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 110px);
}
.payoutWalletsPopupContent button {
  height: 48px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 22px;
}
.payoutWalletsPopupContent [class*="error_"],
.payoutWalletsPopupContent [class*="currenciesSelectError_"] {
  top: unset;
  right: unset;
  bottom: -20px;
}
.payoutWalletsPopupContent [class*="errorText_"],
.payoutWalletsPopupContent [class*="currenciesSelectErrorText_"] {
  padding: 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
}
.payoutWalletsAddWalletPopup [class*="formInput_"] {
  margin-top: 24px;
}
.payoutWalletsPopupContent [class*="formInput_"] [class*="fieldIcon_"],
.payoutWalletsPopupContent [class*="fieldBox_"] [class*="fieldIcon_"] {
  height: 45px;
}
.payoutWalletsAddWalletPopup > [class*="title"],
.payoutWalletsAddWalletPopup > [class*="description"],
.payoutWalletsAddWalletPopup > [class*="line"] {
  display: none;
}
.payoutWalletsAddWalletPopup [class*="apiError"] {
  margin-top: auto;
}

@media screen and (min-width: $screenMinWidthM) {
  .payoutWalletsPopupContent {
    padding: 32px;
  }
  .payoutWalletsAddWalletPopup form {
    min-height: 355px;
  }
}
