@import "../../../../css/variables.scss";

.addWalletToWhitelistButton {
  margin-top: auto;
}

@media screen and (min-width: $screenMinWidthM) {
  .form.addWalletToWhitelist {
    min-height: 216px;
  }
}
