@import "../../css/variables";

.massPayoutMethodsButton {
  margin-top: 16px;
}
.massPayoutMethodsButton:first-child {
  margin-top: 0;
}

@media screen and (min-width: $screenMinWidthM) {
  .massPayoutMethodsButtons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .massPayoutMethodsButton {
    width: auto;
    margin-top: 0;
    margin-left: 24px;
  }
  .massPayoutMethodsButton:first-child {
    margin-left: 0;
  }
}
