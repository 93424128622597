@import "../../../../css/variables.scss";

.subscriptionPlanTableBox {
  overflow-x: auto;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
.subscriptionPlanTable {
  width: 100%;
  min-width: 500px;
  margin-left: 0;
  margin-right: 0;
  overflow: visible;
  border-radius: 8px;
}
.subscriptionPlanTable table[class*="historyTable"] {
  min-width: auto;
}
.subscriptionPlanTable tbody:before {
  content: '';
  display: table-row;
  height: 15px;
}
.subscriptionPlanTable th[class*="historyTableTh"]:nth-child(1),
.subscriptionPlanTable td[class*="rowTd"]:nth-child(1) {
  width: 30%;
}
.subscriptionPlanTable th[class*="historyTableTh"]:nth-child(2),
.subscriptionPlanTable td[class*="rowTd"]:nth-child(2) {
  width: 15%;
}
.subscriptionPlanTable th[class*="historyTableTh"]:nth-child(3),
.subscriptionPlanTable td[class*="rowTd"]:nth-child(3) {
  width: 20%;
}
.subscriptionPlanTable [class*="overflow"] {
  max-width: 50px;
}
.subscriptionPlanTableButton {
  margin-top: 16px;
}
.subscriptionPlanTableButton button {
  width: 130px;
}
.paginator {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

@media screen and (min-width: $screenMinWidthM) {
  .subscriptionPlanTableButton {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .subscriptionPlanTable th[class*="historyTableTh"]:nth-child(1),
  .subscriptionPlanTable td[class*="rowTd"]:nth-child(1) {
    width: 25%;
  }
  .subscriptionPlanTable th[class*="historyTableTh"]:nth-child(2),
  .subscriptionPlanTable td[class*="rowTd"]:nth-child(2) {
    width: 15%;
  }
  .subscriptionPlanTable th[class*="historyTableTh"]:nth-child(3),
  .subscriptionPlanTable td[class*="rowTd"]:nth-child(3) {
    width: 15%;
  }
  .subscriptionPlanTable th[class*="historyTableTh"]:nth-child(4),
  .subscriptionPlanTable td[class*="rowTd"]:nth-child(4) {
    width: 15%;
  }
}
