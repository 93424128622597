@import "../../css/variables";

.platformUpdates {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
}
.platformUpdatesTitle {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  color: #13171C;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.6px;
}
.platformUpdatesText {
  display: block;
  color: #64ACFF;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.6px;
  margin-top: 10px;
}

@media screen and (min-width: $screenMinWidthL) {
  .platformUpdates {
    padding: 25px;
  }
}
