.simpleSelect {
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  transition: all ease .3s;
}
.simpleSelect * {
  box-sizing: border-box;
}
.simpleSelectValue {
  width: 100%;
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #13171C;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0 15px;
  outline: 0;
  cursor: pointer;
  overflow: hidden;
}
.simpleSelectLoader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  margin: auto;
}
.simpleSelectPlaceholder {
  color: #A4A4A4;
}
.simpleSelectArrow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto;
  transition: transform ease .3s;
}
.simpleSelectContent {
  display: block;
  max-height: 0;
  position: absolute;
  top: calc(100% - 4px);
  left: -1px;
  right: -1px;
  z-index: 1;
  background-color: #ffffff;
  transition: max-height ease .3s;
  overflow: hidden;
}
.simpleSelectList {
  position: relative;
  display: block;
  list-style-type: none;
  border: 1px solid #D2D2D2;
  border-top: none;
  margin: 0;
  padding: 17px 15px 10px;
  transition: border-color ease .3s;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.simpleSelectList:before {
  content: "";
  width: calc(100% - 30px);
  height: 1px;
  background-color: #CFCFCF;
  position: absolute;
  top: 4px;
  left: 15px;
  right: 15px;
  z-index: 1;
}
.simpleSelectList li {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #13171C;
  padding: 2.5px 5px;
  margin: 0 -5px;
  border-radius: 4px;
  cursor: pointer;
}
.simpleSelectList li:hover {
  background-color: #ECF5FF;
}
.simpleSelectList li:first-child {
  margin-top: 0;
}
.simpleSelectActive {
  border-color: #64ACFF;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.simpleSelectActive .simpleSelectArrow {
  transform: rotate(180deg);
}
.simpleSelectActive .simpleSelectContent {
  max-height: 500px;
}
.simpleSelectActive .simpleSelectList {
  border-color: #64ACFF;
}
.simpleSelectActive .simpleSelectScrollContainer {
  background-color: #ffffff;
}
