@import "../../../css/variables.scss";

.password {
  p {
    color: $textColor;
    font-size: 14px;
    margin-top: 0;
    font-weight: 400;
  }

  .fieldContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 10px;

    @media all and (max-width: 790px) {
      width: 100%;
    }

    input {
      margin-right: 70px;
      margin-bottom: 15px;
      padding: 10px;
      height: 15px;
      font-size: 15px;
      background-color: $bgPrimaryColor;
      border: none;

      @media all and (max-width: 790px) {
        margin-right: 0;
      }
    }
  }
}

.disabled {
  opacity: 0.4;
}
