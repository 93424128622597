@import "../../../../css/variables";

.balanceRow:not(:last-child) {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #EEEEEE;
  }
}
.balanceItem {
  padding: 28px 0 20px;
  vertical-align: top;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #13171C;
}
.balanceRow:nth-child(2) .balanceItem {
  padding-top: 16px;
}
.balanceItemPrimaryText {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #2B2B36;
}
.balanceItemSecondaryText {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #808086;
}
.balanceButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: auto;
  height: auto;
  padding: 0;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  background: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #7E7E7E;
  cursor: pointer;
}
.balanceItemLoader {
  display: inline-block;
  margin-bottom: -2px;
}
.coinImage {
  width: 36px;
  height: 36px;
  margin-right: 12px;
  margin-left: 16px;
}
.balanceAmount {
  text-align: right;
}
.balanceItemCurrency,
.balanceItemValues {
  display: flex;
  flex-direction: column;
}
.balanceItemInfo {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
}
.balanceItemInfoMain {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #F8F8F8;
}
.balanceItemInfoHandlers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding-top: 12px;
}
.balanceButtonText {
  display: none;
}

@media screen and (min-width: $screenMinWidthXXS) {
  .balanceButtonText {
    display: block;
  }
  .balanceButton {
    padding: 2px 8px 2px 4px;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .coinImage {
    width: 40px;
    height: 40px;
    margin-left: 0;
  }
  .balanceItem {
    padding: 18px 0 14px;
    vertical-align: middle;
  }
  .balanceRow:nth-child(2) .balanceItem {
    padding-top: 18px;
  }
  .balanceItemCoin {
    width: 52px;
  }
  .balanceItemInfo {
    flex-direction: row;
    margin-right: 0;
  }
  .balanceItemInfoMain {
    width: 56%;
    padding-bottom: 0;
    border-bottom: none;
  }
  .balanceItemPrimaryText {
    font-size: 16px;
    line-height: 24px;
  }
  .balanceItemSecondaryText {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .balanceItemCurrency,
  .balanceItemValues {
    width: calc(50% - 10px);
  }
  .balanceItemInfoHandlers {
    width: 44%;
    padding: 0 18px;
  }
  .balanceAmount {
    text-align: left;
  }
  .balanceButton {
    padding: 0;
    transition: all 0.3s ease;
  }
  .balanceButton:hover {
    border-color: #64ACFF;
    background-color: #64ACFF;
  }
  .balanceButtonIcon path {
    transition: all 0.3s ease;
  }
  .balanceButton:hover .balanceButtonIcon path {
    fill: #FFFFFF;
  }
  .balanceButtonText {
    display: none;
  }
}
