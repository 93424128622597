.onboardingStepsHeader:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  margin: 16px 0;
  background-color: #EEEEEE;
}
.onboardingStepsHeaderContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
}
.onboardingStepsHeaderTitle {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  color: #13171C;
}
.onboardingStepsHeaderDescription {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}
.onboardingStepsHeaderBackButton {
  display: flex;
  align-items: center;
  width: 36px;
  height: 36px;
  padding: 0;
  background-color: #FFFFFF;
  background-image: url("../../../../../../../public/images/arrow-back.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
