@import "../../../../css/variables.scss";

.unableDeleteLastApiKey {
  height: calc(100vh - 119px);
  display: flex;
  flex-direction: column;
}
.closeButton {
  margin-top: auto;
}
.unableDeleteText {
  color: #13171C;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (min-width: $screenMinWidthM) {
  .unableDeleteLastApiKey {
    height: auto;
    display: block;
  }
  .closeButton {
    margin-top: 24px;
  }
}
