.currency {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  cursor: pointer;
  outline: 0;
}
.currency * {
  box-sizing: border-box;
}
.currencyError {
  border-color: #FF7DA0;
}
.currencyDisabled {
  cursor: default;
}
.currencyPlaceholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #13171C;
}
.currencyPlaceholderText {
  font-size: 12px;
  line-height: 16px;
  color: #8A8A8E;
  padding-right: 10px;
  margin-top: 4px;
}
.currencyCoin {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.currencyCoinImage {
  flex-shrink: 0;
  margin-right: 10px;
}
.currencyCoinBody {
  flex-grow: 1;
}
.currencyCoinTicker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #13171C;
  text-transform: uppercase;
}
.currencyCoinNetwork {
  margin-left: 6px;
}
.currencyCoinName {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #A4A4A4;
}
.currenciesSelect {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  margin: auto;
  display: block;
}
div.currencySmallTheme .currencyPlaceholder {
  font-size: 16px;
  line-height: 20px;
  color: #A4A4A4;
}
.currencySmallTheme .currencyCoinImage {
  width: 25px;
  height: 25px;
}
.currencySmallTheme .currencyCoinTicker {
  font-size: 14px;
}
.currencySmallTheme .currencyCoinNetwork {
  font-size: 10px;
}
.currencySmallTheme .currencyCoinName {
  display: none;
}
.currencyWithAmountField {
  padding-right: 25px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
.currencyWithAmountField .currencyCoinNetwork {
  white-space: nowrap;
}
.currencyWithAmountField .currencyCoinName {
  display: none;
}
.currencyWithAmountField .currencyCoinImage {
  width: 24px;
  height: 24px;
}
.currencyWithAmountField .currenciesSelect {
  right: 10px;
}
.currencyWithAmountField .currencyPlaceholder {
  font-size: 16px;
  line-height: 16px;
}
