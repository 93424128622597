@import "../../../css/variables.scss";

.newInfoBox {
  display: block;
  width: 100%;
}
.newInfoBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.newInfoBoxTitle {
  width: calc(40% - 5px);
  font-weight: 600;
  font-size: 14px;
  color: #7E7E7E;
  flex-shrink: 0;
}
.newInfoBoxId {
  width: calc(60% - 5px);
  color: #13171C;
}
.newInfoBoxList {
  margin-top: 20px;
}
.newInfoBoxItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #7E7E7E;
  margin-top: 20px;
}
.newInfoBoxItem:first-child {
  margin-top: 0;
}
.newInfoBoxItemKey {
  width: calc(40% - 5px);
  font-size: 14px;
  flex-shrink: 0;
}
.newInfoBoxItemValue {
  width: calc(60% - 5px);
  color: #13171C;
  font-size: inherit;
}
.newInfoBoxItemValue strong {
  font-weight: 700;
}
.newInfoBoxItemValueFlex {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.newInfoBoxItemValueFlex > span {
  margin-right: 4px;
}
.newInfoBoxButtons {
  margin-top: 20px;
}
.newInfoBoxButton {
  margin-top: 10px;
}
.newInfoBoxButton:first-child {
  margin-top: 0;
}

@media screen and (min-width: $screenMinWidthM) {
  .newInfoBoxHeader,
  .newInfoBoxItem {
    font-size: 18px;
  }
  .newInfoBoxTitle,
  .newInfoBoxItemKey {
    width: calc(50% - 12px);
    font-size: 16px;
  }
  .newInfoBoxId,
  .newInfoBoxItemValue {
    width: calc(50% - 12px);
  }
  .newInfoBoxButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
  .newInfoBoxButton {
    margin-top: 0;
    margin-left: 12px;
  }
  .newInfoBoxButton:first-child {
    margin-left: 0;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .newInfoBoxButton {
    width: 100%;
    max-width: 250px;
    margin-left: 24px;
  }
}
