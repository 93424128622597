@import "src/css/variables";

.body {
  height: 100%;
}
.bodyWithMenu {
  display: flex;
  flex-direction: column;
}
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #F4F4F4;
  padding: 25px 15px;
  margin-left: 0;
  box-sizing: border-box;
  overflow: auto;
}
.content h1 {
  font-weight: 600;
  font-size: 36px;
  color: #13171c;
  margin: 0 0 32px 0;
}
.content h2 {
  margin: 0 0 20px 0;
}

@media screen and (min-width: $screenMinWidthL) {
  .bodyWithMenu {
    flex-direction: row;
    flex-grow: 1;
  }
  .content {
    padding: 25px;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .content {
    padding: 30px 40px 40px;
  }
}
