.copyButton {
  width: 20px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.copyButton:focus {
  outline: 0;
}
.copyButtonImgCopy {
  width: 18px;
  height: 18px;
  display: block;
  cursor: pointer;
  animation-name: copy-button-fade;
  animation-duration: .3s;
}
.copyButtonImgSuccess {
  width: 18px;
  height: 18px;
  display: block;
  animation-name: copy-button-rotate;
  animation-duration: 2s;
}

@keyframes copy-button-rotate {
  0% {
    transform: rotate(-45deg);
  }
  30% {
    transform: rotate(0);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes copy-button-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
