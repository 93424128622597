@import "../../../../../../css/variables";

.setUpAccountForm {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 144px);
}
.setUpAccountFormButton {
  height: 48px;
  margin-top: auto;
  border-radius: 8px;
  font-size: 20px;
  line-height: 28px;
}
.setUpAccountFormInput {
  margin-top: 0;
}
.setUpAccountFormInput [class*="fieldInput_"],
.setUpAccountFormInput [class*="fieldSelect_"],
.setUpAccountFormInput [class*="currenciesSelectSmallTheme_"] {
  height: 48px;
}
.setUpAccountFormInput [class*="simpleSelectValue_"] {
  line-height: 48px;
}
.setUpAccountFormInput:not(:last-child) {
  margin-bottom: 20px;
}
.setUpAccountFormInput [class*="industrySelect"] [class*="simpleSelect_"] {
  width: 100%;
  border-color: #D2D2D2;
}
.setUpAccountFormInput [class*="industrySelect"] [class*="simpleSelectActive_"] {
  border-color: #64ACFF;
}
.setUpAccountFormInput [class*="industrySelect"] [class*="simpleSelectPlaceholder"] {
  color: #A4A4A4;
  font-weight: 400;
}
.setUpAccountFormInput [class*="industrySelect"] [class*="simpleSelectList_"] {
  margin: 16px 0 0 15px;
}
.setUpAccountFormInput [class*="simpleSelectList_"] li:hover {
  background-color: #f5f7fa;
}
.setUpAccountFormInput [class*="selectList_"] {
  max-height: 210px;
}
.setUpAccountFormInput [class*="selectBody_"] {
  animation: slideFromTop 0.2s ease;
}
.setUpAccountFormInput [class*="error_"] {
  position: absolute;
  top: 0;
  right: 0;
  left: unset;
  width: fit-content;
}
.setUpAccountFormInput [class*="errorText_"] {
  width: fit-content;
}
.setUpAccountFormInput [class*="currenciesSelectItemTitle_"] {
  display: none;
}
.setUpAccountFormInput [class*="currenciesSelectItemTitle_"] {
  display: none;
}
.setUpAccountFormInput [class*='currenciesSelectItem_'] {
  min-height: 44px;
}

@keyframes slideFromTop {
  0% {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
  100% {
    max-height: 300px;
    opacity: 1;
    overflow: visible;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .setUpAccountForm {
    min-height: 523px;
  }
}
