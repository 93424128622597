@import "src/css/variables";

.filters {
  display: block;
  width: 100%;
}
.fields {
  margin-top: -10px;
}
.filterInput {
  margin-top: 10px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.button {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  height: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #64ACFF;
  background: none;
  border-radius: 0;
  padding: 0;
  transition: color ease .3s;
}
.button:hover {
  color: #3c82da;
  background: none;
}
.buttonClose {
  color: #A4A4A4;
  margin-left: auto;
  margin-right: 40px;
}

@media  screen and (min-width: $screenMinWidthM) {
  .fields {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -14px;
  }
  .filterInput {
    width: calc(50% - 14px);
    margin-left: 14px;
  }
}

@media  screen and (min-width: $screenMinWidthL) {
  .filterInput {
    width: calc(33.3% - 14px);
  }
  .filterInput:nth-child(1) {
    order: 5;
  }
  .filterInput:nth-child(2) {
    order: 6;
  }
}
