.affHeader {
  display: flex;

  .payoutBtn {
    margin: 10px 0 0 30px;
    width: 200px;
    cursor: pointer;
  }

  .successDiv {
    margin: 10px 0 0 30px;
    max-height: 70px;
  }

  .toolTip {
    z-index: 1000px;
  }

  .qIcon {
    margin: 16px;
    cursor: pointer;
    color: #64acff;
  }
}

@media all and (max-width: 720px) {
  .payoutBtn {
    margin: 0;
    width: 150px;
  }

  .successDiv {
    height: auto;
    max-height: 70px;
    width: auto;
  }
}
