@import "../../../../css/variables";

.popupUploadCsv {
  display: flex;
  flex-direction: column;
}
.description {
  color: $colorTextBlack;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
}
.description a {
  font-size: 18px;
  line-height: 18px;
}
.description img {
  display: block;
  max-width: 100%;
  margin-top: 40px;
}
.buttons {
  margin-top: auto;
}
.button {
  margin-top: 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .popupUploadCsv {
    display: block;
    max-width: 810px;
    padding: 64px;
  }
  .description img {
    height: 58px;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
  }
  .button {
    width: 185px;
    height: 50px;
    margin-top: 0;
    flex-shrink: 0;
  }
}
