.referralProgram {
  .fieldContainer {
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;

    p {
      font-size: 14px;
    }
  }
}
