.invoiceResultModal {
  padding: 20px;
  position: relative;
}
.invoiceResultModal > p {
  color: #2B2B37;
  font-size: 18px;
  margin-top: 0;
  font-weight: 400;
}

@media all and (max-width: 790px) {
  .invoiceResultModal {
    padding: 5px;
  }
}
