@import "../../../../css/variables.scss";

.whitelistPopup {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 24px 16px;
}
.whitelistPopup [class*="title"] {
  font-size: 24px;
  line-height: 30px;
}
.whitelistPopupButtons {
  margin-top: auto;
  padding-top: 24px;
}
.whitelistPopupButton {
  width: 100%;
}
.whitelistPopupButton {
  margin-top: 24px;
}
.whitelistPopupButton:first-child {
  margin-top: 0;
}
.whitelistPopupField {
  margin-top: 24px;
}
.whitelistPopupError {
  font-size: 14px;
  line-height: 16px;
  color: #FF7DA0;
  text-align: center;
  margin-top: 5px;
  margin-bottom: -20px;
}

@media screen and (min-width: $screenMinWidthM) {
  .whitelistPopup {
    max-width: 424px;
    display: block;
    padding: 32px;
  }
  .whitelistPopupButton {
    margin-top: 12px;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .whitelistPopupButton {
    width: 100%;
  }
}
