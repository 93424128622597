@import "../../../../css/variables.scss";

.apiKeysPopup {
  padding: 24px 16px;
}
.apiKeysPopup > [class*="title"] {
  font-size: 24px;
  line-height: 30px;
}
.apiKeysPopup form {
  height: calc(100vh - 119px);
  display: flex;
  flex-direction: column;
}
.apiKeysPopup button {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 23px;
};
.apiKeysAddWalletPopup > [class*="title_"],
.apiKeysAddWalletPopup > [class*="description_"],
.apiKeysAddWalletPopup > [class*="line_"] {
  display: none;
}
.apiKeysAddWalletPopup [class*="apiError_"] {
  margin-top: auto;
}
.apiKeysAddWalletPopup [class*="currenciesSelectSmallTheme_"],
.apiKeysAddWalletPopup [class*="fieldInput_"] {
  height: 48px;
}
.apiKeysAddWalletPopup [class*="formInput_"] [class*="fieldIcon_"],
.apiKeysAddWalletPopup [class*="fieldBox_"] [class*="fieldIcon_"] {
  height: 45px;
}

@media screen and (min-width: $screenMinWidthM) {
  .apiKeysPopup {
    max-width: 424px;
    padding: 32px;
  }
  .apiKeysPopup form {
    height: auto;
    display: block;
  }
  .apiKeysAddWalletPopup [class*="apiError_"] {
    margin-top: 108px;
  }
}
