@import "../../css/variables.scss";

.innerNavigationTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #A4A4A4;
}
.innerNavigation {
  margin-left: -15px;
  margin-right: -15px;
}
.innerNavigationList {
  min-width: 885px;
}
.sectionContent {
  margin-top: 24px;
  padding: 0;
}
.sectionComponent {
  opacity: 0;
  visibility: hidden;
  transition: opacity, visibility, ease-in-out .3s;
}
.sectionComponentActive {
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: $screenMinWidthL) {
  .innerNavigation {
    margin-left: -25px;
    margin-right: -25px;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .section {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    background-color: #ffffff;
    border-radius: 8px;
  }
  .sectionNavigation {
    width: 200px;
    flex-shrink: 0;
    padding: 24px 0;
    border-right: 1px solid #EEEEEE;
  }
  .innerNavigationTitle {
    margin-left: 16px;
    margin-bottom: 4px;
  }
  .innerNavigation {
    margin-left: 0;
    margin-right: 0;
  }
  .innerNavigationList {
    min-width: 100%;
  }
  .sectionContent {
    flex-grow: 1;
    padding: 24px;
    margin-top: 0;
  }
  .sectionContentIpn {
    display: flex;
  }
}
