@import "src/css/variables";

.historyTableBox {
  width: calc(100% + 50px);
  background-color: #ffffff;
  margin-left: -25px;
  margin-right: -25px;
  margin-top: 20px;
  padding: 25px 10px;
  overflow-x: auto;
  box-sizing: border-box;
}
.historyTableBox * {
  box-sizing: border-box;
}
.historyTable {
  width: 100%;
  min-width: 760px;
  border-collapse: separate;
  border-spacing: 0 3px;
}
.historyTableThead th {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.6px;
  color: #7E7E7E;
  border-bottom: 1px solid #eeeeee;
  padding-right: 5px;
  padding-bottom: 15px;
}
.historyTableThead th:first-child {
  position: relative;
  min-width: 150px;
  border-color: transparent;
  padding-left: 15px;
}
.historyTableThead th:nth-child(2) {
  min-width: 120px;
}
.historyTableThead th:nth-child(3) {
  min-width: 110px;
}
.historyTableThead th:nth-child(4) {
  min-width: 80px;
}
.historyTableThead th:nth-child(5) {
  min-width: 140px;
}
.historyTableThead th:last-child {
  position: relative;
  min-width: 160px;
  border-color: transparent;
  padding-right: 15px;
}
.historyTableThead th:first-child:before,
.historyTableThead th:last-child:after {
  content: "";
  width: calc(100% - 15px);
  height: 1px;
  background-color: #eeeeee;
  position: absolute;
  bottom: -1px;
}
.historyTableThead th:first-child:before {
  right: 0;
}
.historyTableThead th:last-child:after {
  left: 0;
}
.historyTableTbody:before {
  content: '';
  display: table-row;
  height: 15px;
}
.historyTableLoaderTr td {
  padding: 25px 0 15px 15px;
}
.historyTableEmptyTr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #A4A4A4;
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 15px;
}
.historyTableBodyTr {
  margin-left: -15px;
  margin-right: -15px;
}
button.historyTableEmptyButton {
  width: 180px;
  margin-top: 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .historyTableBox {
    width: 100%;
    border-radius: 8px;
    margin-left: 0;
    margin-right: 0;
  }
  .historyTableLoaderTr {
    text-align: center;
    padding-left: 0;
  }
  .historyTableEmptyTr td {
    text-align: center;
    padding-left: 0;
  }
  button.historyTableEmptyButton {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .historyTableEmptyTr td {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-width: 1100px) {
  .historyTableThead th:first-child {
    width: 15%;
  }
  .historyTableThead th:nth-child(2) {
    width: 10%;
  }
  .historyTableThead th:nth-child(3) {
    width: 10%;
  }
  .historyTableThead th:nth-child(4) {
    width: 10%;
  }
  .historyTableThead th:nth-child(5) {
    width: 43%;
  }
  .historyTableThead th:last-child {
    width: 12%;
  }
}

@media screen and (min-width: 1400px) {
  .historyTableThead th {
    font-size: 16px;
  }
  .historyTableThead th:first-child {
    width: 13%;
  }
  .historyTableThead th:nth-child(5) {
    width: 45%;
  }
  .historyTableThead th:last-child {
    min-width: 180px;
  }
}
