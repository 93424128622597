.whiteList {
  color: #13171C;
}
.whiteListTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}
.whiteListCurrentIP {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin-top: 32px;
  color: #13171C;
}
.whiteListCurrentIPLoader {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.whiteListCurrentIPError {
  color: #FF7DA0;
}
.whiteListCurrentIPError img {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.whiteListAdditionalIP {
  margin-top: 32px;
}
.whiteListAdditionalIPField > [class*="fieldTitle"] {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #13171C;
  padding: 0;
}
.whiteListAdditionalIPText {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}
.whiteListAdditionalIPText img {
  display: inline-block;
  vertical-align: top;
  margin-top: 1px;
  margin-right: 10px;
}
.whiteListAdditionalIPText a {
  color: #64ACFF;
}
.whiteListAgreement {
  margin-top: 48px;
}
.whiteListButtonBox {
  margin-top: 24px;
}
.whiteListButtonError {
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #959595;
  text-align: center;
  margin-top: 15px;
}
.whiteListButtonError img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.whiteListButtonApiError {
  color: #FF7DA0;
}

@media screen and (min-width: 480px) {
  .whiteListButtonBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .whiteListButton {
    width: 175px;
  }
  .whiteListButtonError {
    margin-left: 25px;
    margin-top: 0;
  }
}

@media screen and (min-width: 1200px) {
  .whiteListAdditionalIP {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .whiteListAdditionalIPField {
    width: 390px;
    flex-shrink: 0;
  }
  .whiteListAdditionalIPText {
    max-width: 410px;
    align-items: center;
    margin-top: 0;
    margin-left: 40px;
  }
}
