.table {
  border: 2px solid #64acff;
  box-shadow: 7px 7px 0px 0px rgba(236, 239, 241, 1);
  width: 100%;
  font-weight: 600;
  font-size: 13px;

  .bigRow {
    min-width: 10px;
    max-width: 100px;
    word-break: break-all;
  }

  border-collapse: collapse;

  th {
    height: 48px;
    background-color: #f7f8fa;
    color: #2b2b37;
    opacity: 0.6;
  }

  td {
    height: 55px;
    color: #2b2b37;
    text-align: center;
    border-top: 1px solid #ceccd9;

    span {
      padding: 2px 7px;
      display: inline-block;
    }
  }

  .tableHeader {
    color: #2b2b37;
    opacity: 0.6;
    display: none;
    flex-basis: 30%;
  }

  .tableValue {
    flex-basis: 50%;
  }

  .tableValueBetween {
    flex-basis: 50%;
    display: flex;
    justify-content: space-between;
  }
}
.emptyMessage {
  font-size: 19px;
}
