.successImage {
  width: 339px;
  height: 182px;
  margin: 60px auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../../images/svg/success-sign-up.svg');
}

.footer {
  margin-top: 55px;
}

.contentMsgSuccess {
  margin: 30px 0px 20px;
}

.contentText {
  margin-bottom: 8px;
}

.contentSubText {
  margin-bottom: 75px;
}
