@import "../../../css/variables.scss";

.tfaSettings {
  .tfaSettingsBlock {
    > div {
      display: flex;
      align-items: center;
      @media screen and (max-width: $screenMaxWidthXS) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
      }
    }
    p {
      margin-right: auto;
      color: $textColor;
      font-size: 14px;
      font-weight: 400;
      width: 90%;
      @media screen and (max-width: $screenMaxWidthXS) {
        margin-right: 10px;
      }
    }
  }
  .fieldContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 10px;

    @media all and (max-width: 790px) {
      width: 100%;
    }

    input {
      margin-right: 70px;
      margin-bottom: 15px;
      padding: 10px;
      height: 15px;
      font-size: 15px;
      background-color: $bgPrimaryColor;
      border: none;

      @media all and (max-width: 790px) {
        margin-right: 0;
      }
    }
  }
}
