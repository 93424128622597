@import "../../../../css/variables.scss";

.billingPlanFilter {
  margin-top: 24px;
}
.button {
  height: 44px;
}
.searchInput {
  height: 44px;
  margin-top: 24px;
}

@media screen and (min-width: $screenMinWidthM) {
  .billingPlanFilter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .button {
    order: 2;
    width: auto;
    min-width: 180px;
  }
  .searchInput {
    order: 1;
    max-width: 320px;
    margin-top: 0;
    margin-right: auto;
  }
}
