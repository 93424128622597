@import "../../../../../css/variables.scss";

.tfaTypeButton {
  margin-bottom: 16px;
  border: 1px solid $colorLightGray;
  min-height: 100px;
  cursor: pointer;
  .tfaTypeDescription {
    padding: 20px;
    > * {
      margin: 0;
    }
    > h4 {
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 600;
      .arrow {
        display: inline-block;
        position: relative;
        margin-left: 5px;
        margin-bottom: 6px;
        width: 20px;
        height: 2px;
        background-color: $colorBlue;
        &::after {
          content: "";
          display: block;
          position: absolute;
          border: solid $colorBlue 8px;
          border-width: 0 2px 2px 0;
          height: 8px;
          width: 8px;
          transform: rotate(-45deg);
          top: -4px;
          right: 0;
        }
      }
    }
    > p {
      font-size: 16px;
    }
  }
  &:hover {
    box-shadow: 7px 7px 0 0 $shadowColor;
  }
}

.errorNotify {
  box-sizing: border-box;
  height: 100px;
  padding: 20px;
  margin-bottom: 16px;
  border: 1px solid rgba($colorPink, 0.6);
  background-color: rgba($colorPink, 0.1);
  color: $colorPink;
}
