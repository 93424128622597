.topCoinsTitle {
  color: #13171C;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}
.topCoinsList {
  margin-top: 16px;
}
.topCoinsItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #13171C;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
}
.topCoinsItemName {
  color: #FFF;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  background-color: #A0A0A0;
  border-radius: 4px;
  padding: 4px 8px;
  margin-right: 20px;
}
.topCoinsItemRight {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}
.topCoinsItemTicker {
  margin: 0 2px;
}
