@import "../../css/variables";

.customerPageLoader {
  display: block;
  margin: 0 auto;
}
.customerPageHeader {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #13171c;
}
.customerPageHeaderBack {
  margin-right: 8px;
}
.customerPageInfo {
  margin-top: 24px;
}
.customerPageInfoIcon {
  margin-bottom: -1px;
}
.customerPageInfoLoader {
  margin-bottom: -2px;
}
.customerPageInfo > div {
  min-width: 300px;
}
.customerPageButtons {
  margin-top: 32px;
}

@media screen and (min-width: $screenMinWidthM) {
  .customerPageInfo {
    margin-top: 28px;
  }
  .customerPageInfoIcon {
    margin-bottom: -2px;
    cursor: pointer;
  }
  .customerPageInfo [class*="userDataInfoHeaderItem"]:first-child,
  .customerPageInfo [class*="userDataInfoBodyItem"]:first-child {
    width: 18%;
  }
  .customerPageInfo [class*="userDataInfoHeaderItem"]:nth-child(2),
  .customerPageInfo [class*="userDataInfoBodyItem"]:nth-child(2) {
    width: 35%;
  }
  .customerPageInfo [class*="userDataInfoHeaderItem"]:nth-child(3),
  .customerPageInfo [class*="userDataInfoBodyItem"]:nth-child(3) {
    width: 25%;
  }
  .customerPageInfo [class*="userDataInfoHeaderItem"]:nth-child(4),
  .customerPageInfo [class*="userDataInfoBodyItem"]:nth-child(4) {
    width: 22%;
  }
  .customerPageButtons {
    margin-top: 24px;
  }
}
