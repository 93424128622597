@import "../../../../css/variables";

.whitelistTexts {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #13171C;
  margin-top: 24px;
  margin-bottom: 20px;
}
.whitelistTextsInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #959595;
  margin: 4px 0 16px;
}
.whitelistTexts a {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.whitelistTexts picture {
  display: block;
  text-align: center;
  margin-top: 16px;
}
.whitelistTexts picture img {
  max-width: 100%;
  min-height: 50px;
}

@media screen and (min-width: $screenMinWidthXXS) {
  .whitelistTexts picture img {
    min-height: 60px;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .whitelistTexts {
    font-size: 18px;
    line-height: 26px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
  .whitelistTexts a {
    font-size: 18px;
    line-height: 26px;
  }
}
