.tabs {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #64ACFF;
  overflow: hidden;
}
.tab {
  width: auto;
  height: auto;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #7E7E7E;
  text-align: center;
  background-color: #ffffff;
  flex-grow: 1;
  outline: 0;
  border: 0;
  padding: 4px 8px;
  transition: color, background-color, ease .3s;
  cursor: pointer;
}
.tabActive {
  color: #ffffff;
  background-color: #64ACFF;
}
