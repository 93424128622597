@import "src/css/variables";

.switch {
  display: inline-block;
  font-size: 20px;
  height: 30px;
  min-width: 55px;
  background: rgba(255, 125, 160, 0.5);
  border-radius: 25px;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  div {
    cursor: pointer;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 2px;
    left: 3px;
    border-radius: 50%;
    background: #ff7da0;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
  }

  input:checked + div {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.switchDisable {
  display: inline-block;
  font-size: 20px;
  height: 30px;
  min-width: 55px;
  background: rgba(210, 210, 210, 0.5);
  border-radius: 25px;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  div {
    cursor: pointer;
    height: 25px;
    width: 25px;
    position: absolute;
    top: 2px;
    left: 3px;
    border-radius: 50%;
    background: #d2d2d2;
    box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
  }

  input:checked + div {
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.switchBlue {
  height: 16px;
  min-width: 30px;
  background: $colorWhite;
  border: 1px solid $colorBlue;

  .marker {
    height: 12px;
    width: 14px;
    background: $colorBlue;
    top: 2px;
    left: 1px;
  }
}
