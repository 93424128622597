@import "../../../../css/variables.scss";

.loader {
  display: block;
  margin: 0 auto;
}
.customerActiveBillingPlans {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100vh - 175px);
}
.customerActiveBillingPlansWrapper {
  flex-grow: 1;
  overflow-y: auto;
}
.button {
  width: 100%;
  flex-shrink: 0;
  margin-top: 16px;
}
.buttonUnsubscribe {
  width: 100%;
  font-weight: 600;
  color: #FF7DA0;
  border-color: #FF7DA0;
  flex-shrink: 0;
  margin-top: 16px;
}
.buttonUnsubscribe:hover {
  background-color: rgba(#FF7DA0, .1);
}

@media screen and (min-width: $screenMinWidthM) {
  .customerActiveBillingPlans {
    height: 350px;
  }
}

@media screen and (min-width: $screenMinWidthXXL) {
  .customerActiveBillingPlans {
    height: 450px;
  }
}
