@import "../../../css/variables";

.faq > :first-child {
  display: block;
}
.faq h2 {
  text-align: center;
  line-height: 32px;
  font-size: 24px;
}

@media screen and (min-width: $screenMinWidthL) {
  .faq h2 {
    text-align: left;
  }
}
