@import "../../css/variables";

.systemStatuses {
  color: #13171C;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.6px;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
}
.systemStatusesItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}
.systemStatusesItem:first-child {
  margin-top: 0;
}
.systemStatusesItemKey {
  margin-right: 10px;
}
.systemStatusesItemValue {
  color: #1ED025;
}
.systemStatusesText {
  color: #A4A4A4;
  margin-top: 10px;
}
.systemStatusesText a {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.6px;
  color: #64ACFF;
}

@media screen and (min-width: $screenMinWidthL) {
  .systemStatuses {
    padding: 25px;
  }
}
