@import "../../../../css/variables";

.statsAreaChart {
  height: 200px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #A4A4A4;
}
.statsAreaChartTooltipLabel {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #13171C;
  border-radius: 8px 8px 0 0;
  background: #F9F9F9;
  padding: 7px 15px;
}
.statsAreaChartTooltip {
  min-width: 210px;
}
.statsAreaChartTooltip :global(.recharts-tooltip-item-name) {
  color: #A4A4A4;
}
.statsAreaChartTooltip :global(.recharts-tooltip-item-separator) {
  visibility: hidden;
  opacity: 0;
}
.statsAreaChartTooltip :global(.recharts-tooltip-item-unit) {
  display: block;
}
.statsAreaChartTooltip :global(.recharts-tooltip-item:last-child) {
  padding-top: 0!important;
}

@media screen and (min-width: $screenMinWidthL) {
  .statsAreaChart {
    height: 230px;
  }
}
