.enableCustodySteps {
  max-width: 1200px;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 25px;
  box-sizing: border-box;
}
.enableCustodySteps * {
  box-sizing: border-box;
}
.enableCustodyStep {
  display: block;
}
.enableCustodyStepFooter {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #13171C;
  margin-top: 30px;
}
.enableCustodyStepFooter a {
  color: #64ACFF;
  font-size: 16px;
  line-height: 20px;
}
