@import "src/css/variables";

.label{
  display: flex;
  flex-direction: column;
  color:$colorDark;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 27px;
}

.title{
  font-size: 15px;
  display: block;
  line-height: 17px;
  margin-left: 17px;
  margin-bottom: 10px;
  white-space: nowrap;
  color: $colorTextBlack
}

.errorText {
  font-size: 12px;
  display: block;
  line-height: 19px;
  margin-right: 8px;
  margin-bottom: 8px;
  margin-left: auto;
  line-height: 15px;
  text-align: right;
  color: $colorPink;
}

.input{
  height: 37px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: $colorWhite!important;
  border: 1px solid $colorD2;
  width: 100%;
  transition: .3s;
  padding: 9px 40px 9px 17px;
  font-size: 16px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type=number] {
    -moz-appearance: textfield;
  }

  &:focus{
    border: 1px solid darken($colorD2,30);
  }

  &:valid{
    border:1px solid #949494;
  }

  &:hover{
    border-color: $colorBlue;
  }
}


.error{
  .input{
    border-color: $colorPink;
  }
}

.errorMsg {
  font-size: 14px;
  height: 37px;
  color: $colorPink;
  position: absolute;
  width: calc(100% / 1.5);
  right: calc( -100% / 1.5 - 15px);
  bottom: 20px;
  display: flex;
  align-items: center;
}

.errorBottom{
  height: 53px;
  .errorMsg{
  position: static;
  }
}

@media all and (max-width:450px) {
  .infoRow {
    height: unset
  }
}
