@import "../../../../css/variables.scss";

.payoutWalletsTableRow {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ECF5FF;
  }
}
.payoutWalletsTableRowActive {
  background-color: #ECF5FF;
  border-radius: 4px;
}
.payoutWalletsTableRow:not(:last-child) {
  border-bottom: 1px solid #EEEEEE;
}
.payoutWalletsTableRowContent {
  display: flex;
  flex-direction: column;
}
.payoutWalletsTableRowCoinInfo {
  margin: 2px 0;
  padding: 2px 0;
}
.payoutWalletsTableRowPaymentInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.payoutWalletsTableRowCopy div,
.payoutWalletsTableRowCopy img {
  height: 16px;
  width: 16px;
}
.payoutWalletsTableRowOptions {
  height: auto;
  margin-top: 4px;
  padding: 0;
  background-color: transparent;
}
.payoutWalletsTableRowOptions svg {
  transition: fill ease .3s;
}
.payoutWalletsTableRowOptions:hover svg {
  fill: #64ACFF;
}
.payoutWalletsTableRowPriority {
  width: fit-content;
  margin-left: 24px;
  padding: 1px 4px;
  border-radius: 2px;
  background-color: #DAFF7C;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 600;
  line-height: 10px;
  cursor: pointer;
}

@media screen and (min-width: $screenMinWidthL) {
  .payoutWalletsTableRow {
    align-items: center;
  }
  .payoutWalletsTableRow:not(:last-child) {
    border-bottom: none;
  }
  .payoutWalletsTableRowContent {
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
  }
  .payoutWalletsTableRowCoinInfo {
    order: 1;
    width: 152px;
    margin: 0 20px 0 0;
  }
  .payoutWalletsTableRowPaymentInfo {
    order: 2;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-left: 0;
  }
  .payoutWalletsTableRowPriority {
    order: 3;
    height: fit-content;
    padding: 2px 8px;
    margin-left: auto;
    text-transform: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
  .payoutWalletsTableRowOptions {
    margin-left: 20px;
    margin-top: 0;
    display: flex;
  }
}
