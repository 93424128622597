@import "../../../../css/variables.scss";

.customerCreateBillingPlanForm {
  min-height: calc(100vh - 235px);
  display: flex;
  flex-direction: column;
}
.customerCreateBillingPlanFormButton {
  width: 100%;
  margin-top: 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .customerCreateBillingPlanForm {
    min-height: auto;
    display: block;
  }
}
