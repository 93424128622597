@import "src/css/variables";

.row {
  height: 36px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A4A4A4;
  background-color: transparent;
  border-radius: 4px;
}
.row:hover {
  background-color: #ECF5FF;
  cursor: pointer;
}
.rowTd {
  padding: 8px 10px 8px 0;
}
.rowTd:first-child {
  padding-left: 15px;
  border-radius: 4px 0 0 4px;
}
.rowTd td:last-child {
  padding-right: 15px;
  border-radius: 0 4px 4px 0;
}
.colorBlack {
  color: #13171C;
}
.rowTdSecondItem {
  margin-top: 4px;
}
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
