@import "../../../../css/variables.scss";

.massPayoutsTable {
  margin-top: 24px;
}
.massPayoutsTable th,
.massPayoutsTable td {
  width: 25%;
}
.massPayoutsTable th:nth-child(2),
.massPayoutsTable td:nth-child(2) {
  width: 50%;
  max-width: 200px;
}

@media screen and (min-width: 480px) {
  .massPayoutsTable [class*="historyTableEmptyTr"],
  .massPayoutsTable [class*="historyTableLoaderTr"],
  .massPayoutsTable [class*="historyTableLoaderTr"] {
    text-align: center;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .massPayoutsTable th,
  .massPayoutsTable td {
    width: 16.6%;
  }
  .massPayoutsTable th:nth-child(2),
  .massPayoutsTable td:nth-child(2) {
    width: 16.6%;
    max-width: 100%;
  }
  .massPayoutsTable th:nth-child(3),
  .massPayoutsTable td:nth-child(3) {
    max-width: 100px;
  }

  .massPayoutsTable th:nth-child(6),
  .massPayoutsTable td:nth-child(6) {
    width: 10%;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .massPayoutsTable th:nth-child(1),
  .massPayoutsTable td:nth-child(1) {
    width: 10%;
  }
  .massPayoutsTable th:nth-child(2),
  .massPayoutsTable td:nth-child(2) {
    width: 12%;
  }
  .massPayoutsTable th:nth-child(3),
  .massPayoutsTable td:nth-child(3) {
    width: 20%;
    max-width: 200px;
  }
  .massPayoutsTable th:nth-child(4),
  .massPayoutsTable td:nth-child(4),
  .massPayoutsTable th:nth-child(5),
  .massPayoutsTable td:nth-child(5) {
    width: 16%;
  }
}
