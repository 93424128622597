@import "../../../../css/variables.scss";

.deleteWalletButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.deleteWalletApiError {
  margin-top: auto;
  min-height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF7DA0;
  text-align: center;
}
.deleteWalletLast {
  line-height: 24px;
  color: #13171C;
}

@media screen and (min-width: $screenMinWidthM) {
  .form.deleteWallet {
    min-height: 203px;
  }
}
