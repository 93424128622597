@import "../../css/variables.scss";

.innerNavigation {
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
.innerNavigationList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
  padding: 0 16px;
}
.innerNavigationListLine {
  display: none;
}
.innerNavigationLink {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #13171C;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 12px 16px;
  cursor: pointer;
}
.innerNavigationLink svg {
  display: block;
  margin-top: 4px;
  transition: fill ease .3s;
}
.innerNavigationLinkActive {
  border-color: #64ACFF;
}
.innerNavigationLinkActive svg {
  fill: #64ACFF;
}

@media screen and (min-width: $screenMinWidthL) {
  .innerNavigationList {
    padding: 0 25px;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .innerNavigationList {
    position: relative;
    display: block;
    white-space: normal;
    padding: 0;
  }
  .innerNavigationListLine {
    display: block;
    width: 2px;
    height: 46px;
    background-color: #64ACFF;
    position: absolute;
    top: 0;
    left: 0;
    transition: top, height, linear .2s;
  }
  .innerNavigationLink {
    transition: background-color ease .3s;

    &:hover svg {
      fill: #64ACFF;
    }
  }
  .innerNavigationLinkActive {
    background-color: #FAFAFA;
    border-color: transparent;
    border-radius: 0;
  }
}
