.switcher {
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 30px;
  height: 16px;
  background-color: rgba(210, 210, 210, 0.5);
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  padding: 0 1px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color ease .3s;
  outline: 0;
}
.switcherActive {
  background-color: #1ED025;
}
.switcherActive .switcherBox {
  margin-left: calc(100% - 12px);
}
.switcherBox {
  width: 12px;
  height: 12px;
  background-color: #ffffff;
  border-radius: 2px;
  transition: all ease .3s;
}
