@import "../../css/variables.scss";

.invoicePage {
  padding-bottom: 40px;
}
.invoicePage * {
  box-sizing: border-box;
}
.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.invoicePageInfoBlock {
  margin-top: -16px;
  margin-bottom: 32px;
}
.infoIcon {
  flex-shrink: 0;
  margin-left: 10px;
  margin-top: 18px;
  cursor: pointer;
}
.textBox {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #13171C;
  margin-bottom: 20px;
}
.searchInput {
  margin-top: 10px;
}
.searchFilterMenuIcon svg {
  display: block;
  transition: fill ease .3s;
}
.searchFilterMenuIconActive svg {
  fill: #64ACFF;
}
.invoiceFilters {
  max-height: 0;
  overflow: hidden;
  transition: max-height linear .3s;
}
.invoiceFiltersActive {
  max-height: 700px;
  overflow: visible;
}
.invoiceFiltersBox {
  margin-top: 20px;
}
.popupPaymentLinkCreate [class*="content"] {
  max-width: 500px;
}
.popupPaymentLinkResult {
  z-index: 13;
}
.popupPaymentLinkResult [class*="content"] {
  max-width: 490px;
  padding: 16px;
}
.popupInvoice [class*="content"] {
  max-width: 920px;
  padding: 50px 15px 20px;
}
.popupPaymentInfo [class*="content"] {
  max-width: 900px;
}
.paymentInfoButtons {
  margin-top: 20px;
}
.paymentInfoButton {
  margin-top: 10px;
}
.paymentInfoButton:first-child {
  margin-top: 0;
}
.paginator {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

@media screen and (min-width: $screenMinWidthS) {
  .popupPaymentLinkCreate [class*="content"] {
    padding: 64px;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .searchBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .searchBoxButton {
    width: auto;
    flex-shrink: 0;
  }
  .searchInput {
    max-width: 300px;
    margin-top: 0;
  }
  .paymentInfoButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .paymentInfoButton {
    width: auto;
    min-width: 120px;
    flex-shrink: 0;
    margin-left: 15px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 0;
  }
  .paymentInfoButton:first-child {
    margin-left: 0;
  }
}
