@import "src/css/variables";

.historyItem {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A4A4A4;
  background-color: transparent;
  border-radius: 4px;
}
.historyItem a {
  font-size: 14px;
  line-height: 16px;
  color: #64ACFF;
}
.historyItem td {
  padding: 9px 10px 9px 0;
}
.historyItem td:first-child {
  min-width: 150px;
  padding-left: 15px;
  border-radius: 4px 0 0 4px;
}
.historyItem td:nth-child(2) {
  min-width: 120px;
  word-break: break-all;
}
.historyItem td:nth-child(3) {
  min-width: 110px;
  white-space: nowrap;
}
.historyItem td:nth-child(4) {
  min-width: 80px;
}
.historyItem td:nth-child(5) {
  min-width: 140px;
  word-break: break-all;
}
.historyItem td:last-child {
  min-width: 160px;
  padding-right: 15px;
  border-radius: 0 4px 4px 0;
}
.historyItemColorBlack {
  color: #13171C;
}

@media screen and (min-width: $screenMinWidthM) {
  .historyItem {
    transition: background ease .3s;
  }
  .historyItem:hover {
    background-color: #ECF5FF;
    cursor: pointer;
  }
}

@media screen and (min-width: 1100px) {
  .historyItem td:first-child {
    width: 15%;
  }
  .historyItem td:nth-child(2) {
    width: 10%;
  }
  .historyItem td:nth-child(3) {
    width: 10%;
  }
  .historyItem td:nth-child(4) {
    width: 10%;
  }
  .historyItem td:nth-child(5) {
    width: 43%;
  }
  .historyItem td:last-child {
    width: 12%;
  }
}

@media screen and (min-width: 1400px) {
  .historyItem {
    font-size: 16px;
  }
  .historyItem a {
    font-size: 16px;
    line-height: 18px;
  }
  .historyItem td:first-child {
    width: 13%;
  }
  .historyItem td:nth-child(5) {
    width: 45%;
  }
  .historyItem td:last-child {
    min-width: 180px;
  }
}
