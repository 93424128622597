.activateProviders {
  position: relative;
  padding: 12px;
  margin-top: 20px;
  border-radius: 8px;
  background: #F4F6F9;
}
.activateProvidersTitle {
  color: #13171C;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 8px;
}
.activateProvidersDescription {
  margin-bottom: 12px;
  color: #13171C;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.activateProvidersCards {
  margin-bottom: 12px;
}
.proceedButton {
  margin-top: 12px;
  border-radius: 8px;
}
.activateProvidersPermissionBlock{
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: all 0.5s;
}
.activateProvidersPermissionBlockActive {
  height: auto;
  visibility: visible;
  opacity: 1;
}
.activateProvidersInitialBlock {
  display: none;
}
.activateProvidersInitialBlockActive {
  display: block;
}
