@import "src/css/variables";

.historyItem {
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A4A4A4;
  background-color: transparent;
  border-radius: 4px;
}
.historyItemTd {
  padding: 8px 10px 8px 0;
}
.historyItemTd:first-child {
  padding-left: 15px;
  border-radius: 4px 0 0 4px;
}
.historyItemTd:nth-child(2) {
  white-space: nowrap;
}
.historyItemTd:nth-child(3) {
  word-break: break-all;
}
.historyItem td:last-child {
  padding-right: 15px;
  border-radius: 0 4px 4px 0;
}
.historyItemColorBlack {
  color: #13171C;
}
.historyItemTdCapitalized {
  text-transform: capitalize;
}

@media screen and (min-width: $screenMinWidthM) {
  .historyItem {
    transition: background ease .3s;
  }
  .historyItem:hover {
    background-color: #ECF5FF;
    cursor: pointer;
  }
}

@media screen and (min-width: 1400px) {
  .historyItem {
    font-size: 16px;
  }
}
