.addAccountSection {
  padding: 16px 24px 24px;
}
.addAccountSection h2 {
  line-height: 32px;
}
.addAccountSectionDescription {
  margin-top: -8px;
  margin-bottom: 24px;
}
.addAccountSectionButton {
  padding: 0 40px;
  margin-top: 20px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
}
