@import "../../../../css/variables.scss";

.groupCurrenciesHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.groupCurrenciesContent {
  margin-top: 10px;
}
.groupCurrenciesList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-top: -15px;
}
.groupCurrenciesItem {
  margin-left: 15px;
  margin-top: 15px;
}
.groupCurrenciesTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #13171C;
  margin-left: 10px;
}
.groupCurrenciesLoader {
  margin-left: 10px;
}
.groupCurrencies .link {
  font-weight: 600;
  font-size: 16px;
  color: #64ACFF;
  border-bottom: none;
}
.info {
  margin-top: 8px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.defaultCursor * {
  cursor: default;
}

@media screen and (min-width: $screenMinWidthM) {
  .groupCurrenciesList {
    margin-left: -20px;
    margin-top: -20px;
  }
  .groupCurrenciesItem {
    margin-left: 20px;
    margin-top: 20px;
  }
}
