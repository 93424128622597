@import "../../css/variables";

.balances {
  width: 100%;
  box-sizing: border-box;
}
.balances * {
  box-sizing: border-box;
}
.balancesTable {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}
.balancesTableHeader {
  display: none;
}

@media screen and (min-width: $screenMinWidthM) {
  .balancesTableHeader {
    display: table-row;
    border-bottom: 1px solid #EEEEEE;
  }
  .balancesTableHeader * {
    color: #A4A4A4;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
  .balancesTableHeaderItem {
    padding: 8px 0 16px;
    text-align: left;
  }
  .balancesTableHeaderInfo {
    width: 100%;
    display: flex;
  }
  .balancesTableHeaderActions {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    width: 44%;
    padding: 0 9px;
  }
  .balancesTableHeaderAmount {
    display: flex;
    justify-content: right;
    width: 56%;
  }
  .amount {
    width: calc(50% - 10px);
    text-align: left;
  }
}
