@import "../../css/variables";

.section {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 25px;
}
.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h2.sectionTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  margin: 0;
}
.sectionContent {
  margin-top: 16px;
}

@media screen and (min-width: $screenMinWidthS) {
  h2.sectionTitle {
    font-size: 24px;
    line-height: 30px;
  }
}
