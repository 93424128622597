.selectField {
  position: relative;
}
.selectFieldHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #13171C;
}
.selectFieldLabel {
  color: #13171C;
}
.selectFieldError {
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  transition: max-height ease .3s;
}
.selectFieldErrorShow {
  max-height: 200px;
}
.selectFieldErrorText {
  padding-top: 4px;
  padding-left: 15px;
  font-size: 12px;
  line-height: 14px;
  color: #FF7DA0;
}
