.customInformationLink {
  padding: 8px 48px 8px 9px;
  margin-bottom: 16px;
  border-radius: 8px;
  background: #E0EEFF;

  &:hover {
    outline: 1px solid #64ACFF;
  }
}
.customInformationLink a {
  display: flex;
  gap: 8px;
  color: #13171C;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
