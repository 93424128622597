.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .desc {
    width: 70%;
  }
}

.appBlock {
  border: 2px solid #64acff;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  align-items: center;
  margin-top: 30px;

  .appInfo {
    h3 {
      margin-top: 0;
    }
    span {
      font-size: 15px;
    }
  }

  .appButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;

    .deleteIcon {
      cursor: pointer;
      font-size: 22px;
      margin-right: 30px;
      color: #64acff;
    }
  }
}
