@import "../../../../css/variables";

.button {
  margin-top: 16px;
}
.button:first-child {
  margin-top: 0;
}
.button:disabled {
  opacity: 0.7;
}

@media screen and (min-width: $screenMinWidthM) {
  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button {
    width: 154px;
    margin-top: 0;
    margin-left: 12px;
  }
  .button:first-child {
    margin-left: 0;
  }
  .buttonChangeStatus {
    width: 220px;
  }
}
