@import "../../css/variables.scss";

.popup {
  position: fixed;
  z-index: 12;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0,0,0, .4);
  overflow-y: auto;
  visibility: hidden;
  opacity: 0;
  transition: opacity ease .3s;
  padding: 10px;
  box-sizing: border-box;
  outline: 0;
}
.popupActive {
  visibility: visible;
  opacity: 1;
  left: 0;
}
.content {
  width: 100%;
  max-width: 700px;
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 30px 16px;
  margin: auto;
  box-sizing: border-box;
}
.close {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 1;
  width: 24px;
  height: 24px;
  border: 4px solid transparent;
  cursor: pointer;
  outline: 0;
  box-sizing: border-box;
}
.closeLine {
  position: absolute;
  top: 10px;
  width: 100%;
  height: 2px;
  background-color: rgba(0,0,0, .7);
  border-radius: 4px;
}
.closeLine:first-child {
  transform: rotate(45deg);
}
.closeLine:last-child {
  transform: rotate(-45deg);
}
.icon {
  text-align: center;
}
.icon img {
  display: inline-block;
  vertical-align: top;
}
.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #13171C;
}
.description {
  max-width: 435px;
  font-size: 20px;
  line-height: 28px;
  color: #868A99;
  margin: 12px auto 24px;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #EEEEEE;
  margin: 16px 0 24px;
}
.contentWithoutPadding {
  padding: 0;
}
.popupMobileFullView {
  padding: 0;
}
.contentMobileFullView {
  width: 100%;
  min-height: 100%;
  padding: 48px 16px;
  border-radius: 0;
}

@media screen and (min-width: $screenMinWidthM) {
  .content {
    padding: 40px;
  }
  .contentWithoutPadding {
    padding: 0;
  }
  .title {
    font-size: 28px;
  }
  .popupMobileFullView {
    padding: 10px;
  }
  .contentMobileFullView {
    max-width: 470px;
    min-height: auto;
    border-radius: 10px;
    padding: 48px 30px;
  }
}
