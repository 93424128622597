@import "../../../../css/variables";

.setUpNotification .inputTitle {
  margin-top: 16px;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  color: #13171C;
}

@media screen and (min-width: $screenMinWidthL) {
  .setUpNotification .inputTitle {
    display: inline;
    font-size: 16px;
    line-height: 24px;
  }
  .input {
    display: inline-flex;
    margin: 0 12px;
  }
  .notificationsText {
    margin-bottom: 16px;
  }
}
