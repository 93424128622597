.mainMenuDropdownOpen .mainMenuDropdownContent {
  max-height: 400px;
}
.mainMenuDropdownOpen .mainMenuDropdownArrow {
  transform: rotate(180deg);
}
.mainMenuDropdownHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A0A0A0;
  padding-right: 8px;
  cursor: pointer;
  transition: color ease-in-out .3s;
}
.mainMenuDropdownLinkActive .mainMenuDropdownHeader,
.mainMenuDropdownHeader:hover {
  color: #dadada;
}
.mainMenuDropdownLinkActive .mainMenuDropdownHeader svg,
.mainMenuDropdownHeader:hover svg {
  fill: #dadada;
}
.mainMenuDropdownHeader svg {
  display: block;
  margin-right: 8px;
  transition: fill ease .3s;
}
.mainMenuDropdownArrow {
  display: block;
  margin-left: auto;
  transition: transform ease .2s;
}
.mainMenuDropdownContent {
  max-height: 0;
  overflow: hidden;
  transition: max-height ease .1s;
}
.mainMenuDropdownList {
  margin: 10px 0 0;
  list-style-type: none;
  padding-left: 26px;
}
.mainMenuDropdownListItem {
  margin-top: 2px;
}
.mainMenuDropdownListItem:first-child {
  margin-top: 0;
}
.mainMenuDropdownListLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A0A0A0;
  border-radius: 8px;
  padding: 5px 15px;
  transition: background, color, ease .3s;
}
.mainMenuDropdownListLink:hover {
  background-color: #262626;
  color: #ffffff;
}
.mainMenuDropdownListLinkActive {
  background-color: #262626;
  color: #ffffff;
  pointer-events: none;
}
.mainMenuDropdownListLinkBeta {
  font-size: 14px;
  color: #A0A0A0;
  margin-left: auto;
}
.mainMenuDropdownListLinkNew {
  &:after {
    content: "";
    display: inline-block;
    width: 33px;
    height: 18px;
    margin-left: 8px;
    background-image: url("../../../public/images/new-icon.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.mainMenuDropdownAttention span {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 4px;
    display: inline-block;
    width: 4px;
    height: 4px;
    margin-left: 2px;
    border-radius: 50%;
    background-color: #64ACFF;
  }
}
