@import "../../css/variables";

.statisticsGraph {
  display: block;
}
.statisticsGraphHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.statisticsGraphTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  font-size: 20px;
  line-height: 25px;
  color: #13171C;
}
.statisticsGraphTabs button {
  width: 85px;
}
.statisticsGraphPeriodTabsBox {
  position: relative;
  z-index: 2;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  padding: 15px 20px 0;
  margin-top: 15px;
}
.statisticsGraphPeriodTabs {
  width: 100%;
}
.statisticsGraphPeriodTabs button {
  width: 20%;
}
.statisticsGraphTabsDisabled,
.statisticsGraphPeriodTabsDisabled {
  opacity: 0.3;
  pointer-events: none;
}
.statisticsGraphTabsDisabled button,
.statisticsGraphPeriodTabsDisabled button {
  cursor: default;
}
.statisticsGraphPeriodCalendar {
  position: absolute;
  z-index: 1;
  top: 14px;
  right: 16px;
}
.statisticsGraphBody {
  position: relative;
  z-index: 1;
  min-height: 230px;
  background-color: #ffffff;
  border-radius: 0 0 8px 8px;
  padding: 15px 20px;
  box-sizing: border-box;
}
.statisticsGraphDisabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
}
.statisticsGraphInfo {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statisticsGraphInfoCard a {
  display: block;
  margin-top: 8px;
}
.statisticsGraphInfoCardButtons a {
  display: flex;
  margin-top: 10px;
}
.statisticsGraphInfoCardButtons a:first-child {
  color: #ffffff;
}
.statisticsGraphInfoCardError {
  color: #FF7DA0;
}

@media screen and (min-width: $screenMinWidthM) {
  .statisticsGraph {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 25px;
  }
  .statisticsGraphHeader {
    justify-content: flex-start;
  }
  .statisticsGraphTabs {
    margin-left: auto;
    margin-right: 8px;
  }
  .statisticsGraphTabs button {
    width: auto;
  }
  .statisticsGraphPeriodTabsBox {
    width: auto;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin-top: 0;
  }
  .statisticsGraphPeriodCalendar {
    top: -2px;
    right: -4px;
  }
  .statisticsGraphBody {
    min-height: 200px;
    background-color: transparent;
    border-radius: 0;
    padding: 25px 0 0;
  }
  .statisticsGraphInfoCardButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 16px;
  }
  .statisticsGraphInfoCardButtons a {
    width: 50%;
    margin: 0;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .statisticsGraphBody {
    flex-grow: 1;
    min-height: 230px;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .statisticsGraphItem {
    height: calc(100% - 5px);
    min-height: 230px;
  }
}
