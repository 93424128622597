@import "../../css/variables";

.table {
  width: 100%;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media all and (max-width: 760px) {
    display: none;
  }

  .thead {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    box-sizing: border-box;

    .tr {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
    }

    .th {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 10px 0;
      box-sizing: border-box;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;

      span {
        color: #252629;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
      }

      &:first-child {
        align-items: flex-start;
      }
    }
  }

  .tbody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    box-sizing: border-box;

    .tdRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border: 1px solid #64acff;
      border-radius: 8px;
      margin-bottom: 10px;
      padding: 10px 15px;
      box-sizing: border-box;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;

      @media all and (max-width: 720px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .td {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      &:first-child {
        align-items: flex-start;

        span {
          word-break: break-all;
        }
      }

      .finished {
        color: #0ed389;
      }

      .expired {
        color: #64acff;
      }

      .refunded {
        color: #2df4e8;
      }

      .partially_paid {
        color: #9b51e0;
      }

      .waiting {
        color: #ff7da0;
      }

      .disabled {
        color: #828282;
      }

      .failed {
        color: #dc1d2e;
      }

      .sending {
        color: #38a99b;
      }

      .confirming {
        color: #bf39b2;
      }

      .confirmed {
        color: #fe5b00;
      }
    }
  }
}

.tableRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #64acff;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 15px;
  box-sizing: border-box;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  .tableCell {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 16.6%;
    position: relative;
    text-align: left;

    .value {
      width: 50%;
    }

    @media all and (max-width: 720px) {
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      width: 100%;
      margin-bottom: 10px;

      .value {
        display: flex;
        flex-direction: column;
      }

      span {
        display: flex;
      }
    }
  }

  @media all and (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.action {
  @media all and (max-width: 720px) {
    justify-content: flex-end !important;
    span {
      justify-content: center;
    }
  }
}

.arrow {
  cursor: pointer;
  display: block !important;
  color: #64acff;
  width: 10px !important;
  height: 10px !important;
  transform: rotate(-225deg);
  margin-left: 20px;
  position: absolute;
  right: 0;
  top: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: #64acff;
    transition: 0.2s ease;
    display: block;
    transform-origin: 100% 0;
  }

  &:after {
    content: "";
    float: left;
    position: relative;
    top: -100%;
    width: 100%;
    height: 100%;
    border-width: 0 2px 0 0;
    border-style: solid;
    border-color: #64acff;
    transform-origin: 100% 0;
    transition: 0.2s ease;
  }
  @media all and (max-width: 720px) {
    display: inline-block;
  }
}

.emptyBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0 100px;
}
.emptyBlockButtons {
  width: 100%;
  max-width: 270px;
  margin-top: 16px;
}
.emptyBlockButton {
  margin-top: 16px;
}
.emptyBlockButton:first-child {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0 !important;
}

@media screen and (min-width: $screenMinWidthM) {
  .emptyBlockButtons {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  .emptyBlockButton {
    width: 180px;
    margin-top: 0;
    margin-left: 16px;
  }
  .emptyBlockButton:first-child {
    margin-left: 0;
  }
}
