@import "../../../../css/variables";

.dashboardSettings {
  padding: 16px;
  border-radius: 8px;
  background-color: #FFFFFF;
}
.dashboardSettingsList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.dashboardSettingsListItem:after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  margin-left: auto;
  border: 2px solid #64ACFF;
  border-left: 0;
  border-top: 0;
  transform: rotate(-45deg);
}
.dashboardSettingsListItem,
.dashboardSettingsListItem a {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #13171C;
}
.dashboardSettingsListItem img {
  margin-right: 8px;
}

@media screen and (min-width: $screenMinWidthL) {
  .dashboardSettings {
    min-width: 280px;
  }
  .dashboardSettingsList {
    height: 100%;
    justify-content: space-between;
  }
}
