.minAmountText {
  min-height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7E7E7E;
  padding-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.minAmountText span {
  color: #64ACFF;
}
