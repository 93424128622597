@import "src/css/variables";

.filters {
  display: block;
  width: 100%;
}
.filterFieldDate {
  max-width: 100%;
}
.filterFieldDate [class*='dateFieldBox'] {
  height: 40px;
}
.filterFieldDate [class*='dateFieldBox'] span {
  line-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.filterInput {
  margin-top: 10px;
}
.filterInput:first-child {
  margin-top: 0;
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
}
.button:first-child {
  order: 2;
}
.buttonExport {
  order: 1;
  margin-bottom: 20px;
}
.button:nth-child(3) {
  order: 3;
}
.button:nth-child(4) {
  order: 4;
}
.button {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  height: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #64ACFF;
  background: none;
  border-radius: 0;
  padding: 0;
  transition: color ease .3s;
}
.button:hover {
  color: #3c82da;
  background: none;
}
.buttonClose {
  color: #A4A4A4;
  margin-left: auto;
  margin-right: 40px;
}

@media  screen and (min-width: $screenMinWidthM) {
  .fields {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -14px;
  }
  .filterInput {
    width: calc(50% - 14px);
    margin-left: 14px;
  }
  .filterInput:first-child {
    margin-top: 10px;
  }
  .button:first-child {
    order: 1;
  }
  .buttonExport {
    width: 140px;
    order: 2;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

@media  screen and (min-width: $screenMinWidthXXL) {
  .filterInput {
    width: calc(25% - 14px);
  }
}
