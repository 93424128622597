@import "../../../../css/variables";

.industrySelect > [class*="simpleSelect"] {
  height: 48px;
  border-color: #EAEFF4;
}
.industrySelect [class*="simpleSelectActive"] {
  border-color: #64ACFF;
}
.industrySelect  [class*="simpleSelectPlaceholder"] {
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  color: #64ACFF;
}
.industrySelect  [class*="simpleSelectContent"] {
  border: 1px solid #EAEFF4;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: none;
  border-bottom: none;
  transition: all ease .3s;
}
.industrySelect [class*="simpleSelectActive"] [class*="simpleSelectContent"] {
  border-color: #64ACFF;
  border-bottom: 1px solid #64ACFF;
}
.industrySelect [class*="simpleSelectScrollContainer"]:before {
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  z-index: 1;
  width: calc(100% - 33px);
  height: 4px;
  background-color: #ffffff;
  border-bottom: 1px solid #CFCFCF;
}
.industrySelect [class*="simpleSelectList"] {
  padding: 0;
  margin: 16px 15px 0;
  border: none;
  max-height: 236px;
  overflow-y: scroll;

  &:before {
    display: none;
  }
}
.industrySelect  [class*="simpleSelectValue"],
.industrySelect  [class*="simpleSelectList"] li {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 24px;
}
.industrySelect  [class*="simpleSelectList"] li {
  padding: 6px 0;
  margin: 0;
}
.industrySelect  [class*="simpleSelectList"] li:last-child {
  margin-bottom: 10px;
}
.industrySelectFieldTitle {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #13171C;
}

@media screen and (min-width: $screenMinWidthL) {
  .industrySelect > [class*="simpleSelect"] {
    width: 273px;
  }
}
