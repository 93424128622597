@import "../../../../css/variables";

.popupManualPayout {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}
.description {
  font-size: 20px;
  line-height: 28px;
  color: $colorTextBlack;
}
.popupForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  margin-top: 24px;
}

@media screen and (min-width: $screenMinWidthM) {
  .popupManualPayout {
    display: block;
  }
  .popupForm {
    display: block;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .popupManualPayout {
    max-width: 975px;
    display: block;
    padding: 64px;
  }
}
