div.datepicker {
  max-width: 310px;
  background-color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 4px 10px -1px rgba(#000000, .3);
  padding: 15px;
  margin: 0;
}
div.datepicker :global(.rdp-day) {
  border-radius: 0;
}
div.datepicker :global(.rdp-button:hover:not([disabled]):not(.rdp-day_selected)) {
  background-color: #ECF5FF;
}
div.datepicker :global(.rdp-cell),
div.datepicker :global(.rdp-day) {
  width: 34px;
  height: 34px;
}
div.datepicker :global(.rdp-caption_label),
div.datepicker :global(.rdp-head_cell) {
  font-weight: 400;
}
button.today {
  color: #64ACFF;
  font-weight: 700;
}
button.selected {
  background-color: #ECF5FF;
  color: #13171c;
}
button.disabled {
  color: #A4A4A4;
}
button.rangeStart {
  background-color: #64ACFF;
  color: #ffffff;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
button.rangeEnd {
  background-color: #64ACFF;
  color: #ffffff;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
button.selected:hover {
  background-color: #ECF5FF !important;
}
button.selected.rangeStart:hover,
button.selected.rangeEnd:hover {
  background-color: #64ACFF !important;
}
button.selected.rangeStart:focus-visible,
button.selected.rangeEnd:focus-visible {
  border: 0;
  background-color: #64ACFF;
  color: #ffffff
}
