@import "../../../../css/variables.scss";

.customerHistoryTableHeader {
  margin-top: 20px;
}
div.customerHistoryTableTabs {
  height: auto;
}
.customerHistoryTableTabs > div {
  max-width: 100%;
  margin-left: 0;
}
.customerHistoryTableTabs > div:nth-child(2) {
  margin-top: 5px;
}
.customerHistoryTable th,
.customerHistoryTable td {
  width: 35%;
}
.customerHistoryTable th:nth-child(1),
.customerHistoryTable td:nth-child(1) {
  width: 30%;
}

@media screen and (min-width: 375px) {
  .customerHistoryTableHeader {
    padding-top: 0;
    padding-bottom: 0;
  }
  div.customerHistoryTableTabs {
    display: flex;
    align-items: center;
    height: 40px;
  }
  .customerHistoryTableTabs > div {
    max-width: 100%;
  }
  .customerHistoryTableTabs > div:nth-child(2) {
    margin-left: 15px;
    margin-top: 0;
  }
}

@media screen and (min-width: 530px) {
  .customerHistoryTable [class*="historyTableEmptyTr"],
  .customerHistoryTable [class*="historyTableLoaderTr"] {
    text-align: center;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  div.transactionsTableTabs {
    height: 50px;
  }
  .customerHistoryTable th:nth-child(1),
  .customerHistoryTable td:nth-child(1) {
    width: 10%;
  }
  .customerHistoryTable th:nth-child(2),
  .customerHistoryTable td:nth-child(2) {
    width: 10%;
  }
  .customerHistoryTable th:nth-child(3),
  .customerHistoryTable td:nth-child(3) {
    width: 18%;
  }
  .customerHistoryTable th:nth-child(4),
  .customerHistoryTable td:nth-child(4),
  .customerHistoryTable th:nth-child(5),
  .customerHistoryTable td:nth-child(5) {
    width: 12%;
  }
}

@media screen and (min-width: $screenMaxWidthXL) {
  .customerHistoryTable th:nth-child(1),
  .customerHistoryTable td:nth-child(1),
  .customerHistoryTable th:nth-child(2),
  .customerHistoryTable td:nth-child(2),
  .customerHistoryTable th:nth-child(3),
  .customerHistoryTable td:nth-child(3),
  .customerHistoryTable th:nth-child(4),
  .customerHistoryTable td:nth-child(4),
  .customerHistoryTable th:nth-child(5),
  .customerHistoryTable td:nth-child(5) {
    width: 20%;
  }
}
