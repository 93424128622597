@import "../../../css/variables.scss";

.infoAboutUse {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  color: #13171C;
  background-color: #E0EEFF;
  border-radius: 8px;
  padding: 16px 25px 16px 16px;
  box-sizing: border-box;
}
.infoAboutUse a {
  font-size: 14px;
  line-height: 22px;
  color: #13171C;
  border-bottom: 1px solid #13171C;
  transition: border-color ease .3s;
}
.infoAboutUse a:hover {
  border-color: transparent;
}
.infoAboutUseClose {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
.infoAboutUseCloseLine {
  width: calc(100% - 4px);
  height: 2px;
  background-color: #13171C;
  position: absolute;
  top: 9px;
  left: 2px;
}
.infoAboutUseCloseLine:first-child {
  transform: rotate(45deg);
}
.infoAboutUseCloseLine:last-child {
  transform: rotate(-45deg);
}
.infoAboutUseIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 8px;
}
.infoAboutUseContent {
  flex-grow: 1;
}
.infoAboutUseContent strong {
  font-weight: 700;
}
.infoAboutUseContent > div {
  margin-top: 16px;
}
.infoAboutUseContent ul,
.infoAboutUseContent ol {
  margin: 0;
  padding-left: 20px;
}
.infoAboutUseContent ul {
  list-style-type: none;
}
.infoAboutUseContent ul li {
  position: relative;
}
.infoAboutUseContent ul li:before {
  content: "";
  position: absolute;
  top: 11px;
  left: -13px;
  width: 4px;
  height: 4px;
  background-color: #13171C;
  border-radius: 50%;
}

@media screen and (min-width: $screenMinWidthM) {
  .infoAboutUse {
    font-size: 16px;
    line-height: 24px;
    padding-right: 50px;
  }
  .infoAboutUse a {
    font-size: 16px;
    line-height: 24px;
  }
  .infoAboutUseClose {
    width: 24px;
    height: 24px;
    top: 16px;
    right: 16px;
  }
  .infoAboutUseCloseLine {
    top: 10px;
  }
}
