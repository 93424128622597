@import "../../../../css/variables.scss";

.pagesWrapper * {
  box-sizing: border-box;
}
h1.title {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}
.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 16px;
}
.navigation a {
  width: 50%;
}

@media screen and (min-width: $screenMinWidthL) {
  .content {
    gap: 16px;
  }
  .navigation a {
    width: auto;
  }
}
