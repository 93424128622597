@import "../../css/variables.scss";

.inputBlock {
  height: 35px;
  width: 260px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;

  .icon {
    position: absolute;
    left: 10px;
    color: #7d7f82;
  }

  input {
    display: inline-block;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 10px;
    align-self: flex-end;

    font-size: 15px;
    background-color: $bgPrimaryColor;
    border: none;

    &.withIcon {
      padding-left: 30px;
    }

    &.withoutIcon {
      padding-left: 10px;
    }
  }
}
