@import "src/css/variables";

.mainNavigation {
  display: block;
  width: 100%;
  box-sizing: border-box;
}
.mainNavigation * {
  box-sizing: border-box;
}
.mainNavigationHeader {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  padding: 20px;
}
.mainNavigationLogo {
  display: block;
  font-family: $fontSecondary;
  font-size: 23px;
  line-height: 27px;
  letter-spacing: .6px;
  color: $colorWhite;
}
.mainNavigationLogo span {
  font-family: $fontSecondary;
  color: $colorBlue;
}
.mainNavigationBody {
  position: fixed;
  top: 70px;
  left: -120%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 70px);
  background: linear-gradient(-20deg, #3D3D3D 16.98%, #000000 85.22%);
  transition: left ease .3s;
  overflow: auto;
  padding: 20px;
}
.mainNavigationBodyActive {
  left: 0;
}
.mainNavigationItems {
  margin-bottom: 38px;
}
.mainNavigationListSecond {
  margin-top: auto;
}
.mainNavigationBodyFooter {
  margin-top: 20px;
}
.mainNavigationSupport {
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #64ACFF;
}
.mainNavigationCopyRight {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-top: 8px;
}
.mainNavigationPartnerEmail {
  flex-shrink: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-top: 5px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: $screenMinWidthM) {
  .mainNavigationBody {
    background: linear-gradient(-20deg, #3D3D3D 0%, #000000 71%);
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .mainNavigation {
    height: 100%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: linear-gradient(-20deg, #3D3D3D 16.98%, #000000 85.22%);
    padding: 25px;
    overflow-y: auto;
  }
  .mainNavigationHeader {
    height: auto;
    background: none;
    padding: 0;
  }
  .mainNavigationBurger {
    display: none;
  }
  .mainNavigationBody {
    position: static;
    height: auto;
    flex-grow: 1;
    background: none;
    margin-top: 30px;
    overflow: visible;
    padding: 0;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .mainNavigation {
    max-width: 290px;
    padding: 40px;
  }
}
