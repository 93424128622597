@import "../../../css/variables";

.footer {
  margin-top: 284px;
}

.resendText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #A4A4A4;
  text-align: center;

  .resend {
    color: $colorBlue;
    cursor: pointer;
  }

  .sent {
    color: $green74;
    cursor: pointer;
  }

}

.dividerContent:not(:last-of-type) {
  margin: 15px 0px;
} 

.dividerContent:last-of-type {
  margin: 15px 0px 30px;
} 

.serverError {
  position: absolute;
  left: 467px;
  top: 45px;
  padding: 10px 20px;
  background: #FFECF1;
  border-radius: 8px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $colorPink;
    white-space: nowrap;
  }
}
