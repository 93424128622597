@import "../../../../css/variables.scss";

.userTransactionsTableBox {
  overflow-x: auto;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 24px;
}
.userTransactionsTable {
  width: 100%;
  min-width: 550px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  overflow: visible;
  border-radius: 8px;
}
.userTransactionsTable table[class*="historyTable"] {
  min-width: auto;
}
.userTransactionsTable tbody:before {
  content: '';
  display: table-row;
  height: 15px;
}
.userTransactionsTable th[class*="historyTableTh"]:nth-child(1),
.userTransactionsTable td[class*="rowTd"]:nth-child(1) {
  width: 35%;
}
.userTransactionsTable th[class*="historyTableTh"]:nth-child(2),
.userTransactionsTable td[class*="rowTd"]:nth-child(2) {
  width: 45%;
}
.userTransactionsTable th[class*="historyTableTh"]:nth-child(3),
.userTransactionsTable td[class*="rowTd"]:nth-child(3) {
  width: 18%;
}
.paginator {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

@media screen and (min-width: $screenMinWidthXL) {
  .userTransactionsTable th[class*="historyTableTh"]:nth-child(1),
  .userTransactionsTable td[class*="rowTd"]:nth-child(1) {
    width: 20%;
  }
  .userTransactionsTable th[class*="historyTableTh"]:nth-child(2),
  .userTransactionsTable td[class*="rowTd"]:nth-child(2) {
    width: 16%;
  }
  .userTransactionsTable th[class*="historyTableTh"]:nth-child(3),
  .userTransactionsTable td[class*="rowTd"]:nth-child(3) {
    width: 16%;
  }
  .userTransactionsTable th[class*="historyTableTh"]:nth-child(4),
  .userTransactionsTable td[class*="rowTd"]:nth-child(4) {
    width: 10%;
  }
  .userTransactionsTable th[class*="historyTableTh"]:nth-child(5),
  .userTransactionsTable td[class*="rowTd"]:nth-child(5) {
    width: 15%;
  }
  .userTransactionsTable th[class*="historyTableTh"]:nth-child(6),
  .userTransactionsTable td[class*="rowTd"]:nth-child(6) {
    width: 15%;
  }
}
