@import '../../css/variables.scss';

.modalBody {
	background-color: $modalBGColorOld;
	display: flex;
	border: 2px solid $colorBlue;
	box-shadow: 7px 7px 0 0 $shadowColor;
	padding: 15px 20px 15px 20px;
}

.modalOverlay {
	background-color: rgba($color: $modalOverlayColorOld, $alpha: 0.4);
	position: fixed;
	display: flex;
	padding: 5px;
	align-items: center;
	justify-content: center;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
}
