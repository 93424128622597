@import "../../css/variables.scss";

.subscriptionUserTitle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #13171C;
}
.subscriptionUserBack {
  margin-right: 8px;
}
.loader {
  text-align: center;
  margin-top: 16px;
}
.subscriptionUserInfo {
  margin-top: 16px;
}
.subscriptionUserInfo > [class*="planInfo"]{
  min-width: 500px;
}
.subscriptionUserInfo [class*="planInfoHeaderItem"]:nth-child(1),
.subscriptionUserInfo [class*="planInfoBodyItem"]:nth-child(1) {
  width: calc(40%);
}
.subscriptionUserInfo [class*="planInfoHeaderItem"]:nth-child(2),
.subscriptionUserInfo [class*="planInfoBodyItem"]:nth-child(2) {
  width: calc(40%);
}
.subscriptionUserInfo [class*="planInfoHeaderItem"]:nth-child(3),
.subscriptionUserInfo [class*="planInfoBodyItem"]:nth-child(3) {
  width: calc(15%);
  max-width: 80px;
}
.subscriptionUserInfoDeactivate {
  font-weight: 600;
  color: #FF7DA0;
  cursor: pointer;
}
.subscriptionUserFilter {
  margin-top: 40px;
}
.subscriptionUserFullFilter {
  margin-top: 24px;
}

@media screen and (min-width: $screenMinWidthM) {
  .subscriptionUserInfo [class*="planInfoHeaderItem"]:nth-child(1),
  .subscriptionUserInfo [class*="planInfoBodyItem"]:nth-child(1) {
    width: 30%;
  }
  .subscriptionUserInfo [class*="planInfoHeaderItem"]:nth-child(2),
  .subscriptionUserInfo [class*="planInfoBodyItem"]:nth-child(2) {
    width: 10%;
  }
  .subscriptionUserInfo [class*="planInfoHeaderItem"]:nth-child(3),
  .subscriptionUserInfo [class*="planInfoBodyItem"]:nth-child(3) {
    width: 22%;
    max-width: 100%;
  }
  .subscriptionUserInfo [class*="planInfoHeaderItem"]:nth-child(4),
  .subscriptionUserInfo [class*="planInfoBodyItem"]:nth-child(4) {
    width: 22%;
  }
  .subscriptionUserInfo div[class*="planInfoHeaderItem"]:last-child,
  .subscriptionUserInfo div[class*="planInfoBodyItem"]:last-child {
    max-width: 80px;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .subscriptionUserInfo {
    margin-top: 24px;
  }
  .loader {
    margin-top: 24px;
  }
}
