.externalWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

.wrapper {
  position: fixed;
  width: 300px;
  background-color: #fefefe;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  border: 2px solid #64acff;
  box-sizing: border-box;
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.09);
  text-align: center;

  .btns {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    .btnCancel {
      background-color: #d2d2d2;
    }
  }
}
