@import "settings";
@import "variables.scss";

.panel {
  border: 2px solid $colorBlue;
  box-shadow: 7px 7px 0 0 $shadowColor;
  margin-bottom: 50px;
  padding: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  input {
    width: auto;
  }

  .label {
    color: $textColor;
    font-weight: 600;
    font-size: 15px;

    margin-bottom: 20px;

    a {
      color: #64ACFF;
    }
  }

  .textField {
    margin-right: 70px;
    padding: 10px;
    height: 15px;
    font-size: 15px;
    background-color: $bgPrimaryColor;
    border: none;
  }
}

.notification {
  background-color: $notificationBgColor;
  border: 1px solid $notificationBorderColor;
  padding: 10px 15px 10px 15px;
  margin: 15px 0 15px 0;  
  font-weight: 400;
  font-size: 14px;
  color: $notificationTextColor;
}
