@import "../../css/variables.scss";

.subscriptionPlanTitle {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #13171C;
}
.subscriptionPlanBack {
  margin-right: 8px;
}
.subscriptionPlanLoader {
  text-align: center;
  margin-top: 16px;
}
.subscriptionPlanInfo {
  margin-top: 16px;
}
.subscriptionPlanInfo > [class*="planInfo"]{
  min-width: 500px;
}
.subscriptionPlanInfo [class*="planInfoHeaderItem"]:nth-child(1),
.subscriptionPlanInfo [class*="planInfoBodyItem"]:nth-child(1) {
  width: calc(40% - 6px);
}
.subscriptionPlanInfo [class*="planInfoHeaderItem"]:nth-child(2),
.subscriptionPlanInfo [class*="planInfoBodyItem"]:nth-child(2) {
  width: calc(28% - 6px);
}
.subscriptionPlanInfo [class*="planInfoHeaderItem"]:nth-child(3),
.subscriptionPlanInfo [class*="planInfoBodyItem"]:nth-child(3) {
  width: calc(30% - 6px);
}
.subscriptionPlanFilter {
  margin-top: 40px;
}

@media screen and (min-width: $screenMinWidthM) {
  .subscriptionPlanInfo [class*="planInfoHeaderItem"]:nth-child(1),
  .subscriptionPlanInfo [class*="planInfoBodyItem"]:nth-child(1) {
    width: calc(50% - 6px);
  }
  .subscriptionPlanInfo [class*="planInfoHeaderItem"]:nth-child(2),
  .subscriptionPlanInfo [class*="planInfoBodyItem"]:nth-child(2) {
    width: calc(28% - 6px);
  }
  .subscriptionPlanInfo [class*="planInfoHeaderItem"]:nth-child(3),
  .subscriptionPlanInfo [class*="planInfoBodyItem"]:nth-child(3) {
    width: calc(22% - 6px);
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .subscriptionPlanInfo {
    margin-top: 24px;
  }
  .subscriptionPlanLoader {
    margin-top: 24px;
  }
  .subscriptionPlanInfo div[class*="planInfoHeaderItem"]:nth-child(1),
  .subscriptionPlanInfo div[class*="planInfoBodyItem"]:nth-child(1) {
    max-width: 130px;
  }
  .subscriptionPlanInfo div[class*="planInfoHeaderItem"]:nth-child(1),
  .subscriptionPlanInfo div[class*="planInfoBodyItem"]:nth-child(1),
  .subscriptionPlanInfo [class*="planInfoHeaderItem"]:nth-child(2),
  .subscriptionPlanInfo [class*="planInfoBodyItem"]:nth-child(2),
  .subscriptionPlanInfo [class*="planInfoHeaderItem"]:nth-child(3),
  .subscriptionPlanInfo [class*="planInfoBodyItem"]:nth-child(3),
  .subscriptionPlanInfo [class*="planInfoHeaderItem"]:nth-child(4),
  .subscriptionPlanInfo [class*="planInfoBodyItem"]:nth-child(4),
  .subscriptionPlanInfo [class*="planInfoHeaderItem"]:nth-child(5),
  .subscriptionPlanInfo [class*="planInfoBodyItem"]:nth-child(5) {
    width: calc(20% - 6px);
  }
}
