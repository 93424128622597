.table {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__thead {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    &__tr {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    &__th {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33%;

      &:first-child {
        justify-content: flex-start;
      }
    }
  }
}

.tbody {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__tr {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &__td {
    display: flex;
    width: 33%;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;

    &:first-child {
      justify-content: flex-start;
      text-transform: capitalize;
    }
  }
}
