.btn {
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px 23px;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #27282d;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: transparent;
  min-width: 100px;
  max-width: fit-content;
  margin: 0 3px;

  &White {
    color: #68aaff;
    border: 1px solid #68aaff;

    &:active {
      color: #fff;
      background: #68aaff;
    }

    &__active {
      color: #fff;
      background: #68aaff;
    }
  }

  &Black {
    border: 1px solid #000;

    &:active {
      color: #fff;
      background: #000;
    }

    &__active {
      color: #fff;
      background: #000;
    }
  }
}

.groupBtns {
  margin-bottom: 10px;
  display: flex;
}

.wrapperCode {
  overflow-x: auto;
  padding: 1em;
  background: #282b2e;
}

.code {
  font-size: 1rem;
  color: #a9b7c6;
}

.tagName {
  color: #e8bf6a;
}

.tagString {
  color: #6a8759;
}

.select {
  width: 100%;
}
