@import '../../css/variables.scss';

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  margin-top: auto;
}
.copyright {
  font-family: $fontSecondary;
  color: $modalOverlayColor;
  font-size: 13px;
  order: 3;
  text-align: center;
  margin-top: 15px;
}
.copyright span {
  font-family: $fontSecondary;
  color: $colorBlue;
}
.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 210px;
  order: 2;
  margin-top: 15px;
}
.link {
  color: #64acff;
  font-size: 13px;
  font-weight: 400;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
    justify-content: space-between;
    padding: 30px 130px 30px 30px;
  }
  .copyright {
    flex-shrink: 0;
    order: unset;
    margin-top: 0;
  }
  .links {
    width: auto;
    align-items: center;
    flex-shrink: 0;
    order: unset;
    margin-top: 0;
  }
  .links a:last-child {
    margin-left: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .footer {
    padding-right: 150px;
  }
}
