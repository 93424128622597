@import "../../../../css/variables";

.requestPayoutCreated {
  min-height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.requestPayoutCreatedContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.requestPayoutCreatedImage {
  max-width: 100%;
  height: auto;
}
.requestPayoutCreatedMainText {
  margin: 16px 0 12px;
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #13171C;
}
.requestPayoutCreatedDescription {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #7E7E7E;
}
.requestPayoutCreatedId {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.requestPayoutCreatedIdTitle {
  color: #7E7E7E;
}
.requestPayoutCreatedIdValue {
  color: #1B1E22;
}
.requestPayoutCreatedNotice {
  padding: 8px;
  margin-bottom: 16px;
  border: 2px solid #F9F9F9;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #7E7E7E;
}
.requestPayoutCreatedNotice span {
  color: #64ACFF;
}
.requestPayoutCreatedNotice a {
  text-decoration: underline;
}
.requestPayoutCreatedButton {
  width: 100%;
  border-radius: 8px;
}
.requestPayoutCreatedTitle {
  width: 100%;
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

@media screen and (min-width: $screenMinWidthM) {
  .requestPayoutCreated {
    min-height: unset;
  }
  .requestPayoutCreatedContent {
    padding: 120px 0 76px;
  }
}
