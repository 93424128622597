@import "../../../../css/variables";

.twoFactor {
  height: calc(620px);
}
.twoFactorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.twoFactorHeaderLogo {
  font-family: $fontSecondary;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 28px;
}
.twoFactorHeaderLogo span {
  color: $colorBlue;
}
.twoFactorDivider {
  width: 100%;
  height: 1px;
  background-color: #EEEEEE;
  margin: 15px 0;
}
.twoFactorTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
}
.twoFactorInput [class*='input']::placeholder {
  font-size: 16px;
}
.twoFactorInput [class*='error'] {
  left: auto;
  top: auto;
  right: 5px;
  bottom: 42px;
}
.twoFactorContent {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  height: calc(100% - 100px);
}
.twoFactorContent a {
  font-size: inherit;
}
.twoFactorDescription {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #13171C;
  margin-bottom: 30px;
}
.twoFactorReCaptcha {
  margin-top: 168px;
  margin-bottom: 5px;
  transform: scale(0.7);
  transform-origin: left;
}
.twoFactorLink {
  text-align: center;
  color: $colorBlue;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.twoFactorBottom {
  margin-top: auto;
}
.twoFactorApiError {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 20px;
  font-size: 12px;
  line-height: 14px;
  color: #FF7DA0;
  margin: 5px 0;
}
.twoFactorButton {
  width: 100%;
}
.twoFactorLoseCodeText {
  font-size: 18px;
  line-height: 23px;
  color: #13171C;
}

@media screen and (min-width: 375px) {
  .twoFactorReCaptcha {
    transform: scale(0.8);
  }
}

@media screen and (min-width: 390px) {
  .twoFactorReCaptcha {
    transform: scale(0.9);
  }
}

@media screen and (min-width: 420px) {
  .twoFactorReCaptcha {
    transform: scale(1);
  }
}
