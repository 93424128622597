.dFlex {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.justifyCenter {
  justify-content: center;
}

.fs20 {
  font-size: 20px;
}
.fs16 {
  font-size: 16px;
}
.fwBold {
  font-weight: bold;
}
.wordBreak {
  word-break: break-all;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.breakAll {
  word-break: break-all;
}
.link {
  color: #64acff;
}
.priceAmount {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

@media all and (max-width: 790px) {
  .breakAll {
    width: 120px;
  }
}
