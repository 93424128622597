@import "../../../../css/variables.scss";

.apiKeysTableRow {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 68px;
  border-radius: 4px;
  margin-top: 4px;
  padding: 4px 8px;
  background-color: #ffffff;
  transition: background-color ease .3s;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #13171C;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: #ECF5FF;
  }
}
.apiKeysTableRowContent {
  display: flex;
  justify-content: space-between;
}
.apiKeysTableRow .apiKeysTableRowOptions {
  position: relative;
  top: 50%;
  transform: translateY(50%);
  display: flex;
  justify-content: right;
  width: 3px;
  padding-top: 2px;
}
.apiKeysTableRowOptionsButton {
  height: 15px;
  padding: 0;
  background-color: transparent;
}
.apiKeysTableRowOptionsButton svg {
  transition: fill ease .3s;
}
.apiKeysTableRowOptionsButton:hover svg {
  fill: #64ACFF;
}
.copy {
  font-size: 16px;
  line-height: 24px;
}
.copy img {
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
}
.copy:hover img {
  transform: scale(1.1);
}
.copy [class*="copyButtonImgSuccess"] {
  animation: copy-button;
  animation-duration: 1s;
}
.secondaryText {
  color: #A4A4A4;
  font-size: 14px;
  line-height: 18px;
}
.createdAtMobile {
  position: relative;
  width: calc(100vw - 94px);
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}
.keyTitle {
  position: relative;
  width: calc(100vw - 110px);
  margin-bottom: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tdBottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.apiKeysTableRowActive {
  background-color: #ECF5FF;
}

@keyframes copy-button {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: $screenMinWidthXXS) {
  .apiKeysTableRowTitle .copy span {
    min-width: 88px;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .apiKeysTableRow {
    position: static;
    flex-direction: row;
    justify-content: unset;
    align-items: center;
    min-height: 36px;
    padding: 4px 16px;
  }
  .apiKeysTableRowContent {
    width: 100%;
  }
  .keyTitle {
    width: 140px;
    margin-bottom: 0;
    cursor: pointer;
  }
  .tdBottom {
    justify-content: center;
  }
  .apiKeysTableRow .apiKeysTableRowOptions {
    position: static;
    transform: unset;
    align-items: center;
  }
  .copy {
    font-size: 14px;
    line-height: 18px;
  }
  .copy img {
    width: 16px;
    height: 16px;
    transition: all 0.3s ease;
  }
  .apiKeysTableRowContent .copy span {
    min-width: 76px;
  }
}
