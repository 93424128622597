.new2fa {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 25px;
  box-sizing: border-box;
}
.new2faTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #13171C;
}
.new2faHr {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  margin-top: 16px;
  margin-bottom: 12px;
}
.new2faDescription {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #13171C;
}
.new2faField {
  margin-top: 24px;
}
.new2faField > [class*="error"] {
  bottom: 100%;
  top: auto;
  left: auto;
  right: 0;
}
.new2faField [class*="errorText"] {
  text-align: right;
  padding: 0 5px 5px;
}
.new2faButtons {
  margin-top: 5px;
}
.captchaBlock {
  transform: scale(0.8);
  transform-origin: left;
  margin-top: 60px;
}
.new2faError {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 20px;
  font-size: 12px;
  line-height: 20px;
  color: #ff7da0;
  text-align: center;
  margin: 4px 0;
}
.new2faButton {
  width: 100%;
}
.new2faButtonCancel {
  background-color: #ffffff;
  color: #64ACFF;
  border: 1px solid #64ACFF;
  margin-top: 10px;
  transition: color, background-color, ease .3s;
}
.new2faButtonCancel:hover {
  color: #ffffff;
}

@media screen and (min-width: 375px) {
  .captchaBlock {
    transform: scale(1);
  }
}
