.currenciesSelect {
  display: block;
  width: 100%;
  height: 68px;
  position: relative;
}
div.currenciesSelectSmallTheme {
  height: 40px;
}
.currenciesSelectBody {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #64ACFF;
  box-shadow: 0 10px 20px rgba(139, 139, 139, 0.06);
  border-radius: 8px;
  overflow: hidden;
  opacity: 0;
  transition: opacity ease .3s;
}
.currenciesSelectBodyAnimated {
  opacity: 1;
}
.currenciesSelectNotFound {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #13171C;
  padding: 15px 0;
  margin-bottom: 15px;
}
.currenciesSelectList {
  max-height: 350px;
  overflow-y: auto;
  margin: 0 -15px;
  padding: 0;
  list-style-type: none;
}
.currenciesSelectListShort {
  max-height: 300px;
}
.currenciesSelectListShortFiat {
  max-height: 196px;
}
.currenciesSelectItemTitle {
  font-size: 14px;
  line-height: 16px;
  color: #5e5a72;
  padding: 8px 15px;
}
.currenciesSelectItem {
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  transition: background-color ease .3s;
}
.currenciesSelectItem:hover {
  background-color: #f5f7fa;
}
.currenciesSelectItemActive {
  background-color: #f5f7fa;
}
.currenciesSelectWithAmountField {
  width: auto;
  height: auto;
}
.currenciesSelectLink span {
  color: #64ACFF;
  font-weight: 700;
}
.currenciesSelectItemDisabled {
  filter: grayscale(100%);
  user-select: none;

  &:hover {
    cursor: initial;
  }
}
