.payoutWalletsOption {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 12px;
  background-color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #13171C;
  text-align: left;
}
.payoutWalletsOption:hover {
  background-color: #FAFAFA;
}
.payoutWalletsOptionDisable {
  color: #A4A4A4;
}
