@import "../../../css/variables";

.innerNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #C9DFF7;
}
.innerNavigationItem {
  position: relative;
  display: block;
  width: 30%;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A4A4A4;
  text-align: center;
  padding-bottom: 8px;
  transition: color ease .3s;
}
.innerNavigationItem:before {
  content: "";
  width: 0;
  height: 2px;
  background-color: #64ACFF;
  position: absolute;
  bottom: -1px;
  left: 0;
  transition: width ease .3s;
}
.innerNavigationItemActive {
  color: #13171C;
}
.innerNavigationItem:hover:before,
.innerNavigationItemActive:before {
  width: 100%;
}

@media screen and (min-width: $screenMinWidthM) {
  .innerNavigation {
    justify-content: flex-start;
  }
  .innerNavigationItem {
    width: auto;
    margin-left: 40px;
  }
  .innerNavigationItem:first-child {
    margin-left: 0;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .innerNavigationItem {
    font-size: 24px;
    line-height: 32px;
  }
}
