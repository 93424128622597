.walletsWhitelistActivation {
  min-height: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 8px;
  margin-top: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  padding: 12px 16px;
  box-sizing: border-box;
}
.walletsWhitelistActivation img {
  display: block;
}
.walletsWhitelistActivationText {
  margin-right: auto;
}
.walletsWhitelistActivationLink {
  margin-left: 32px;
}
