@import "../../../../css/variables";

.dashboardGetStarted {
  padding: 20px;
  border-radius: 8px;
  background-color: #FFFFFF;
}
.dashboardGetStarted h2 {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #13171C;
}
.dashboardGetStarted h2 span {
  font-weight: 900;
  color: #64ACFF;
}
.dashboardGetStartedDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.dashboardGetStartedButton {
  margin-top: 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.dashboardGetStartedPopup [class*="contentMobileFullView"] {
  padding: 48px 16px 40px;
}

@media screen and (min-width: $screenMinWidthM) {
  .dashboardGetStartedPopup [class*="contentMobileFullView"] {
    max-width: 500px;
    padding: 64px 64px 40px;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .dashboardGetStartedButton {
    min-width: 194px;
    font-size: 16px;
    line-height: 24px;
  }
  .dashboardGetStarted {
    width: 100%;
  }
}
