@import "../../css/variables.scss";

.popupContent .title {
  font-size: 24px;
  line-height: 32px;
}
.popupForm {
  min-height: calc(100vh - 175px);
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: $screenMinWidthM) {
  .popup .popupContent {
    max-width: 500px;
    padding: 64px;
  }
  .popupForm {
    min-height: auto;
    display: block;
  }
}
