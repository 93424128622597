@import "settings";
@import "fonts/sourcesanspro";
@import "fonts/syne";
@import "global";
@import "variables";
@import 'react-day-picker/dist/style.css';

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
