.paginator {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}
.infoBlock {
  margin-top: -16px;
  margin-bottom: 32px;
}
