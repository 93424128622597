@import "../../../../css/variables.scss";

.box {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 98px);
}
.title {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #13171C;
}
.hr {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  margin: 16px 0 24px;
}
.text {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #13171C;
}
.field {
  margin-top: 24px;
}
.field [class*="fieldTitle"] {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding-left: 0;
  padding-right: 0;
}
.field input[class*="input"] {
  height: 48px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.field > [class*="error"] {
  max-width: calc(100% - 70px);
  top: auto;
  left: auto;
  right: 0;
  text-align: right;
  bottom: 58px;
}
.field [class*="errorText"] {
  padding: 0;
}
.error {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 35px;
  font-size: 14px;
  line-height: 16px;
  color: #FF7DA0;
  text-align: center;
  margin-top: auto;
}
.button {
  margin-top: 5px;
  flex-shrink: 0;
}

@media screen and (min-width: $screenMinWidthXXS) {
  .field input[class*="input"] {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .box {
    display: block;
    min-height: auto;
  }
  .error {
    margin-top: 30px;
  }
  .button {
    width: 100%;
  }
}
