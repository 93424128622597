@import "../../../css/variables";

.customFilter {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  border-radius: 0;
  background-color: #ffffff;
  padding: 25px 16px;
  box-sizing: border-box;
}
.customFilterClose {
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.customFilterCloseLine {
  width: calc(100% - 8px);
  height: 2px;
  background-color: #13171C;
  position: absolute;
  top: 11px;
  left: 0;
  right: 0;
  margin: auto;
}
.customFilterCloseLine:first-child {
  transform: rotate(45deg);
}
.customFilterCloseLine:last-child {
  transform: rotate(-45deg);
}
.customFilterTitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #13171C;
}
.customFilterLine {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  margin: 16px 0 24px;
}
.customFilterFields * {
  box-sizing: border-box;
}
.customFilterFields {
  height: calc(100vh - 265px);
  overflow-y: auto;
}
.customFilterButtons {
  margin-top: 24px;
}
.customFilterButton {
  height: 48px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin-top: 16px;
}
.customFilterButton:first-child {
  margin-top: 0;
}
.customFilterButtonReset,
.customFilterButtonReset:hover {
  color: #FF7DA0;
  background-color: transparent;
}

@media screen and (min-width: $screenMinWidthL) {
  .customFilter {
    position: static;
    height: auto;
    background-color: transparent;
    padding: 0;
    margin-top: 16px;
  }
  .customFilterClose,
  .customFilterTitle,
  .customFilterLine {
    display: none;
  }
  .customFilterFields {
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -16px;
    margin-left: -16px;
    overflow-y: visible;
  }
  .customFilterFields > div {
    margin-top: 16px;
    margin-left: 16px;
  }
  .customFilterButtons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .customFilterButton {
    width: auto;
    height: auto;
    font-size: 16px;
    line-height: 20px;
    background-color: transparent;
    padding: 0;
    margin: 0 0 0 24px;
    transition: color ease .3s;
  }
  .customFilterButton:hover {
    background-color: transparent;
  }
  .customFilterButtonSubmit {
    color: #64ACFF;
    margin-left: 0;
  }
  .customFilterButtonSubmit:hover {
    color: #91c4ff;
  }
  .customFilterButtonReset:hover {
    color: #ffa5be;
  }
}
