.currenciesSelectField {
  position: relative;
}
.currenciesSelectFieldHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #13171C;
  margin-bottom: 8px;
}
.currenciesSelectFieldLabel {
  color: #13171C;
}
.currenciesSelectFieldLabelTooltip {
  display: inline-block;
  vertical-align: bottom;
  margin-left: 5px;
  margin-bottom: 3px;
}
.currenciesSelectFieldLabelTooltip img {
  display: block;
}
.currenciesSelectError {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 0;
  max-height: 0;
  background-color: #ffffff;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  transition: max-height ease .3s;
}
.currenciesSelectErrorShow {
  max-height: 200px;
}
.currenciesSelectErrorText {
  font-size: 12px;
  line-height: 14px;
  color: #FF7DA0;
  padding-top: 4px;
  padding-left: 15px;
}
.currenciesSelectFieldSmallTheme .currenciesSelectFieldHeader {
  font-size: 15px;
  line-height: 18px;
}
