.item {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  background-color: #ffffff;
  cursor: pointer;
  outline: 0;
}
.item * {
  box-sizing: border-box;
}
.itemError {
  border-color: #FF7DA0;
}
.itemPlaceholderText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8A8A8E;
}
.itemContent {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  padding-right: 10px;
}
.itemsSelect {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  display: block;
  margin: auto;
}
.itemDisabled {
  cursor: default;
}
