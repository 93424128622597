@import "../../../../css/variables.scss";

.subscriptionTableBox {
  overflow-x: auto;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
.subscriptionTable {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  overflow: visible;
  min-width: 600px;
  border-radius: 8px;
}
.subscriptionTable table[class*="historyTable"] {
  min-width: auto;
}
.subscriptionTable tbody:before {
  content: '';
  display: table-row;
  height: 15px;
}
.subscriptionTable th[class*="historyTableTh"],
.subscriptionTable td[class*="rowTd"] {
  width: 37.5%;
}
.subscriptionTable th[class*="historyTableTh"]:nth-child(3),
.subscriptionTable td[class*="rowTd"]:nth-child(3) {
  width: 25%;
}
.subscriptionTable [class*="rowTdOverflow"] {
  max-width: 190px;
}
.paginator {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

@media screen and (min-width: 480px) {
  .subscriptionTable [class*="historyTableEmptyTr"],
  .subscriptionTable [class*="historyTableLoaderTr"] {
    text-align: center;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .subscriptionTable [class*="rowTdOverflow"] {
    max-width: 240px;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .subscriptionTable [class*="rowTdOverflow"] {
    max-width: 50px;
  }
  .subscriptionTable th[class*="historyTableTh"]:nth-child(1),
  .subscriptionTable td[class*="rowTd"]:nth-child(1) {
    width: 16%;
  }
  .subscriptionTable th[class*="historyTableTh"]:nth-child(2),
  .subscriptionTable td[class*="rowTd"]:nth-child(2) {
    width: 35%;
  }
  .subscriptionTable th[class*="historyTableTh"]:nth-child(3),
  .subscriptionTable td[class*="rowTd"]:nth-child(3) {
    width: 15%;
  }
  .subscriptionTable th[class*="historyTableTh"]:nth-child(4),
  .subscriptionTable td[class*="rowTd"]:nth-child(4) {
    width: 10%;
  }
  .subscriptionTable th[class*="historyTableTh"]:nth-child(5),
  .subscriptionTable td[class*="rowTd"]:nth-child(5) {
    width: 18%;
  }
}
