@import "../../../css/variables.scss";

.customSearchFilter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.customSearchFilterInput {
  width: calc(100% - 62px);
  height: 44px;
}
.customSearchFilterButton {
  flex-shrink: 0;
  margin-left: 16px;
}
.customSearchFilterContent {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 25px 16px;
  box-sizing: border-box;
}
.customSearchFilterClose {
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.customSearchFilterCloseLine {
  width: calc(100% - 8px);
  height: 2px;
  background-color: #13171C;
  position: absolute;
  top: 11px;
  left: 0;
  right: 0;
  margin: auto;
}
.customSearchFilterCloseLine:first-child {
  transform: rotate(45deg);
}
.customSearchFilterCloseLine:last-child {
  transform: rotate(-45deg);
}

@media screen and (min-width: $screenMinWidthM) {
  .customSearchFilterInput {
    max-width: 320px;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .customSearchFilterContent {
    position: static;
    height: auto;
    background-color: transparent;
    padding: 0;
  }
  .customSearchFilterClose {
    display: none;
  }
}
