@import "../../../../css/variables";

.valueFromStatistic {
  position: relative;
  z-index: 1;
  border-radius: 8px;
  background: #ffffff;
  padding: 15px 20px;
}
.valueFromStatisticTitle {
  color: #A4A4A4;
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
}
.valueFromStatisticTickers {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  margin-left: 15px;
}
.valueFromStatisticTicker {
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  color: #13171C;
  text-transform: uppercase;
  margin-left: 4px;
}
.valueFromStatisticTicker:first-child {
  margin-left: 0;
}
.valueFromStatisticAmount {
  min-height: 30px;
  font-size: 24px;
  line-height: 30px;
  color: #13171C;
  margin-top: 5px;
}
.valueFromStatisticAmountTicker {
  font-size: 18px;
  line-height: 23px;
  margin-left: 4px;
}
.valueFromStatisticAmount img {
  display: block;
}
.valueFromStatisticText {
  max-width: 240px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 4px;
}
.valueFromStatisticLink {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  box-sizing: border-box;

  &:hover img {
    transform: scale(1.2);
  }
}
.valueFromStatisticLink img {
  transition: transform ease .3s;
}

@media screen and (min-width: $screenMinWidthXL) {
  .valueFromStatistic,
  .valueFromStatisticLink {
    padding-left: 24px;
    padding-right: 24px;
  }
  .valueFromStatisticTickers {
    margin-left: 12px;
  }
  .valueFromStatisticAmount {
    margin-top: 10px;
  }
}
