.accountList {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 0;
  margin-bottom: 20px;
  list-style: none;
}
.accountListItem {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  background: #F5F7FB;
  color: #13171C;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accountListItemImage {
  margin-right: 8px;
}
