@import "../../../../../css/variables.scss";

.appTwoFactor {
  display: flex;
  .twoFactorSettings {
    flex: 1;
    > div {
      margin-bottom: 16px;
    }
    .QRCode {
      width: 100%;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
      > img {
        width: 100%;
        height: auto;
      }
    }
    .tfaKey {
      font-size: 16px;
      font-weight: 400;
      background-color: $notificationBgColor;
      border: 1px solid $notificationBorderColor;
      padding: 10px 15px 10px 15px;
    }
    .authenticationForm {
      input {
        box-sizing: border-box;
        margin-bottom: 10px;
        width: 100%;
        padding: 10px;
        height: 40px;
        font-size: 15px;
        background-color: $bgPrimaryColor;
        border: none;
      }
    }
  }
  .twoFactorSteps {
    flex: 1;
    padding-left: 16px;
    font-size: 14px;
  }
}

@media screen and (max-width: $screenMaxWidthXS) {
  .appTwoFactor {
    flex-direction: column;
    .twoFactorSteps {
      padding-left: 0;
    }
    .twoFactorSettings {
      order: 2;
    }
  }
}
