@import "../../../../css/variables.scss";

.editWalletFormCurrency {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  gap: 8px;
}
.editWalletFormInput {
  margin-top: 24px;
}
.editWalletFormButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.editWalletFormApiError {
  margin-top: auto;
  min-height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF7DA0;
  text-align: center;
}

@media screen and (min-width: $screenMinWidthM) {
  .form.editWalletForm {
    min-height: 355px;
  }
}
