@import "src/css/variables";

.filters {
  display: block;
  width: 100%;
}
.filterInput {
  margin-top: 10px;
}
.filterInput:first-child {
  margin-top: 0;
}
.filterInputCurrencyLabel {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #13171C;
  margin-bottom: 8px;
}
.filterInputCurrencySelect {
  height: 37px;
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.button {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  height: auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #64ACFF;
  background: none;
  border-radius: 0;
  padding: 0;
  transition: color ease .3s;
}
.button:hover {
  color: #3c82da;
  background: none;
}
.buttonClose {
  color: #A4A4A4;
  margin-left: auto;
  margin-right: 40px;
}

@media  screen and (min-width: $screenMinWidthM) {
  .fields {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: -14px;
  }
  .filterInput {
    width: calc(50% - 14px);
    margin-left: 14px;
  }
  .filterInput:first-child {
    margin-top: 10px;
  }
  .filterFixRate,
  .filterFeePaid {
    width: calc(25% - 14px);
  }
}

@media  screen and (min-width: $screenMinWidthL) {
  .filterInput {
    width: calc(33.3% - 14px);
  }
  .filterInput:nth-child(1) {
    order: 6;
  }
  .filterInput:nth-child(5) {
    order: 7;
  }
}

@media  screen and (min-width: 1250px) {
  .filterFixRate,
  .filterFeePaid {
    width: calc(16.5% - 14px);
  }
}
