@import "../../../../css/variables";

.popup {
  width: 100%;
  max-width: 700px;
  padding: 30px 16px;
}
.popupButtons {
  margin-top: 25px;
}

@media screen and (min-width: $screenMinWidthM) {
  .popup {
    padding: 30px;
  }
  .popupButtons {
    margin-top: 40px;
  }
}
