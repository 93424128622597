@import "../../../../css/variables.scss";

.tfaTypeModal {
  padding: 5px;
  position: relative;
  .closeButton {
    z-index: 2;
    position: absolute;
    width: 20px;
    height: 20px;
    right: -10px;
    top: -5px;
    cursor: pointer;
    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: calc(50% - 1px);
      width: 2px;
      height: 20px;
      background-color: $colorPink;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }
  .loader {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: white, $alpha: 0.7);
  }
  > p {
    color: $textColor;
    font-size: 18px;
    margin-top: 0;
    font-weight: 400;
  }
}
