.getStartedStepMobileCards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;
}
.getStartedStep [class*="onboardingStepsHeader_"]:after {
  display: none;
}
.getStartedStepSlider {
  margin-top: 16px;
}
.getStartedStepSlider > ul {
  padding: 8px 0;
}

