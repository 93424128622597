@import "../../css/variables";

.notificationMessage {
  position: relative;
  width: 100%;
  max-height: 0;
  background-color: #64acff;
  transition: max-height .2s ease;
  overflow: hidden;
}
.notificationMessageActive {
  max-height: 200px;
}
.notificationMessageClose {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  right: 10px;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}
.notificationMessageCloseLine {
  width: calc(100% - 6px);
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  top: 10px;
  left: 3px;
  border-radius: 10px;
}
.notificationMessageCloseLine:first-child {
  transform: rotate(45deg);
}
.notificationMessageCloseLine:last-child {
  transform: rotate(-45deg);
}
.notificationMessageText {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-align: center;
  padding: 15px 40px 15px 20px;
  box-sizing: border-box;
}
.notificationMessageText a {
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  transition: border-color ease .3s;
}
.notificationMessageText a:hover {
  border-color: transparent;
}

@media screen and (min-width: $screenMinWidthM) {
  .notificationMessageText,
  .notificationMessageText a {
    font-size: 16px;
    line-height: 18px;
  }
}
