@import "../../../../css/variables";

.balances {
  width: 100%;
}
.button {
  width: 140px;
  height: 37px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.loader {
  text-align: center;
  margin: 40px 0;
}
.balancesEmptyText {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 92px;
  padding: 0 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #7E7E7E;
}
.sectionBalance > [class*="sectionHeader_"] {
  position: relative;
  padding: 0 16px 16px;
  border-bottom: 1px solid #EEEEEE;
}
.sectionBalanceEmpty > [class*="sectionHeader_"] {
  padding-bottom: 0;
  border-bottom: none;
}
.sectionBalanceEmpty > [class*="sectionContent_"] {
  margin-top: 8px;
}
.sectionBalanceSearch {
  position: absolute;
  bottom: -77px;
  right: 0;
  width: calc(100vw - 62px);
}
.balancesWithSearch {
  margin-top: 77px;
}
.sectionBalancePagination {
  padding: 16px 16px 0;
  border-top: 1px solid #EEEEEE;
}
.sectionBalanceHeaderControl {
  position: relative;
  top: -125px;
  padding-top: 125px;
  margin-bottom: -125px;
}
.balancesEmptyPicture {
  margin: 24px 0;
}
.balancesEmptyImage {
  max-width: 343px;
  width: 100%;
}
.balancesEmptyButton {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

@media screen and (min-width: $screenMinWidthM) {
  .button {
    height: 44px;
    min-width: 140px;
    border-radius: 8px;
  }
  .sectionBalance > [class*="sectionHeader_"] {
    position: static;
    padding: 0;
    border-bottom: none;
  }
  .sectionBalanceHeaderControl {
    display: flex;
    gap: 24px;
  }
  .sectionBalanceSearch {
    position: relative;
    bottom: unset;
    left: unset;
    min-width: 311px;
    width: unset;
    height: 44px;
    margin: 0;
  }
  .balancesWithSearch {
    margin-top: 0;
  }
  .balancesEmptyText {
    display: block;
    padding: 0;
  }
  .sectionBalanceEmpty {
    position: relative;
    padding: 24px;
  }
  .balancesEmptyPicture {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
  .balancesEmptyButton {
    width: 166px;
    margin-top: 24px;
  }
}
