@import '../../../../css/variables.scss';

.addWallet {
  display: block;
  width: 100%;
  background-color: #ffffff;
}
.addWalletText {
  margin: 20px 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #13171C;
}
.addWalletInput {
  margin-top: 8px;
  margin-bottom: 40px;
}
.addWalletSubTitle {
  color: #13171C;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.addWalletInputWithExtraId {
  margin-bottom: 0;
}
.addWalletInputExtraId {
  margin-top: 20px;
  margin-bottom: 40px;
}
.addWalletErrorMessage {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 14px;
  line-height: 16px;
  color: #FF7DA0;
  text-align: center;
}
.addWalletButton {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 5px;
}
.addWalletButtonCancel {
  width: 100%;
  background-color: #ffffff;
  color: #64ACFF;
  border: 1px solid #64ACFF;
  margin-top: 10px;
  transition: color, background-color, ease .3s;
}
.addWalletButtonCancel:hover {
  color: #ffffff;
}

@media screen and (min-width: $screenMinWidthM) {
  .addWalletButton {
    position: static;
  }
}
