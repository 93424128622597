@import "src/css/variables";

.headerElement {
  display: block;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
}
.historyTableWithPagination {
  overflow-x: auto;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
.table {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  overflow: visible;
  border-radius: 8px;
  margin-top: 0;
}
.table > table {
  min-width: auto;
}
.table tbody:before {
  content: '';
  display: table-row;
  height: 15px;
}
.paginator {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
