.slickSliderCustom {
  position: relative;
}
.slickSliderCustom :global(.slick-list) {
  padding-bottom: 6px;
}
.slickSliderCustom :global(.slick-prev),
.slickSliderCustom :global(.slick-next) {
  position: absolute;
  bottom: 50px;
  height: 20px;
  background-color: transparent;
  border-radius: 8px;
  transition: background-color ease .3s;
}
.slickSliderCustom :global(.slick-prev) {
  left: -50px;
}
.slickSliderCustom :global(.slick-next) {
  right: -50px;
}
.slickSliderCustomDots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 16px 0;
}
.slickSliderCustomDots li {
  display: inline-block;
  vertical-align: middle;
}
.slickSliderCustomDots li button {
  display: block;
  width: 8px;
  height: 8px;
  font-size: 0;
  line-height: 0;
  background-color: #D2D2D2;
  border-radius: 50%;
  border: none;
  text-indent: -99999px;
  margin: 2px;
  padding: 0;
}
.slickSliderCustom :global(li.slick-active) button {
  background-color: #64ACFF;
}
