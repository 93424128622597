.fieldSelect {
  position: relative;
  display: block;
  width: 100%;
}
.fieldTitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #13171C;
}
.fieldSelect {
  margin-top: 8px;
}
.error {
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height ease .3s;
}
.errorShow {
  max-height: 200px;
}
.errorText {
  font-size: 12px;
  line-height: 14px;
  color: #FF7DA0;
  padding-top: 4px;
  padding-left: 15px;
}
