@import "../../../../../../css/variables";

.generateApiKeyStep {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 144px);
}
.generateApiKeyStepAddWallet > form {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 247px);
}
.generateApiKeyStepAddWallet [class*="apiError"] {
  margin-top: auto;
}
.generateApiKeyStepAddWallet > [class*="title"],
.generateApiKeyStepAddWallet > [class*="description"],
.generateApiKeyStepAddWallet > [class*="line"] {
  display: none;
}
.generateApiKeyStepAddWallet div[class*="currenciesSelect_"],
.generateApiKeyStepAddWallet [class*="fieldInput"] {
  height: 48px;
}
.generateApiKeyStepAddWallet [class*="field_"] {
  margin-top: 20px;
}
.generateApiKeyStepAddWallet [class*="currenciesSelectError_"],
.generateApiKeyStepAddWallet [class*="errorShow_"] {
  bottom: 58px;
}
.generateApiKeyStepAddWallet button[type="submit"],
.continueButton {
  height: 48px;
  border-radius: 8px;
  font-size: 20px;
  line-height: 28px;
}
.generateApiKeyStepAddWallet [class*="fieldBox_"] span[class*="fieldIcon_"] {
  height: 42px;
}
.copyText {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
}
.copyText span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.copyTitle {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #A4A4A4;
}
.continueButton {
  margin-top: auto;
}

@media screen and (min-width: $screenMinWidthM) {
  .generateApiKeyStep {
    min-height: 523px;
  }
  .generateApiKeyStepAddWallet > form {
    display: block;
    height: auto;
  }
  .generateApiKeyStepAddWallet [class*="apiError"] {
    margin-top: 170px;
  }
}
