@import "../../../css/variables";

.csvReaderText {
  color: #A4A4A4;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
}
.csvReaderError {
  font-size: 14px;
  line-height: 16px;
  color: #FF7DA0;
  text-align: center;
  margin-bottom: 8px;
}

@media screen and (min-width: $screenMinWidthM) {
  .csvReader {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
  }
  .csvReaderError {
    text-align: left;
    margin: 0 8px;
  }
}
