@import "../../../../css/variables";

.dashboardOurProducts h2 {
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #13171C;
}
.dashboardOurProductsDescription {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #13171C;
}
.dashboardOurProductsCardsWrapper {
  overflow: hidden;
}
.dashboardOurProductsCards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 16px 0;
}
.dashboardOurProductsCardsChange {
  animation: slideInFromBottom 0.3s ease forwards;
}
.dashboardOurProductsIndustrySelect [class*="simpleSelectList"]::-webkit-scrollbar {
  width: 2px;
  border-radius: 8px;
}
.dashboardOurProductsIndustrySelect [class*="simpleSelectList"]::-webkit-scrollbar-thumb {
  width: 2px;
  background-color: #64ACFF;
  border-radius: 8px;
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .dashboardOurProducts {
    border-radius: 20px;
    padding: 20px;
    background-color: #FFFFFF;
  }
  .dashboardOurProductsDescription {
    max-width: 680px;
  }
}

