* {
  scroll-behavior: smooth;
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
}

a {
  color: #64ACFF;
  cursor: pointer;
  text-decoration: none;
}

pre {
  background: transparent;
  color: #fff;
  padding: 34px;
}

p {
  padding: 0;
  margin: 0;
}

#root{
  height: 100%;
}
