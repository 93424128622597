.new2faWithdrawal {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 25px;
  box-sizing: border-box;
}
.new2faWithdrawalTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  color: #13171C;
}
.new2faWithdrawalHr {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  margin-top: 25px;
  margin-bottom: 15px;
}
.new2faWithdrawalDescription {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #13171C;
  margin-top: 15px;
}
.new2faWithdrawalField {
  margin-top: 30px;
}
.new2faWithdrawalField > [class*="error"] {
  bottom: 100%;
  top: auto;
  left: auto;
  right: 0;
}
.new2faWithdrawalField [class*="errorText"] {
  text-align: right;
  padding: 0 5px 5px;
}
.new2faWithdrawalButtons {
  margin-top: 5px;
}
.new2faWithdrawalError {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 50px;
  font-size: 12px;
  line-height: 20px;
  color: #ff7da0;
  text-align: center;
  margin-top: 70px;
}
.new2faWithdrawalButton {
  width: 100%;
}
.new2faWithdrawalButtonCancel {
  background-color: #ffffff;
  color: #64ACFF;
  border: 1px solid #64ACFF;
  margin-top: 10px;
  transition: color, background-color, ease .3s;
}
.new2faWithdrawalButtonCancel:hover {
  color: #ffffff;
}
