.keyBlock {
  position: relative;
  margin-top: 24px;
  padding: 6px 16px;
  background-color: #FAFAFA;
}
.keyBlockText {
  font-size: 14px;
  line-height: 20px;
  color: #7E7E7E;
}
.keyBlockCopy {
  position: static;
}
.keyBlockCopy > span {
  display: block;
  max-width: calc(100% - 30px);
  line-height: 24px;
}
.keyBlockCopy > div {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.keyBlockCopy img {
  width: 22px;
  height: 22px;
}
.closeButton:first-of-type {
  margin-top: unset;
}
.generateIpnError {
  margin-top: auto;
  min-height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF7DA0;
  text-align: center;
}
