@import "../../css/variables";

.sectionHistory {
  background: #ffffff;
  border-radius: 8px;
  padding: 25px;
}
.sectionHistoryHeader {
  display: block;
}
.sectionHistoryTitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #13171C;
}
h2.sectionHistoryTitle {
  margin: 0;
}
.sectionHistoryRightElement {
  margin-top: 15px;
}
.sectionHistoryBody {
  margin-top: 20px;
}

@media screen and (min-width: $screenMinWidthS) {
  .sectionHistoryTitle {
    font-size: 24px;
    line-height: 30px;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .sectionHistoryHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sectionHistoryRightElement {
    margin-top: 0;
    margin-left: 10px;
  }
}
