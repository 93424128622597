@import "../../css/variables";

.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 70px);

  .form {
    position: relative;
    max-width: 437px;
    min-height: 670px;
    padding: 25px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    box-sizing: border-box;

    .serverError {
      position: absolute;
      padding: 10px 20px;
      left: 467px;
      top: 45px;
      background: #FFECF1;
      border-radius: 8px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: $colorPink;
        white-space: nowrap;
      }
    }

    .formHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .logo {
      font-family: $fontSecondary;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 28px;

      .blueText {
        color: $colorBlue;
      }
    }

    .stepsCount {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #7E7E7E;
    }

    .divider {
      height: 1px;
      width: 100%;
      background-color: #EEEEEE;
      margin: 15px 0px;
    }

    .notification {
      background-color: #dff0d8;
      border: 1px solid #d0e9c6;
      padding: 10px 15px 10px 15px;
      margin: 15px 0 15px 0;
      font-weight: 400;
      font-size: 14px;
      color: #3c763d;
    }

    .titleBig {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
    }

    .titleSmall {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
    }

    .inputContainer {
      position: relative;
      width: 100%;
      height: auto;
      margin-bottom: 20px;

      .resetError {
        margin: 20px 0px;
      }
    }

    .passwordEye {
      position: absolute;
      right: 15px;
      bottom: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 11px;
      cursor: pointer;
    }

    .reCaptchaContainer {
      margin-top: 20px;
      margin-bottom: 20px;
      transform: scale(1);
      transform-origin: 0;

      .error {
        position: absolute;
        left: 14px;
        top: 4px;
        color: $colorPink;
        font-size: 12px;
      }

      @media all and (max-width: 450px) {
        transform: scale(0.9);
      }

      @media all and (max-width: 408px) {
        transform: scale(0.8);
      }

      @media all and (max-width: 380px) {
        transform: scale(0.7);
      }

      @media all and (max-width: 350px) {
        transform: scale(0.6);
      }
    }

    .link {
      color: $colorBlue;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      text-align: center;
    }

    .checkboxRow {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin-left: 10px;
        color: #7E7E7E;
      }

      a {
        color: $colorBlue;
      }
    }
  }

  @media all and (max-width: 770px) {
    min-height: calc(100vh - 130px);
    margin-top: 60px;
    justify-content: flex-start;
    flex-direction: column;

    .form {
      .reCaptchaContainer {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    .bottomText {
      margin-top: 50px;
      position: unset;
      transform: unset;
    }
  }

  @media all and (max-width: 450px) {
    .form {
      width: calc(100vw - 60px);
    }
  }
}

.googleButton {
  margin-top: 16px;
}

.agreements {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #7E7E7E;
  margin-bottom: 10px;
}
.agreements a {
  color: $colorBlue;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
