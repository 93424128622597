.affiliateStatistic {
  margin-bottom: 30px;

  .rowBlock {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .block {
      width: 30%;
      min-width: 210px;
      height: 125px;
      text-align: center;
      padding-top: 20px;
      margin-bottom: 20px;
      border: 2px solid #64acff;
      box-shadow: 7px 7px 0px 0px rgba(236, 239, 241, 1);

      .title,
      .estimateTitle {
        margin-top: 0;
        color: #2b2b37;
        opacity: 0.6;
        font-weight: 600;
        font-size: 13px;
      }

      .title {
        margin-bottom: 30px;
      }

      .estimateTitle {
        margin-bottom: 5px;
      }

      .value,
      .estimateValue {
        color: #64acff;
        font-weight: 600;
      }

      .value {
        font-size: 24px;
      }

      .estimateValue {
        display: flex;
        margin: 10px 30px;
        max-width: 300px;
        font-size: 16px;

        img {
          width: 16px;
          height: 16px;
          align-self: center;
          margin-right: 5px;
        }
      }

      ::-webkit-scrollbar {
        width: 0px;
      }

      .scrollBlock {
        height: 100px;
        overflow: auto;
      }
    }
  }
}

@media all and (max-width: 720px) {
  .affiliateStatistic {
    .rowBlock {
      .block {
        width: 100%;
      }
    }
  }
}
