@import "../../../../css/variables.scss";

.whitelistTable {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
}
.whitelistTableHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #A4A4A4;
  padding: 16px 24px 12px;
  border-bottom: 1px solid #eeeeee;
}
.whitelistTableBody {
  padding: 12px 8px;
}
.whitelistTableBodyLoader {
  height: 38px;
  text-align: center;
}
.whitelistTableBodyError {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FF7DA0;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.whitelistTableTr {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  border-radius: 4px;
  padding: 6px 16px;
  margin-top: 4px;
  background-color: #ffffff;
  transition: background-color ease .3s;
  cursor: default;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    background-color: #ECF5FF;
  }
}
.whitelistTableTrActive {
  background-color: #ECF5FF;
}
.whitelistTableTd {
  position: relative;
  &:first-child {
    width: 22px;
    margin-right: 10px;
    flex-shrink: 0;
  }
  &:nth-child(2) {
    flex-grow: 1;
  }
  &:last-child {
    flex-shrink: 0;
  }
}
.whitelistTableTdGrey {
  color: #A4A4A4;
}
.whitelistTableTdAddress {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.whitelistTableTdButton {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.whitelistTableTdButton svg {
  transition: fill ease .3s;
}
.whitelistTableTdButton:hover svg {
  fill: #64ACFF;
}
div.whitelistTableTooltip {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px 0;
  transform: translateX(10px);
}
div.whitelistTableTooltip:global(.show) {
  opacity: 1;
}
.whitelistTableTooltipButton {
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #13171C;
  background-color: #ffffff;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color ease .3s;
  user-select: none;

  &:hover {
    background-color: #FAFAFA;
  }
}
.whitelistTablePagination {
  margin-top: 12px;
}

@media screen and (min-width: $screenMinWidthXL) {
  .whitelistTablePagination {
    margin-top: 12px;
  }
}
