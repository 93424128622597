@import "../../css/variables.scss";

.switchCheckbox {
  cursor: pointer;
  .switchCheckboxLabel {
    padding-right: 10px;
    color: $textColor;
    font-weight: 600;
    font-size: 17px;
  }
  .checkboxBody {
    display: inline-block;
    width: 40px;
    height: 22px;
    border: 1px solid $colorBlue;
    background-color: $checkboxColor;
    position: relative;
    &.checked {
      background-color: $checkboxCheckedColor;
      .switch {
        transform: translateX(18px);
      }
    }
    input.checkboxInput {
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
    .switch {
      position: absolute;
      width: 16px;
      height: 16px;
      background-color: white;
      box-shadow: 0 0 2px 0 rgb(146, 145, 145);
      border-radius: 1px;
      left: 3px;
      top: 3px;
      transition: transform 0.1s linear;
    }
  }
}
