@import "../../../css/variables.scss";

.shopify {
  display: flex;

  & > div {
    width: 50%;

    @media all and (max-width: 991px) {
      width: unset;
    }
  }

  .input {
    width: calc(100% - 20px);
    max-width: 420px;
    margin-right: 0;
    margin-bottom: 15px;

    @media all and (max-width: 767px) {
      max-width: unset;
    }
  }

  .container {
    max-width: 440px;
    padding-right: 20px;

    @media all and (max-width: 991px) {
      padding-right: 0;
    }

    @media all and (max-width: 767px) {
      max-width: unset;
    }
  }

  .text {
    max-width: calc(100% - 20px);
    margin: 10px 0;

    font-size: 15px;
    font-weight: 400;
    line-height: 18px;

    @media all and (max-width: 991px) {
      max-width: 440px;
    }

    @media all and (max-width: 767px) {
      max-width: unset;
    }
  }

  .button {
    position: relative;
    cursor: pointer;
  }

  .buttonBox {
    margin-bottom: 15px;

    display: flex;
    align-items: center;
  }

  .column {
    flex-direction: column;
  }

  .successMessage {
    margin-left: 10px;
    padding: 6px 12px;

    background-color: rgba(#4ee488, 0.1);
    color: #4ee488;
  }

  .errorMessage {
    margin: 10px 0 0 5px;
    background-color: rgba(#ef2c2c, 0.1);
    color: #ef2c2c;
  }

  .error {
    border: 1px solid #ef2c2c;
  }

  .blue {
    color: $colorBlue;
  }

  .pink {
    color: $colorPink;
  }

  .mainLabel {
    margin-top: 0;
    margin-bottom: 8px;

    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
  }

  .label {
    margin-top: 0;
    margin-bottom: 8px;

    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
  }

  .code {
    min-height: 19px;
    margin-top: 0;
    margin-bottom: 15px;
    padding: 8px 10px;
    background-color: $bgPrimaryColor;
    overflow-wrap: break-word;
  }

  .select {
    width: 100%;
    max-width: 440px;
    margin-bottom: 20px;
    position: relative;

    select {
      display: block;
      width: 100%;
      padding: 0 10px;
      height: 35px;
      font-size: 15px;
      background-color: #eceff1;
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-family: inherit;
      color: #000;
    }

    &:after {
      content: "";
      display: block;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      border-color: #000 transparent transparent transparent;
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 15px;
      z-index: 1;
      margin-top: -3px;
    }

    @media all and (max-width: 767px) {
      max-width: unset;
    }
  }

  @media all and (max-width: 991px) {
    flex-direction: column;
  }
}
