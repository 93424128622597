@import "../../../css/variables";

.numberStepperInput {
  position: relative;
  width: 100%;
}
.numberInput {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  padding: 0;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  -moz-appearance: textfield;
}
.numberInput::-webkit-outer-spin-button,
.numberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.controllerButton {
  position: absolute;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 39px;
  box-sizing: border-box;
  padding: 0;
  background-color: transparent;
}
.controllerButton:hover {
  background-color: #FAFAFA;
}
.controllerButtonMinus {
  left: 1px;
  border-radius: 8px 0 0 8px;
}
.controllerButtonPlus {
  right: 1px;
  border-radius: 0 8px 8px 0;
}

@media screen and (min-width: $screenMinWidthL) {
  .numberStepperInput {
    width: 136px;
  }
}
