@import "../../../css/variables";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  background-color: #64ACFF;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  padding: 0 16px;
  transition: background ease .3s;
  box-sizing: border-box;
}
.button:hover {
  background-color: #73b1fa;
}
.button:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.buttonRed {
  color: #ffffff;
  background-color: #FF7DA0;
}
.buttonRed:hover {
  background-color: rgba(#FF7DA0, .9);
}
.buttonBorder {
  color: #64ACFF;
  background-color: transparent;
  border: 1px solid #64ACFF;
  transition: background, color, ease .3s;
}
.buttonBorder:hover {
  background-color: rgba(#73b1fa, .1);
}
.buttonBorderRed {
  color: #FF7DA0;
  background-color: transparent;
  border: 1px solid #FF7DA0;
  transition: background, color, ease .3s;
  &:hover {
    background-color: rgba(#FF7DA0, .1);
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .button {
    width: auto;
  }
}
