@import "../../../../css/variables";

.fieldDate [class*="dateFieldBox"] {
  height: 40px;
}
.filterInput {
  margin-top: 16px;
}
.filterInput :global(.styles_fieldTitle__3RudE),
.filterInput :global(.styles_fieldTitle__2T3Cb) {
  padding: 0;
}
.filterInput :global(.styles_simpleSelect__jcK-_),
.filterInput :global(.styles_input__26XgI) {
  height: 40px;
}


@media  screen and (min-width: $screenMinWidthL) {
  .fieldDate,
  .filterInput {
    width: calc(50% - 16px);
  }
}

@media  screen and (min-width: $screenMinWidthXXL) {
  .fieldDate,
  .filterInput {
    width: calc(25% - 16px);
  }
}
