.select {
  position: relative;
  display: block;
  width: 100%;
  height: 68px;
}
.selectSmall {
  height: 48px;
}
.selectBody {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  padding: 15px 15px 0;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #64ACFF;
  box-shadow: 0 10px 20px rgba(139, 139, 139, 0.06);
  overflow: hidden;
}
.selectNotFound {
  padding: 15px 0;
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #13171C;
}
.selectList {
  max-height: 350px;
  margin: 16px -15px 0;
  padding: 0;
  overflow-y: auto;
  list-style-type: none;
}
.selectSmall .selectList {
  margin-top: 8px;
}
.selectItem {
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  transition: background-color ease .3s;
}
.selectItem:hover {
  background-color: #f5f7fa;
}
.selectItemActive {
  background-color: #f5f7fa;
}
.selectLoading {
  position: relative;
  display: block;
  margin: 20px auto;
}
.selectPlaceholderText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #8A8A8E;
}
