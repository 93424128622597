@import "../../../../css/variables";

.groupCurrency {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #64ACFF;
  border-radius: 5px;
  padding: 2px 5px;
  transition: box-shadow ease .2s;
  cursor: default;
}
.groupCurrencyInteractive {
  cursor: pointer;
}
.groupCurrencyInteractive:hover {
  box-shadow: 0 2px 7px 0 rgba(#64ACFF, .5);
}
.groupCurrencyImage {
  display: block;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.groupCurrencyContent {
  flex-grow: 1;
  margin-left: 5px;
}
.groupCurrencyTicker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #13171C;
}
.groupCurrencyNetwork {
  margin-left: 5px;
}
.groupCurrencyName {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A4A4A4;
}
.groupCurrencyDisabled {
  opacity: .5;
  border-color: #A4A4A4;
  filter: grayscale(100%);
}

@media screen and (min-width: $screenMinWidthM) {
  .groupCurrency {
    padding: 4px 10px;
  }
  .groupCurrencyImage {
    width: 32px;
    height: 32px;
  }
  .groupCurrencyContent {
    margin-left: 10px;
  }
  .groupCurrencyTicker {
    font-size: 16px;
    line-height: 20px;
  }
  .groupCurrencyName {
    font-size: 14px;
    line-height: 20px;
  }
}
