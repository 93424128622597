.field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 44px;
  background-color: #fafafd;
  border: 1px solid #e9e7ee;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
}
.fieldIcon {
  display: block;
  flex-shrink: 0;
  margin-left: 15px;
  margin-right: 8px;
}
.fieldInput {
  flex-grow: 1;
  font-size: 16px;
  line-height: 24px;
  border: 0;
  background: none;
  outline: 0;
}
