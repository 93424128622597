@import "src/css/variables";

.mainMenuItemLink {
  max-width: 216px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #A0A0A0;
  transition: color ease .3s;
}
.mainMenuItemLink svg {
  display: block;
  margin-right: 8px;
  transition: fill ease .3s;
}
.mainMenuItemLinkActive {
  color: #ffffff;
  pointer-events: none;
}
.mainMenuItemLink:hover {
  color: #ffffff;
}
.mainMenuItemLinkActive svg,
.mainMenuItemLink:hover svg {
  fill: #ffffff;
}
.mainMenuItemDropdown {
  max-width: 216px;
}
.mainMenuItemLinkBeta {
  font-size: 14px;
  color: #A0A0A0;
  margin-left: auto;
  margin-right: 10px;
}

@media screen and (min-width: $screenMinWidthL) {
  .mainMenuItemLink,
  .mainMenuItemDropdown {
    max-width: 100%;
  }
}
