@import "../../../../../css/variables.scss";

.wrapper {
  font-size: 15px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 400px;

  & > span {
    color: red;
    font-size: 12px;
    margin-bottom: 12px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .rowButtons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }

  .error {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #fc0606;
  }

  .row {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .table {
    display: flex;
    flex-direction: column;
    width: 100%;

    .thead {
      font-weight: 600;

      tr {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        td {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 100%;

          &:last-child {
            justify-content: center;
          }
        }
      }
    }

    .td {
      height: 40px;
      width: 50%;

      &:first-child {
        text-align: left;
      }

      text-align: center;
    }

    .tbody {
      display: flex;
      flex-direction: column;
      height: 300px;
      width: 100%;
      overflow: auto;

      tr {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      td {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
      }
    }
  }

  .input {
    display: inline-block;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 10px;
    align-self: flex-end;

    font-size: 15px;
    background-color: $bgPrimaryColor;
    border: none;
  }

  .select {
    width: 100%;
    text-transform: capitalize;
  }
}
