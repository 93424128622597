@import "../../../../css/variables.scss";

.popup {
  width: 100%;
  max-width: 530px;
  padding: 15px 20px;
}

@media screen and (min-width: $screenMinWidthM) {
  .popup {
    padding: 30px 40px;
  }
}
