@import "src/css/variables";

.paymentsFilter {
  display: block;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px 16px;
  box-sizing: border-box;
}
.paymentsFilterClose {
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.paymentsFilterCloseLine {
  width: calc(100% - 8px);
  height: 2px;
  background-color: #13171C;
  position: absolute;
  top: 11px;
  left: 0;
  right: 0;
  margin: auto;
}
.paymentsFilterCloseLine:first-child {
  transform: rotate(45deg);
}
.paymentsFilterCloseLine:last-child {
  transform: rotate(-45deg);
}
.paymentsFilterTitle {
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  color: #13171C;
}
.paymentsFilterLine {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  margin: 16px 0 24px;
}
.paymentsFields * {
  box-sizing: border-box;
}
.paymentsFields {
  height: calc(100vh - 340px);
  overflow-y: auto;
}
div.paymentsFieldDate {
  max-width: 100%;
}
.paymentsFilterInput {
  margin-top: 16px;
}
.paymentsFilterInput:first-child {
  margin-top: 0;
}
.paymentsButtons {
  margin-top: 24px;
}
.paymentsButton {
  height: 48px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin-top: 16px;
}
.paymentsButton:first-child {
  margin-top: 0;
}
.paymentsButtonReset,
.paymentsButtonReset:hover {
  color: #FF7DA0;
  background-color: transparent;
}
.paymentsButtonExport,
.paymentsButtonExport:hover {
  color: #64ACFF;
  background-color: transparent;
}

@media  screen and (min-width: $screenMinWidthL) {
  .paymentsFilter {
    padding: 0;
  }
  .paymentsFilterClose,
  .paymentsFilterTitle,
  .paymentsFilterLine {
    display: none;
  }
  .paymentsFields {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -16px;
    margin-left: -12px;
    height: auto;
    overflow-y: visible;
  }
  .paymentsFilterInput {
    width: calc(33.3% - 12px);
    margin-top: 16px;
    margin-left: 12px;
  }
  .paymentsFilterInput:first-child {
    margin-top: 16px;
  }
  .paymentsButtonExport {
    display: none;
  }
  .paymentsButtons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .paymentsButton {
    width: auto;
    height: auto;
    font-size: 16px;
    line-height: 20px;
    background-color: transparent;
    padding: 0;
    margin: 0 0 0 24px;
    transition: color ease .3s;
  }
  .paymentsButton:hover {
    background-color: transparent;
  }
  .paymentsButtonSubmit {
    color: #64ACFF;
    margin-left: 0;
  }
  .paymentsButtonSubmit:hover {
    color: #91c4ff;
  }
  .paymentsButtonReset:hover {
    color: #ffa5be;
  }
}
