.input {
  width: 100%;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #13171C;
  background-color: #ffffff;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  padding: 0 15px;
  box-sizing: border-box;
}
.input::placeholder {
  color: #A4A4A4;
}
.inputError {
  border-color: #FF7DA0;
}
.inputSuccess {
  border-color: #61BC6A;
}
