@import "../../css/variables";

.loaderBlock {
  display: flex;
  align-items: center;
  justify-content: center;
}
.infoBlock {
  margin-top: -16px;
  margin-bottom: 32px;
}
.sectionHeader {
  padding: 24px 16px;
}
.sectionHeader h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}
.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}
.massPayout {
  margin-top: 24px;
}
.sectionWhitelist {
  margin-top: 24px;
  padding: 24px 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .sectionBox {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 16px;
  }
  .sectionHeader {
    width: calc(50% - 8px);
  }
  .description {
    font-size: 18px;
    line-height: 22px;
  }
  .sectionWhitelist {
    width: calc(50% - 8px);
    margin-top: 0;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .sectionBox {
    gap: 24px;
  }
  .sectionHeader,
  .sectionWhitelist {
    width: calc(50% - 12px);
  }
}
