@import "../../../../css/variables.scss";

.billingPlanTable {
  margin-top: 24px;
}
.billingPlanTable th:nth-child(1),
.billingPlanTable td:nth-child(1) {
  width: 30%;
}
.billingPlanTable th:nth-child(2),
.billingPlanTable td:nth-child(2) {
  width: 30%;
}
.billingPlanTable th:nth-child(3),
.billingPlanTable td:nth-child(3) {
  width: 40%;
}

@media screen and (min-width: $screenMinWidthM) {
  .billingPlanTable th:nth-child(1),
  .billingPlanTable td:nth-child(1) {
    width: 25%;
  }
  .billingPlanTable th:nth-child(2),
  .billingPlanTable td:nth-child(2) {
    width: 25%;
  }
  .billingPlanTable th:nth-child(3),
  .billingPlanTable td:nth-child(3) {
    width: 25%;
  }
  .billingPlanTable th:nth-child(4),
  .billingPlanTable td:nth-child(4) {
    width: 25%;
  }
}
