@import "../../../../css/variables.scss";

.searchInput {
  flex-shrink: 0;
}
.billingPlans {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 16px;
}
.billingPlansLoader {
  text-align: center;
}
.billingPlansItem {
  min-height: 30px;
  font-size: 16px;
  line-height: 20px;
  color: #A4A4A4;
  margin-top: 8px;
}
.billingPlansItem:first-child {
  margin-top: 0;
}
.billingPlansItemLabel {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.billingPlansItemId {
  display: inline-block;
  min-width: 100px;
}
.billingPlansItemName {
  color: #13171C;
  margin-left: 5px;
}
.error {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-shrink: 0;
  min-height: 35px;
  font-size: 14px;
  line-height: 16px;
  color: #FF7DA0;
  text-align: center;
  margin-top: auto;
}
.button {
  width: 100%;
  flex-shrink: 0;
  margin-top: 4px;
}
.buttonSecond {
  width: 100%;
  margin-top: 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .billingPlans {
    height: 250px;
  }
}

@media screen and (min-width: $screenMinWidthXXL) {
  .billingPlans {
    height: 350px;
  }
}
