@import "../../../../css/variables";

.dashboardStatisticsGraph {
  margin-top: 30px;
}
.dashboardTransactions {
  margin-top: 30px;
}
div.dashboardTransactionsHistory {
  margin-top: 0;
}
.dashboardAdditionalSection {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 15px;
  margin-top: 30px;
}
.dashboardSystemStatuses {
  flex-shrink: 0;
}
.dashboardPlatformUpdates {
  flex-grow: 1;
}

@media screen and (min-width: $screenMinWidthS) {
  .dashboardAdditionalSection {
    flex-direction: row;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .dashboardBox {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 24px;
  }
  .dashboardStatistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(67% - 24px);
    order: 1;
  }
  div.dashboardStatisticsGraph {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 25px;
    flex-grow: 1;
  }
  .dashboardTransactions {
    width: 100%;
    order: 3;
    margin-top: 0;
  }
  .dashboardAdditionalSection {
    width: 33%;
    flex-direction: column;
    order: 2;
    margin-top: 0;
    gap: 25px;
  }
}
