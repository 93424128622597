@import "src/css/variables";

.fullFilter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
label.fullFilterSearchInput {
  max-width: 320px;
  height: 44px;
}
.fullFilterButton {
  flex-shrink: 0;
  margin-left: 16px;
}
.userPaymentsFilter {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  border-radius: 0;
}
.userPaymentsFilter [class*="paymentsFields"] {
  height: calc(100vh - 290px);
  overflow-y: auto;
}

@media screen and (min-width: $screenMinWidthL) {
  .userPaymentsFilter {
    position: static;
    background-color: transparent;
  }
  .userPaymentsFilter form {
    margin-top: 16px;
  }
  .userPaymentsFilter [class*="paymentsFields"] {
    height: auto;
    overflow-y: visible;
  }
}
