@import "../../../../css/variables";

.dashboardStatisticValues {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.dashboardStatisticValuesReverse {
  flex-direction: column-reverse;
}

@media screen and (min-width: $screenMinWidthM) {
  .dashboardStatisticValues {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
  }
  .dashboardStatisticValuesReverse {
    flex-direction: row-reverse;
  }
  .dashboardStatisticValue {
    width: 33.3%;
    flex-grow: 1;
    margin-top: 0;
  }
}
