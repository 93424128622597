.paymentDetailsTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #13171C;
}
.searchInput {
  margin-top: 10px;
}
.searchFilterMenuIcon svg {
  display: block;
  transition: fill ease .3s;
}
.searchFilterMenuIcon svg:hover {
  fill: #64ACFF;
}
.searchFilterMenuIconActive svg {
  fill: #64ACFF;
}
.paymentDetailsFilters {
  max-height: 0;
  overflow: hidden;
  transition: max-height linear .3s;
}
.paymentDetailsFiltersActive {
  max-height: 700px;
  overflow: visible;
}
.paymentDetailsFiltersPanel {
  margin-top: 30px;
}
.paymentDetailsTable {
  margin-top: 30px;
  margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
  .paymentDetailsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .paymentDetailsTitle {
    flex-shrink: 0;
  }
  .searchInput {
    max-width: 300px;
    margin-top: 0;
  }
}

@media screen and (min-width: 1024px) {
  .paymentDetailsTitle {
    font-size: 24px;
    line-height: 30px;
  }
}
