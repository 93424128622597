@import "../../css/variables.scss";

.button{
    position: relative;
    max-width: 100%;
    width: 385px;
    background: $colorBlue;
    border-radius: 4px;
    border: 0;
    line-height: 22px;
    height: 43px;
    padding: 10px 0;
    box-sizing: border-box;
    font-size: 18px;
    color: $colorWhite;
    transition: .3s;
    font-weight: 600;
    cursor: pointer;
    opacity: 1;

    &:hover {
        background-color: #4A9EFF;
    }

    &:focus {
        background-color: #84BDFF;
    }

    &:disabled{
        opacity: 1;
        color: $colorFontDisabled;
        background-color: $colorDisabled;
    }
}

.outline{
    background-color:$colorWhite;
    border: 1px solid $colorBlue;
    color: $colorBlue;

    &:hover {
        background-color:#F3F9FF;
    }

    &:focus {
        background-color: #EBF4FF;
    }

}

.green{
    background-color: $green55;
    border: 1px solid $green55;

    &:hover {
        background-color: $colorLightGrayVar;
        color: $green55;
    }

    &:focus {
        background-color: $colorLightGrayVar;
        color: $green55;
    }

}

.backNo{
    background: transparent;
    border-color: transparent;

    &:disabled,&:focus,&:hover{
        color: transparent;
        background-color: transparent;
    }
}
