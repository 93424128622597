@import "../../../../css/variables";

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  line-height: 20px;
  color: #13171C;
}
.contentRed {
  color: $colorPink;
}
