@import "../../css/variables.scss";

.settingTableWrapper {
  margin-top: 16px;
}
.searchInput {
  height: 44px;
  margin-bottom: 16px;
  border-color: #eeeeee;
}
.button {
  width: 100%;
  margin-top: 32px;
}
.buttonTwo {
  margin-top: 16px;
  background-color: #FFFFFF;
}
.buttonTwoArrows {
  position: relative;

  &:after {
    content: "";
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../../public/images/arrow-double.svg');
    width: 24px;
    height: 24px;
    margin-left: 4px;
    margin-top: 3px;
    border: none;
    transform: unset;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .settingTableWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .searchInput {
    width: calc(100% - 250px - 48px);
    order: 1;
    margin-right: auto;
  }
  .button {
    width: 250px;
    order: 2;
    margin: 0 0 0 10px;
  }
  .content {
    width: 100%;
    order: 3;
  }
}
