.payoutCreated {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}
.payoutCreatedTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
}
.payoutCreatedText {
  margin-top: 24px;
}
.payoutCreatedText a {
  color: #64ACFF;
}
.payoutCreatedText strong {
  font-weight: 700;
}
button.payoutCreatedButton {
  width: 100%;
  max-width: 287px;
  margin: 32px auto 0;
}
