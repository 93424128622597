@import "../../../../css/variables.scss";

.forActivateCustody {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #13171C;
}
.forActivateCustodyButton {
  margin-top: 24px;
}

@media screen and (min-width: $screenMinWidthM) {
  .forActivateCustodyButton {
    width: 163px;
    margin-top: 24px;
  }
}
