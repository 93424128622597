@import "../../css/variables.scss";

.planInfoBox {
  overflow-x: auto;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
.planInfo {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
}
.planInfoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.6px;
  color: #7E7E7E;
}
.planInfoBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A4A4A4;
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
  padding-top: 10px;
}
.planInfoHeaderItem,
.planInfoBodyItem {
  width: calc(33.3% - 6px);
}
.planInfoBodySecondItem {
  margin-top: 4px;
}
.colorBlack {
  color: #13171C;
}
.colorPink {
  color: #FF7DA0;
}
.colorGreen {
  color: #61BC6A;
}
.overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.priceFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

@media screen and (min-width: $screenMinWidthL) {
  .planInfo {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media screen and (min-width: $screenMinWidthXL) {
  .planInfoHeaderItem,
  .planInfoBodyItem {
    width: calc(20% - 6px);
  }
}
