$fontPrimary: 'Source Sans Pro', sans-serif;
$fontSecondary: 'Syne', sans-serif;


$colorTextBlack:#13171C;
$colorWarning: #f1b44e;
$colorBlue: #64ACFF;
$colorBlueLight: #73b1fa;
$colorBlueF2: #F2F8FF;
$colorDisabled: #F4F4F4;
$colorFontDisabled: #D7D7D7;
$colorD2: #d2d2d2;
$colorDF: #dfdfdf;
$colorDisabledDark: #bcbcbd;
$colorGray: #7f7f86;
$colorDark: #27282D;
$colorBlack: #000000;
$colorLightGray: #ceccd9;
$colorLightGrayVar: #F3F9FF;
$colorLightBlue: #c1ddff;
$colorWhite: #ffffff;
$colorLightPink: #F19999;
$colorPink: #FF7DA0;
$bgPrimaryColor: #ECEFF1;
$shadowColor: rgba(236,239,241,1);

$notificationBgColor: #dff0d8;
$notificationBorderColor: #d0e9c6;

$textColor: #2B2B37;
$notificationTextColor: #3c763d;

$checkboxCheckedColor: rgba(#4EE488, 0.3);
$green74: #74CF6C;
$green55: #55CF86;
$checkboxColor: rgb(212, 211, 211);

$modalBGColor: rgba(0, 0, 0, 0.3);
$modalBGColorOld: #fff;
$modalOverlayColor: #13171C;
$modalOverlayColorOld: #fff;
$modalTextColor: #fefefe;

/* XXL */
$screenMinWidthXXL: 1600px;
/* XL */
$screenMaxWidthXL: 1599px;
$screenMinWidthXL: 1200px;
/* L */
$screenMaxWidthL: 1199px;
$screenMinWidthL: 992px;
/* M */
$screenMaxWidthM: 991px;
$screenMinWidthM: 768px;
/* S */
$screenMaxWidthS: 767px;
$screenMinWidthS: 576px;
/* XS */
$screenMaxWidthXS: 575px;
$screenMinWidthXS: 380px;
/* XXS */
$screenMaxWidthXXS: 379px;
$screenMinWidthXXS: 375px;
