@import "../../../../css/variables.scss";

.deleteApiKeyError {
  margin-top: auto;
  min-height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF7DA0;
  text-align: center;
}
.deleteApiKeyText {
  font-size: 16px;
  line-height: 24px;
  color: #13171C;
}
.deleteApiKeyText span {
  font-weight: 700;
}
.closeButton {
  margin-top: 12px;
}

@media screen and (min-width: $screenMinWidthM) {
  .deleteApiKeyError {
    margin-top: 4px;
  }
}
