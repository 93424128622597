@import "../../../../css/variables";

.popup {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}
.popup [class*="title"] {
  font-size: 28px;
  font-weight: 600;
}

@media screen and (min-width: $screenMinWidthM) {
  .popup {
    max-width: 810px;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .popup {
    padding: 64px;
  }
}
