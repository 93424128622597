@import "../../../css/variables";

.dateField {
  width: 100%;
}
.dateFieldTitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: #13171C;
  margin-bottom: 8px;
}
.dateFieldBox {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  padding: 0 15px;
  box-sizing: border-box;
}
.dateFieldBoxActive .dateFieldArrow {
  transform: rotate(180deg);
}
.dateFieldInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.dateFieldInputGray {
  color: #A4A4A4;
}
.dateFieldArrow {
  transition: transform ease .3s;
}
.dateFieldDatepicker {
  max-width: 270px;
  min-width: 270px;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
  visibility: hidden;
  opacity: 0;
  transition: visibility, opacity, ease .3s;
}
.dateFieldDatepickerAnimate {
  visibility: visible;
  opacity: 1;
}

@media screen and (min-width: $screenMinWidthL) {
  .dateFieldBox {
    height: 44px;
  }
}
