@import "../../../../css/variables.scss";

.customerButtons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: -16px;
}
.customerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(50% - 16px);
  height: 44px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #13171C;
  background-color: #ffffff;
  border-radius: 8px;
  margin-left: 16px;
  cursor: pointer;
  box-sizing: border-box;
  transition: box-shadow ease .3s;
}
.customerButton:last-child {
  width: 100%;
  margin-top: 16px;
}
.customerButton img {
  display: block;
  margin-right: 8px;
}
.customerButton:hover {
  box-shadow: 0 0 6px 0 rgba(#000000, .2);
}
.customerButtonDisabled {
  pointer-events: none;
  cursor: default;
  filter: grayscale(1);
  opacity: 0.6;
}

@media screen and (min-width: $screenMinWidthM) {
  .customerButton {
    width: auto;
    padding: 0 16px;
  }
  .customerButton:last-child {
    width: auto;
    margin-top: 0;
  }
}
