@import "../../../../css/variables";

.sectionBalances {
  max-width: 1600px;
  padding: 24px 0 16px;
}
.sectionBalances h2 {
  font-size: 24px;
  line-height: 30px;
  color: #13171C;
}
.custodyHistory {
  max-width: 1600px;
  margin-top: 20px;
}
.custodyHistoryTabs {
  flex-shrink: 0;
}
.custodyHistoryTable {
  padding-top: 12px;
  padding-bottom: 12px;
}
.custodyHistoryTable th:nth-child(1),
.custodyHistoryTable td:nth-child(1) {
  width: 13%;
}
.custodyHistoryTable th:nth-child(2),
.custodyHistoryTable td:nth-child(2) {
  width: 21%;
}
.custodyHistoryTable th:nth-child(3),
.custodyHistoryTable td:nth-child(3) {
  width: 21%;
}
.custodyHistoryTable td:nth-child(2) div,
.custodyConversionHistoryTable td:nth-child(3) div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.custodyHistoryTable th:nth-child(4),
.custodyHistoryTable th:nth-child(5),
.custodyHistoryTable td:nth-child(4),
.custodyHistoryTable td:nth-child(5) {
  width: 17%;
}
.custodyHistoryTable th:nth-child(6),
.custodyHistoryTable td:nth-child(6) {
  width: 11%;
}
.custodyFiatHistoryTable th:nth-child(2),
.custodyFiatHistoryTable td:nth-child(2) {
  width: 16%;
}
.custodyFiatHistoryTable th:nth-child(3),
.custodyFiatHistoryTable td:nth-child(3) {
  width: 24%;
}
.custodyHistoryTable sup {
  min-width: 36px;
}
.custodyHistoryFilterBox {
  max-height: 0;
  overflow: hidden;
  transition: max-height linear .2s;
}
.custodyHistoryFilterBoxActive {
  max-height: 1000px;
  overflow: visible;
}
.custodyHistoryFilter {
  background-color: #ffffff;
  margin-top: 10px;
}
.searchInputMenuIcon svg {
  display: block;
  transition: fill ease .3s;
}
.searchInputMenuIconActive svg {
  fill: #64ACFF;
}
.custodyBalanceTopUpPopup {
  padding: 48px 16px;
}
.custodyPaymentInfoPopup {
  max-width: 490px;
  padding: 15px 20px;
}
.custodyConversionPopup {
  max-width: 400px;
}
.custodyPayoutCreatedPopup {
  max-width: 490px;
  padding: 15px 20px;
}
.custodyInfoBoxPopup {
  max-width: 600px;
}
.custodyExportPopup {
  max-width: 300px;
  padding: 20px;
}
.custodyRequestPayoutPopup {
  max-width: 408px;
  padding: 25px;
}
.custodyAddWalletPopup {
  max-width: 440px;
  padding: 25px;
}
.custodyHistoryTabs {
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: auto;
  width: 100%;
  padding: 4px;
  border: 1px solid #DFDFDF;
  border-radius: 8px;

  &:before {
    content: "";
    position: absolute;
    display: block;
    height: 66px;
    width: calc(33% - 4px);
    border: 1px solid #64ACFF;
    border-radius: 4px;
    transition: all 0.3s ease;
  }
}
.custodyHistoryTabsConversionsActive:before {
  transform: translateX(100%);
}
.custodyHistoryTabsFiatActive:before {
  transform: translateX(200%);
}
.custodyHistoryTabs > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 66px;
  width: 33%;
  padding: 4px;
  margin-left: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #7E7E7E;

  &:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    padding-right: 4px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}
.custodyHistoryTabs > div:nth-child(1):before {
  background-image: url("../../../../../public/images/custody-page/crypto-payouts-icon.svg");
}
.custodyHistoryTabs > div:nth-child(2):before {
  background-image: url("../../../../../public/images/custody-page/conversions-icon.svg");
}
.custodyHistoryTabs > div:nth-child(3):before {
  background-image: url("../../../../../public/images/custody-page/fiat-payouts-icon.svg");
}
.custodyHistoryTabs [class*="tableTabActive"] {
  font-weight: 600;
  color: #64ACFF;
}
.custodyNoTransactionsIcon {
  margin-bottom: 8px;
}
.custodyHistoryTableEmpty {
  padding: 0 12px;
  overflow-x: hidden;
}
.custodyHistoryTableEmpty table {
  min-width: unset;
}
.custodyHistoryTableEmpty thead {
  display: none;
}
.custodyHistoryTableEmpty [class*="historyTableEmptyTr"] td {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 15px;
}
.custodyHistoryTableLink {
  padding: 8px 12px;
  background-color: #ECF5FF;
}
.custodyHistoryTableLink img {
  display: none;
}
.custodyHistoryTableLink p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.custodyHistoryTableLink span {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: #64ACFF;
}

@media screen and (min-width: $screenMinWidthXXS) {
  .custodyHistoryTabs:before {
    height: 42px;
  }
  .custodyHistoryTabs > div {
    height: auto;
    white-space: nowrap;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .sectionBalances {
    padding: 24px 24px 16px;
  }
  .custodyHistory {
    margin-top: 45px;
  }
  .custodyHistoryFilter {
    margin-top: 20px;
  }
  .custodyConversionPopup {
    padding: 32px;
  }
  .custodyHistoryTabs {
    height: 44px;
    max-width: 400px;

    &:before {
      height: 32px;
    }
  }
  .custodyHistoryTabs > div {
    flex-direction: row;
    font-size: 16px;
    line-height: 24px;
  }
  .searchInput {
    height: 40px;
    width: 250px;
  }
  .custodyHistoryTableEmpty {
    overflow-x: auto;
  }
  .custodyHistoryTableEmpty table {
    min-width: 760px;
  }
  .custodyHistoryTableEmpty thead {
    display: table-header-group;
  }
  .custodyHistoryTableEmpty [class*="historyTableEmptyTr"] td {
    display: table-cell;
    padding: 25px 15px 15px;
  }
  .custodyNoTransactionsIcon {
    position: relative;
    bottom: 10px;
    right: 110px
  }
  .custodyBalanceTopUpPopup {
    padding: 32px;
    max-width: 456px;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .custodyHistoryTabs:before {
    width: calc(33% - 3px);
  }
  .custodyHistoryTableLink img {
    display: block;
  }
  .custodyHistoryTableLink p {
    text-align: left;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .custodyHistoryTabs {
    max-width: 436px;
  }
  .searchInput {
    width: 300px;
  }
  .custodyNoTransactionsIcon {
    right: 120px
  }
}
