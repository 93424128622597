@import "../../../../css/variables";

.paymentDetailsField {
  margin-top: 16px;
}
.fieldController {
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
}
.fieldData [class*='fieldBox_'] {
  &:after {
    content: "%";
    position: absolute;
    right: 16px;
    top: 14px;
  }
}
.fieldData input {
  height: 48px;
}
.saveButton {
  width: 80px;
  height: 48px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 24px;
  transition: all ease 0.3s;
}
.saveButtonSaved,
.saveButtonSaved:hover {
  background-color: #DAFF7C;
}
.paymentDetailsField .errorText {
  color: #FF7DA0;
}

@media screen and (min-width: $screenMinWidthL) {
  .fieldData {
    width: 302px;
  }
}
