@import "../../../../css/variables";

.whitelistSuccessPopup {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.whitelistSuccessPopup [class*="icon"] img {
  max-width: 100%;
  min-height: 154px;
}
.whitelistSuccessPopup [class*="title"] {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  margin-top: 24px;
}
.whitelistSuccessPopup [class*="description"] {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #13171C;
  margin-top: 12px;
}

@media screen and (min-width: $screenMinWidthM) {
  .whitelistSuccessPopup {
    max-width: 450px;
    padding: 48px 32px 32px;
  }
}
