.burger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 20px;
  height: 16px;
  position: relative;
  z-index: 1;
  cursor: pointer;
  user-select: none;
}
.burger div {
  position: relative;
  top: 0;
  z-index: 1;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ff7da0;
  opacity: 1;
  transition: all ease .3s;
  transform: translateX(0) rotate(0);
}
.burgerActive div:nth-child(1) {
  top: 7px;
  transform: rotate(45deg);
}
.burgerActive div:nth-child(2) {
  transform: translateX(-20px);
  opacity: 0;
}
.burgerActive div:nth-child(3) {
  top: -7px;
  transform: rotate(-45deg);
}
