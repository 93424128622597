@import "../../../../css/variables";

.dashboardOnboardingDescription {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #13171C;
}
.dashboardOptions {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 40px;
}

@media screen and (min-width: $screenMinWidthL) {
  .dashboardOptions {
    flex-direction: row;
    gap: 20px;
    padding-bottom: 20px;
  }
}
