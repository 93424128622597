@import "../../../../css/variables";

.statsPieChartBox {
  margin: 0 auto;
}
.statsPieChartTopCoins {
  margin-top: 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .statsPieChart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .statsPieChartBox {
    flex-shrink: 0;
    margin: 0;
  }
  .statsPieChartTopCoins {
    margin-top: 0;
    margin-left: 50px;
  }
}
