@import "src/css/variables";

.newPagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.newPaginationButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #13171C;
  background-color: #ffffff;
  outline: none;
  border: 1px solid #D3D7DA;
  box-sizing: border-box;
  border-radius: 8px;
  margin-left: 10px;
  margin-top: 3px;
  cursor: pointer;
  transition: box-shadow ease .3s;
  &:first-child {
    margin-left: 0;
  }
}
.newPaginationButton:hover {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
.newPaginationButton:focus {
  box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.2);
}
.newPaginationButtonActive {
  border: 1px solid #64ACFF;
  cursor: initial;
  opacity: 1;
  pointer-events: none;
}
.newPaginationSplitter {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #13171C;
  margin: auto 0 0 12px;
  padding: 0 0 10px;
}

@media screen and (min-width: $screenMinWidthM) {
  .newPaginationButton {
    margin-left: 12px;
  }
}
