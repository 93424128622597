@import "../../../../css/variables.scss";

.planInfo {
  padding: 8px 5px;
  border: 1px solid transparent;
  border-radius: 8px;
}
.planInfoActive {
  border-color: rgba($colorBlue, .3);
  background-color: rgba($colorBlueLight, .1);
}
.planInfoContent {
  padding-top: 6px;
  padding-left: 22px;
}
.planInfoItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.planInfoKey {
  width: 110px;
  color: #A4A4A4;
  padding-right: 5px;
  flex-shrink: 0;
}
.planInfoValue {
  color: $colorTextBlack;
  word-break: break-word;
}
.planInfoValueFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
