@import "../../../../css/variables.scss";

.paymentsFullFilter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
}
label.searchInput {
  max-width: 320px;
  height: 44px;
}
.filterButton {
  flex-shrink: 0;
  margin-left: 16px;
}
.searchFilterDateBox {
  flex-shrink: 0;
  max-width: 0;
  opacity: 0;
  visibility: hidden;
  overflow-x: hidden;
  transition: all linear .2s;
}
.searchFilterDateBoxActive {
  max-width: 1000px;
  opacity: 1;
  visibility: visible;
  overflow-x: visible;
  margin-left: 16px;
}
.searchFilterDate {
  min-width: 280px;
}
div.searchFilter {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  border-radius: 0;
}
div.searchFilter [class*="paymentsFields"] {
  height: calc(100vh - 340px);
  overflow-y: auto;
}
button.exportButton {
  display: none;
}

@media screen and (min-width: $screenMinWidthM) {
  .paymentsFullFilter {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  div.searchFilter {
    position: static;
    background-color: transparent;
  }
  div.searchFilter form {
    margin-top: 16px;
  }
  div.searchFilter [class*="paymentsFields"] {
    height: auto;
    overflow-y: visible;
  }
  button.exportButton {
    display: block;
    width: 150px;
    font-size: 20px;
    margin-left: 16px;
  }
}
