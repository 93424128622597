.apiKeysOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.apiKeysOptions button {
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 8px 12px;
  background-color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #13171C;
}
.apiKeysOptions button:hover {
  background-color: #FAFAFA;
}
.apiKeysOptions button img {
  margin-right: 4px;
}
