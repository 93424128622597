@import "../../css/variables";

.posTerminalTitleH2 {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
}
.posTerminalList {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
  padding: 0 0 0 16px;
  margin: 16px 0 0;
}
.posTerminalListItem {
  margin-top: 10px;
}
.posTerminalListItem:first-child {
  margin-top: 0;
}
.posTerminalCreateBox {
  margin-top: 30px;
}
.posTerminalSelects {
  display: block;
  max-width: 1000px;
}
.posTerminalSelectApiKeys {
  margin: 0;
}
.posTerminalLink {
  margin-top: 15px;
}
.posTerminalLinkText {
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #000000;
}
.posTerminalInputBox {
  width: 100%;
  position: relative;
  z-index: 0;
  margin-top: 10px;
}
.posTerminalButtonCopy {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  z-index: 1;
}
.posTerminalButton {
  margin-top: 25px;
}
.posTerminalSuccess {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #64ACFF;
  margin-top: 5px;
}
.posTerminalSuccess img {
  margin-right: 4px;
}
.posTerminalError {
  display: block;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  color: #FF7DA0;
  margin-top: 5px;
}

@media screen and (min-width: $screenMinWidthS) {
  .posTerminalTitleH2 {
    font-size: 24px;
    line-height: 30px;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .posTerminalList {
    margin: 0;
  }
  .posTerminalSelects {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .posTerminalSelectThemes {
    margin: 0 0 0 16px;
  }
  .posTerminalLink {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .posTerminalLinkText {
    flex-shrink: 0;
  }
  .posTerminalInputBox {
    margin-top: 0;
    margin-left: 3px;
    max-width: 255px;
  }
  .posTerminalButton {
    max-width: 415px;
  }
  .posTerminalSuccess,
  .posTerminalError {
    margin-top: 0;
    margin-left: 10px;
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .posTerminalButton {
    width: 415px !important;
  }
}
