.filterButton {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  background: #ffffff;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  cursor: pointer;
  box-sizing: border-box;
}
.filterButton:hover svg {
  fill: #64ACFF;
}
.filterButton svg {
  width: 24px;
  height: 24px;
  display: block;
  transition: fill ease .3s;
}
.filterButtonActiveCount .filterButtonCount {
  visibility: visible;
  opacity: 1;
  right: -8px;
  transform: rotate(0deg);
}
.filterButtonCount {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #ffffff;
  background-color: #64ACFF;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -16px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: rotate(90deg);
  transition: all ease .3s;
}
