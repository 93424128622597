@import "src/css/variables";

.historyTableBox {
  width: calc(100% + 50px);
  background-color: #ffffff;
  margin-left: -25px;
  margin-right: -25px;
  margin-top: 20px;
  padding: 25px 10px;
  overflow-x: auto;
  box-sizing: border-box;
}
.historyTableBox * {
  box-sizing: border-box;
}
.historyTable {
  width: 100%;
  min-width: 760px;
  border-collapse: separate;
  border-spacing: 0 3px;
}
.historyTableTh {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.6px;
  color: #7E7E7E;
  border-bottom: 1px solid #eeeeee;
  padding-right: 5px;
  padding-bottom: 15px;
}
.historyTableTh:first-child {
  position: relative;
  border-color: transparent;
  padding-left: 15px;
}

.historyTableTh:last-child {
  position: relative;
  border-color: transparent;
  padding-right: 15px;
}
.historyTableTh:first-child:before,
.historyTableTh:last-child:after {
  content: "";
  width: calc(100% - 15px);
  height: 1px;
  background-color: #eeeeee;
  position: absolute;
  bottom: -1px;
}
.historyTableTh:first-child:before {
  right: 0;
}
.historyTableTh:last-child:after {
  left: 0;
}
.historyTableThImg {
  margin-left: 4px;
  margin-bottom: -2px;
  cursor: pointer;
}
.historyTableBodyTr {
  margin-left: -15px;
  margin-right: -15px;
}
.historyTableTbody tr {
  height: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A4A4A4;
  background-color: transparent;
  border-radius: 4px;
}
.historyTableTbody tr:hover {
  background-color: #ECF5FF;
  cursor: pointer;
}
.historyTableTbody td {
  padding: 8px 10px 8px 0;
}
.historyTableTbody td:first-child {
  padding-left: 15px;
  border-radius: 4px 0 0 4px;
}
.historyTableTbody td:last-child {
  padding-right: 15px;
  border-radius: 0 4px 4px 0;
}
.historyTableTbody [data-margin="top"] {
  margin-top: 4px;
}
.historyTableTbody [data-color="black"] {
  color: #13171C;
}
.historyTableTbody [data-color="green"] {
  color: #61BC6A;
}
.historyTableTbody [data-color="pink"] {
  color: #FF7DA0;
}
.historyTableTbody [data-overflow="hidden"] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.historyTableTbody [data-text="capitalize"] {
  text-transform: capitalize;
}
tr.historyTableLoaderTr:hover,
tr.historyTableEmptyTr:hover,
tr.historyTableError:hover {
  background-color: transparent;
  cursor: default;
}
.historyTableLoaderTr td {
  padding: 25px 0 15px 15px;
}
.historyTableEmptyTr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #A4A4A4;
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 15px;
}
.historyTableError td {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FF7DA0;
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 15px;
}
.historyTableError td a {
  font-size: 14px;
  line-height: 17px;
}

@media screen and (min-width: $screenMinWidthM) {
  .historyTableLoaderTr {
    text-align: center;
    padding-left: 0;
  }
  .historyTableEmptyTr td,
  .historyTableError td {
    text-align: center;
    padding-left: 0;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .historyTableEmptyTr td,
  .historyTableError td {
    font-size: 16px;
    line-height: 20px;
  }
  .historyTableError td a {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-width: 1400px) {
  .historyTableTh {
    font-size: 16px;
  }
}
