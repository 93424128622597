@import "../../../../css/variables.scss";

.ipnPopup {
  padding: 24px 16px;
}
.ipnPopupContent {
  height: calc(100vh - 119px);
  display: flex;
  flex-direction: column;
}
.ipnPopupContent > p {
  font-size: 16px;
  line-height: 24px;
  color: #13171C;
}
.ipnPopupButton {
  height: 48px;
  border-radius: 8px;
  font-size: 18px;
  line-height: 23px;
}
.ipnPopupButton:last-of-type {
  margin-top: 12px;
}
.ipnPopupButton:first-of-type {
  margin-top: auto;
}

@media screen and (min-width: $screenMinWidthM) {
  .ipnPopup {
    max-width: 424px;
    padding: 32px;
  }
  .ipnPopupContent {
    height: 306px;
  }
}
