@import "../../../../css/variables.scss";

.massPayoutsFullFilter {
  margin-top: 24px;
}
.massPayoutsFilter {
  position: static;
  padding: 0;
}
.exportButton {
  margin-top: 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .exportButton {
    width: 140px;
    margin-top: 0;
    margin-left: auto;
  }
}
