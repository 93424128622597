.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  background-color: #fff;
  text-align: center;
  color: #13171c;
  padding: 10px 0;
  margin: auto;

  .title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 15px;
    margin-top: 0;
  }

  .desc {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .inputBlock {
    width: 100%;

    .inputField {
      width: 100%;
      height: 50px;
      background: #eceff1;
      border: none;
      font-size: 24px;
      padding: 0 18px;
      box-sizing: border-box;

      &::placeholder {
        font-size: 14px;
      }
    }
  }

  .captchaBlock {
    margin-bottom: 20px;
  }

  .buttonsBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    .cancelBtn {
      margin-right: 15px;
      background-color: #d2d2d2;
    }
  }
}
.inputFieldError {
  min-height: 20px;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: #ff7da0;
  margin: 5px 0;
}
