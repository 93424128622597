@import "../../../../css/variables";

.popup {
  width: 100%;
  max-width: 700px;
  padding: 30px 16px;
}
.button {
  height: 40px;
  margin-top: 20px;
}

@media screen and (min-width: $screenMinWidthM) {
  .popup {
    padding: 30px;
  }
  .button {
    width: 180px;
    margin: 40px auto 0;
  }
}
