@import "../../../../../../css/variables";

.onboardingStepsIcons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.onboardingStepsIconsLine {
  width: 14%;
  height: 1px;
  display: block;
  background-color: #64ACFF;
}
.onboardingStepsIconsLineLong {
  width: 22%;
}

@media screen and (min-width: $screenMinWidthM) {
  .onboardingStepsIconsLine {
    width: 15%;
  }
  .onboardingStepsIconsLineLong {
    width: 25%;
  }
}
