@import "../../../../css/variables.scss";

.planFilterButton {
  height: 44px;
}
.planFilterSearch {
  height: 44px;
  margin-top: 24px;
}

@media screen and (min-width: $screenMinWidthM) {
  .planFilter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .planFilterButton {
    width: 115px;
  }
  .planFilterSearch {
    max-width: 320px;
    margin-top: 0;
  }
}
