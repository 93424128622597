@import "../../css/variables.scss";

.confirmModal {
  max-width: 600px;
  font-size: 15px;
  font-weight: 400;
}

.buttonsRow {
  display: flex;
  justify-content: flex-end;
  > button {
    margin-right: 16px;
  }
}
