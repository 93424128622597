@import "../../../../css/variables";

.dashboardProductCard {
  box-sizing: border-box;
  padding: 16px;
  border: 1px solid #EAEFF4;
  border-radius: 8px;
  background-color: #FFFFFF;
}
.dashboardProductCardSmall {
  height: 350px;
}
.dashboardProductCardSmall .dashboardProductCardContent {
  display: flex;
  flex-direction: column;
  height: 198px;
}
.dashboardProductCardSmall .cardButton {
  height: 40px;
  margin-top: auto;
}
.dashboardProductCardSmall .cardIcons {
  flex-wrap: nowrap;
  width: 340px;
  height: 60px;
  padding: 12px;
  margin-left: -10px;
  overflow-x: scroll;
  overflow-y: visible;
}
.dashboardProductCardSmall .cardIcons a img {
  max-height: 40px;
}
.dashboardProductCardSmall .cardIcons a:hover {
  transform: scale(1.1);
}
.dashboardProductCardImage {
  width: 100%;
  max-width: 120px;
}
.cardTitle {
  margin: 16px 0 4px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}
.cardDescription {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.cardDescription ul {
  margin: 0;
  padding-left: 30px;
}
.cardButton {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  &:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    margin-left: 12px;
    border: 2px solid #64ACFF;
    border-left: 0;
    border-top: 0;
    transform: rotate(-45deg);
  }
}
.dashboardProductCardImageContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
}
.dashboardProductCardApi {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px 10px 4px;
  border-radius: 4px;
  background-color: #DAFF7C;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  color: #13171C;
  text-align: center;
  transition: all 0.3s ease;

  &:before {
    content: "";
    display: block;
    width: 16px;
    height: 10px;
    margin-right: 7px;
    background-image: url("../../../../../public/images/dashboard-page/code-fragment.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.dashboardProductCardApi:hover {
  background-color: #C2F63E;
}
.dashboardProductCardApi:active {
  background-color: #B8E150;
}
.cardIcons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 18px;
}
.cardIcons a {
  transition: transform ease 0.3s;
}
.cardIcons a:hover {
  transform: scale(1.2);
}

@media screen and (min-width: $screenMinWidthXXS) {
  .dashboardProductCardApi {
    display: block;
    padding: 4px 12px;
    font-size: 14px;

    &:before {
      display: inline-block;
    }
  }
}

@media screen and (min-width: $screenMinWidthL) {
  .dashboardProductCard:not(.dashboardProductCardSmall) {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .dashboardProductCard:not(.dashboardProductCardSmall) .dashboardProductCardImage {
    max-width: 134px;
  }
  .dashboardProductCard:not(.dashboardProductCardSmall) .cardTitle {
    margin-top: 0;
  }
  .dashboardProductCard:not(.dashboardProductCardSmall) .cardButton {
    display: inline-flex;
    height: 32px;
    padding-right: 25px;
  }
  .dashboardProductCard:not(.dashboardProductCardSmall) .dashboardProductCardImageContainer {
    display: block;
    min-width: 134px;
  }
  .dashboardProductCard:not(.dashboardProductCardSmall) .dashboardProductCardApi {
    position: absolute;
    right: 8px;
    top: -8px;
  }
  .dashboardProductCard:not(.dashboardProductCardSmall) .cardIcons {
    justify-content: flex-start;
    gap: 20px;
  }
  .dashboardProductCard:not(.dashboardProductCardSmall) .cardIcons img {
    max-height: 32px;
  }
}
