@import "../../../../css/variables.scss";

.popupUnsubscribe {
  display: flex;
  flex-direction: column;
}
.error {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 35px;
  font-size: 14px;
  line-height: 16px;
  color: #ff7da0;
  text-align: center;
  margin-top: auto;
}
.buttonUnsubscribe {
  width: 100%;
  background-color: #FF7DA0;
  border: 0;
  color: #ffffff;
  margin-top: 5px;
}
.buttonUnsubscribe:hover {
  background-color: #FF7DA0;
  border: 0;
  color: #ffffff;
}
.buttonCancel {
  width: 100%;
  margin-top: 16px;
}

@media screen and (min-width: $screenMinWidthM) {
  .popupUnsubscribe {
    display: block;
  }
  .error {
    height: 132px;
    margin-top: 30px;
  }
}

@media screen and (min-width: $screenMinWidthXXL) {
  .error {
    margin-top: 128px;
  }
}
