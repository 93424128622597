@import "../../../../css/variables.scss";

.pagesWrapper * {
  box-sizing: border-box;
}
h1.title {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
}
.loader {
  display: block;
  margin: 0 auto;
}
.infoBlock {
  margin-top: -16px;
  margin-bottom: 32px;
}
.content {
  margin-top: 24px;
}

@media screen and (min-width: $screenMinWidthM) {
  h1.title {
    font-size: 32px;
    line-height: 40px;
  }
}
