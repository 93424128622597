@import "../../../../css/variables";

.paymentDetailsCurrencySelect [class*='currencyCoinImage_'] {
  width: 36px;
  height: 36px;
}
.paymentDetailsCurrencySelect [class*='currencyCoinTicker_'],
.preferredCurrencies .paymentDetailsCurrencySelect [class*='currencyPlaceholder_'] {
  font-size: 18px;
  line-height: 24px;
}
.paymentDetailsCurrencySelect [class*='currenciesSelectItemTitle_'] {
  display: none;
}
.preferredCurrencies {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 12px;
}
.paymentDetailsCurrencySelect [class*='currenciesSelectFieldLabel_'] {
  line-height: 24px;
}
.paymentDetailsCurrencySelect [class*='currenciesSelectSmallTheme_'] {
  height: 56px;
}
.paymentDetailsCurrencySelect [class*='currenciesSelectItem_'] {
  min-height: 44px;
}
.paymentDetailsCurrencySelect [class*='currenciesSelectBody_'] {
  z-index: 2;
}
.selectLabel {
  margin-bottom: 12px;
}
.preferredCurrency {
  position: relative;
}
.clearButton {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  height: 24px;
  padding: 0;
  background-color: transparent;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #64ACFF;
}

@media screen and (min-width: $screenMinWidthL) {
  .preferredCurrencies {
    flex-direction: row;
  }
  .preferredCurrency {
    width: 50%;
  }
}
