@import "../../../../css/variables";

.title {
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 30px;
  font-weight: 400;
}

.subtitle {
  margin-bottom: 9px;
  font-size: 20px;
  line-height: 22px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #EEEEEE;
  margin: 15px 0px;
}

.formHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-family: $fontSecondary;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 28px;

  .blueText {
    color: $colorBlue;
  }
}

.link {
  padding: 16px 0 0;
  width: 100%;
  display: inline-block;
  border-top: 1px solid #EEEEEE;
  color: $colorBlue;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  text-align: center;
}

.teamList {
  margin-bottom: 70px;
  padding: 20px 18px;
  border: 2px solid $colorBlue;
  min-height: 327px;

  .teamItem {
    min-height: 44px;
    list-style: none;
    border: 1px solid #64acff;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 30px 10px 10px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;

    .arrowIcon {
      opacity: 0;
      transition: opacity 0.1s ease-in;
      position: absolute;
      right: 10px;
      top: 12px;
    }

    &:hover {
      .arrowIcon {
        opacity: 1;
      }
    }
  }
}
