.popup {
  max-width: 480px;
  padding: 30px;
}
.notification {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.notification a {
  font-size: 16px;
  line-height: 20px;
}
.notification button {
  width: 200px;
  margin: 20px auto 0;
}
