@import "src/css/variables";

.linksTableBox {
  width: 100%;
  max-height: 70vh;
  background-color: #ffffff;
  overflow: auto;
  box-sizing: border-box;
}
.linksTable {
  width: 100%;
  min-width: 500px;
  border-collapse: separate;
  border-spacing: 0 3px;
}
.linksTableThead th {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.6px;
  color: #7E7E7E;
  border-bottom: 1px solid #eeeeee;
  padding-right: 5px;
  padding-bottom: 15px;
}
.linksTableThead th:first-child {
  min-width: 115px;
  padding-left: 3px;
}
.linksTableThead th:nth-child(2) {
  min-width: 135px;
}
.linksTableThead th:nth-child(3) {
  min-width: 150px;
}
.linksTableThead th:nth-child(4) {
  min-width: 170px;
}
.linksTableThead th:nth-child(5) {
  min-width: 170px;
}
.linksTableThead th:last-child {
  min-width: 100px;
  padding-right: 3px;
}
.linksTableTbody:before {
  content: '';
  display: table-row;
  height: 15px;
}
.emptyText {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A4A4A4;
  text-align: center;
}

@media screen and (min-width: $screenMinWidthL) {
  .linksTableBox {
    max-height: 600px;
  }
}
@media screen and (min-width: $screenMinWidthXL) {
  .emptyText {
    font-size: 16px;
    line-height: 20px;
  }
  .linksTableLoaderTr {
    text-align: center;
  }
}

@media screen and (min-width: 1400px) {
  .linksTableThead th {
    font-size: 16px;
  }
}
