@import "../../css/variables";

.addNewAccountFormSubmitSection {
  position: absolute;
  bottom: 32px;
  width: calc(100% - 64px);
}
.addNewAccountFormSelect div[role=button] div div {
  margin-top: 0;
  font-size: 16px;
}
.addNewAccountFormTitle {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #13171C;
}
.addNewAccountFormInputsSection > div:not(:last-child){
  margin-bottom: 16px;
}
.addNewAccountFormSelect > [class*="select_"],
.addNewAccountFormInput input {
  height: 48px;
}
.addNewAccountFormSelect [class*="selectFieldLabel"],
.addNewAccountFormSelect > [class*="selectFieldError"] div,
.addNewAccountFormSelect [class*="selectFieldErrorText"],
.addNewAccountFormInput [class*="fieldTitle"],
.addNewAccountFormInput > [class*="error"] div {
  font-size: 16px;
}
.addNewAccountFormSelect [class*="selectFieldErrorText"] {
  padding-top: 0;
  line-height: 18px;
}
.addNewAccountFormSelect [class*="item_"] {
  display: flex;
}
.addNewAccountFormSelect [class*="itemsSelect_"] {
  position: static;
  margin-left: 5px;
}
.addNewAccountFormSelect [class*="itemContent"],
.addNewAccountFormSelect [class*="itemPlaceholderText"] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.addNewAccountFormInput {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: auto auto;
}
.addNewAccountFormInput > [class*="fieldTitle"] {
  order: 1;
  grid-area: 1 / 1 / 2 / 2;
}
.addNewAccountFormInput > [class*="error"] {
  position: static;
  order: 2;
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  justify-content: flex-end;
}
.addNewAccountFormInput > [class*="fieldBox"] {
  order: 3;
  grid-area: 2 / 1 / 3 / 3;
}
.addNewAccountFormButton {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  font-size: 18px;
}
.addNewAccountFormCheckbox {
  margin-top: 24px;
  margin-bottom: 16px;
}
.addNewAccountFormCheckbox [class*="checkboxNewBox"] {
  width: 20px;
  height: 20px;
}
.addNewAccountFormCheckbox [class*="checkboxNewInput"]:checked + [class*="checkboxNewBox"] {
  background-repeat: no-repeat;
  background-size: cover;
}
.addNewAccountFormCheckbox span {
  margin-left: 12px;
  color: #13171C;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.addNewAccountFormError {
  margin-bottom: 16px;
  color: #FF7DA0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}
.addNewAccountFormBackButton,
.addNewAccountFormBackInfo {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #F5F7FB;
}
.addNewAccountFormBackButton:hover {
  padding: 0 19px;
  border: 1px solid #64ACFF;
}
.addNewAccountFormBackInfo {
  cursor: default;
  pointer-events: none;
}
.addNewAccountFormBackCurrency {
  margin-left: 8px;
  color: #13171C;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.addNewAccountFormBackArrow {
  &:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    margin-right: 12px;
    border: 2px solid #64ACFF;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(-45deg);
  }
}
.addNewAccountFormHeader {
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 24px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background: #F4F4F4;
  }
}
.addNewAccountFormHeaderButton {
  background-color: unset;
  padding: 0 20px 0 0;
}
.addNewAccountFormHeaderBackArrow {
  &:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border: 2px solid #2D3748;
    border-right: 0;
    border-bottom: 0;
    transform: rotate(-45deg);
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .addNewAccountFormContent {
    min-height: 504px;
  }
}
