@import "../../css/variables.scss";

.popup {
  max-width: 450px;
}

@media screen and (min-width: $screenMinWidthM) {
  .popup {
    padding: 30px;
  }
}
