.depositAddressTopUp {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.depositAddressTopUpText {
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #13171C;
}
.depositAddressTopUpCurrency {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #13171C;
}
.depositAddressTopUpCopy {
  align-items: center;
  margin: 16px 0 14px;
  padding: 12px;
  border-radius: 8px;
  background-color: #F8F8F8;
}
.depositAddressTopUpCopy div,
.depositAddressTopUpCopy img {
  width: 22px;
  height: 22px;
}
.depositAddressTopUpMinAmount {
  text-align: center;
  line-height: 20px;
}
.depositAddressTopUpMinAmount span {
  color: #7E7E7E;
}
