.currenciesDropdownInnerMenu {
  display: flex;
  gap: 12px;
  margin-top: 16px;
}
.currenciesDropdownInnerMenuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 4px 0;
  color: #1B2125;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  transition: none;

  &:focus {
    background: #64ACFF;
    color: #FFFFFF;
  }
}
.currenciesDropdownInnerMenuItemActive {
  background: #64ACFF;
  color: #FFFFFF;
  font-weight: 600;
}
.currenciesDropdownInnerMenuItemNew:after {
  content: "NEW";
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 13px;
  margin-left: 4px;
  margin-top: 2px;
  background-color: #64ACFF;
  border-radius: 2px;
  color: #FFF;
  font-size: 10px;
  font-weight: 600;
  line-height: 10px;
  letter-spacing: 0.1px;
}
.currenciesDropdownInnerMenuItemActive:after {
  background-color: #FFF;
  color: #64ACFF;
}
