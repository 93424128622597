@import "../../../css/variables";

.buttonWithIcon {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  height: auto;
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
  color: #13171C;
  background-color: transparent;
  border: 0;
  padding: 0;
  outline: 0;
  cursor: pointer;
}
.buttonWithIconBoxImg {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #ecf5ff;
  border-radius: 4px;
}
.buttonWithIconText {
  margin-left: 8px;
}

@media screen and (min-width: $screenMinWidthM) {
  .buttonWithIconBoxImg {
    width: 24px;
    height: 24px;
  }
}
