@import "../../../../css/variables.scss";

.customersTableBox {
  overflow-x: auto;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 24px;
}
.customersTable {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  overflow: visible;
  min-width: 500px;
  border-radius: 8px;
  margin-top: 0;
}
.customersTable table[class*="historyTable"] {
  min-width: auto;
}
.customersTable tbody:before {
  content: '';
  display: table-row;
  height: 15px;
}
.customersTable th,
.customersTable td {
  width: 30%;
}
.customersTable th:nth-child(2),
.customersTable td:nth-child(2) {
  width: 35%;
}
.customersTableButton {
  margin-top: 16px;
  width: 150px;
}
.paginator {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}

@media screen and (min-width: 480px) {
  .customersTable [class*="historyTableEmptyTr"],
  .customersTable [class*="historyTableLoaderTr"] {
    text-align: center;
  }
  .customersTableButton {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: $screenMinWidthXL) {
  .customersTable th:nth-child(1),
  .customersTable td:nth-child(1),
  .customersTable th:nth-child(4),
  .customersTable td:nth-child(4) {
    width: 20%;
  }
  .customersTable th:nth-child(2),
  .customersTable td:nth-child(2) {
    width: 25%;
  }
  .customersTable th:nth-child(3),
  .customersTable td:nth-child(3) {
    width: 35%;
  }
}
