@import "../../../../css/variables.scss";

.payoutWalletsTable {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
}
.payoutWalletsTableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #A4A4A4;
  padding: 16px 16px 12px;
  border-bottom: 1px solid #eeeeee;
}
.payoutWalletsTableBody {
  padding: 12px 8px;
}
.payoutWalletsTablePagination {
  margin-top: 12px;
}
.payoutWalletsTablePagination [class*='newPaginationButton'] {
  background-color: transparent;
  border: none;
}
.payoutWalletsTablePagination [class*='newPaginationButtonActive'] {
  border: 1px solid #64ACFF;
}
.payoutWalletsTable .payoutWalletsTableTooltip {
  z-index: 1;
  padding: 4px 0;
}
.payoutWalletsTable .payoutWalletsTableTooltipMain {
  z-index: 1;
  max-width: 248px;
  padding: 4px 8px;
  border: 1px solid #64ACFF;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.payoutWalletsTableTooltipMain ul {
  margin: 0;
  padding-left: 20px;
}
.payoutWalletsTable [class*='emptySearch_'] {
  min-height: 218px;
}
.payoutWalletsTable [class*='emptySearchText'] {
  width: 100%;
}
.payoutWalletsTableAdd {
  width: 100%;
  margin-top: 16px;
}
.payoutWalletsTableBodyLoader {
  text-align: center;
}

@media screen and (min-width: $screenMinWidthL) {
  .payoutWalletsTableHeader {
    justify-content: flex-start;
  }
  .payoutWalletsTableTd {
    position: relative;
    &:first-child {
      width: 152px;
      margin-right: 20px;
      flex-shrink: 0;
    }
    &:nth-child(2) {
      flex-grow: 1;
    }
    &:last-child {
      flex-shrink: 0;
    }
  }
  .payoutWalletsTable [class*='emptySearchText'] {
    width: auto;
  }
  .payoutWalletsTableAdd {
    width: 152px;
    margin: 16px auto 0;
  }
}
