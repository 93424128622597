.lineDelimiter {
  border-bottom: 1px solid #cecece;
  margin: 15px 40px;
}
.dFlex {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.selfCenter {
  align-self: center;
}
.justifyCenter {
  justify-content: center;
}
.justifyBetween {
  justify-content: space-between;
}
.fs20 {
  font-size: 20px;
}
.fs16 {
  font-size: 16px;
}
.fs18 {
  font-size: 18px;
}
.fwBold {
  font-weight: bold;
}
.wordBreak {
  word-break: break-all;
}
.mt10 {
  margin-top: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb10 {
  margin-bottom: 10px;
}
.ml10 {
  margin-left: 10px;
}
.breakAll {
  word-break: break-all;
}
.finished {
  color: #1bdc66;
}
.expired {
  color: #2f80ed;
}
.refunded {
  color: #2df4e8;
}
.partially_paid {
  color: #9b51e0;
}
.waiting {
  color: #f1b44e;
}
.failed {
  color: #dc1d2e;
}
.sending {
  color: #38a99b;
}
.confirming {
  color: #bf39b2;
}
.confirmed {
  color: #fe5b00;
}
.link {
  color: #64acff;
}
.qrCode {
  margin-left: 10px;
}
.displayInline {
  display: inline;
}
.priceAmount,
.payAmount {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

@media all and (min-width: 768px) {
  .qrCode {
    margin-left: 30px;
  }
}
