@import "../../../css/variables";

.tableTabs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 40px;
}
.tableTab {
  max-width: 100px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #A4A4A4;
  margin-left: 16px;
  cursor: pointer;
  transition: all ease .2s;
}
.tableTab:first-child {
  margin-left: 0;
}
.tableTabActive {
  font-size: 20px;
  line-height: 20px;
  color: #13171C;
  pointer-events: none;
}
@media screen and (min-width: 380px) {
  .tableTabs {
    height: 25px;
  }
  .tableTab {
    max-width: none;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .tableTab {
    font-size: 20px;
    line-height: 20px;
    margin-left: 24px;
  }
  .tableTabActive {
    font-size: 24px;
    line-height: 24px;
  }
}
