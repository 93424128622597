@import "../../../../css/variables.scss";

.apiKeyData {
  position: relative;
  box-sizing: border-box;
  min-height: 144px;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #FAFAFA;
}
.apiKeyDataLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.apiKeyDataBlock {
  position: relative;
  padding: 6px 0;

  &:first-child {
    margin-bottom: 8px;
  }
}
.apiKeyDataTitle {
  font-size: 14px;
  line-height: 20px;
  color: #7E7E7E;
}
.apiKeyDataCopy {
  position: static;
}
.apiKeyDataCopy span {
  display: block;
  width: calc(100% - 32px);
  font-size: 16px;
  line-height: 24px;
  color: #13171C;
}
.apiKeyDataCopy > div {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
}
.apiKeyDataCopy > div > img {
  width: 32px;
  height: 32px;
  transition: all 0.3s ease;
}
.apiKeyDataCopy:hover img {
  transform: scale(1.1);
}
.apiKeyDataCopy [class*="copyButtonImgSuccess"] {
  animation: copy-button;
  animation-duration: 1s;
}
.apiKeyComment {
  margin-top: 24px;
}
.apiKeyCommentTitle {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  color: #13171C;
}
.apiKeyCommentTitleError {
  color: #FF7DA0;
}
.apiKeyTextarea {
  width: 100%;
  height: 120px;
  padding: 8px 16px;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  background-color: #ffffff;
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #13171C;
}
.apiKeyTextarea::placeholder {
  font-family: Source Sans Pro, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #A4A4A4;
}
.editApiKeyButton:last-child {
  margin-top: 16px;
}
.editApiKeyError {
  margin-top: auto;
  min-height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FF7DA0;
  text-align: center;
}

@keyframes copy-button {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .editApiKeyError {
    margin-top: 4px;
  }
}
