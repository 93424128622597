.buttonBack {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 4px;
  transition: box-shadow ease .3s;
}
.buttonBack:hover {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}
.buttonBack:before {
  content: "";
  width: 8px;
  height: 8px;
  border: 2px solid #2D3748;
  border-top: 0;
  border-right: 0;
  transform: rotate(45deg);
  position: relative;
  left: 2px;
}
