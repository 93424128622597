@import "../../../../css/variables";

.autoProcessingPopup {
  display: flex;
  flex-direction: column;
}
.autoProcessingPopup p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.autoProcessingPopup [class*='permissionProceed_'] {
  margin-top: auto;
}

@media screen and (min-width: $screenMinWidthM) {
  .autoProcessingPopup {
    display: block;
  }
  .autoProcessingPopup [class*='permissionProceed_'] {
    margin-top: 126px;
  }
}
