@import "../../../../css/variables.scss";

.customerDepositOrWithdrawForm {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.error {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 35px;
  font-size: 14px;
  line-height: 16px;
  color: #FF7DA0;
  text-align: center;
  margin-top: auto;
}
.button {
  margin-top: 5px;
}
.radioButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

@media screen and (min-width: $screenMinWidthXS) {
  .radioButtons {
    justify-content: flex-start;
  }
  .radioButton {
    width: 50%;
  }
}

@media screen and (min-width: $screenMinWidthM) {
  .customerDepositOrWithdrawForm {
    display: block;
  }
  .error {
    min-height: 30px;
    margin-top: 5px;
  }
  .button {
    width: 100%;
  }
}
