.infoCard {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #13171C;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 6px 15px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
}
.infoCard a {
  color: #64ACFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
}
