.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .title {
    font-weight: 600;
    font-size: 36px;
    color: #13171c;
    margin: 0 0 32px 0;
  }
}
