.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;
}
.header h1 {
  margin-bottom: 0;
}
.image {
  display: block;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  margin-top: 6px;
  cursor: pointer;
}
