@import "../../../css/variables.scss";

.accordion {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #2B2B36;
  border-top: 1px solid #E5E5E9;

  &:last-child {
    border-bottom: 1px solid #E5E5E9;
  }
}
.accordionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  padding: 16px 25px 16px 0;
  outline: none;
  cursor: pointer;

  &:focus-visible {
    box-shadow: 0 0 5px 1px #64ACFF;
    border-radius: 8px;
  }
}
.accordionButton {
  position: absolute;
  top: 20px;
  right: 0;
  display: block;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  transition: all .2s ease-in-out;
}
.accordionButton:before,
.accordionButton:after {
  content: '';
  display: block;
  background: #64ACFF;
  position: absolute;
  width: 100%;
  height: 1px;
  top: 6px;
  left: 0;
}
.accordionButton:after {
  transform: rotate(90deg);
}
.accordionContent {
  display: block;
  max-width: 842px;
  max-height: 0;
  opacity: 0;
  font-weight: 300;
  line-height: 24px;
  margin: 0;
  transition: all .3s ease-in-out;
  overflow: hidden;
}
.accordionContent p {
  font-weight: 300;
}
.accordionContent strong {
  font-weight: 600;
}
.accordionContent ul,
.accordionContent ol {
  margin: 0;
  padding-left: 25px;
}
.accordionContent a {
  color: #64ACFF;
  font-weight: 600;
  font-size: 20px;
}
.accordionContent u {
  color: #64ACFF;
}
.accordionOpen .accordionButton {
  transform: rotate(45deg);
}
.accordionOpen .accordionContent {
  max-height: 800px;
  opacity: 1;
  padding-bottom: 16px;
}

@media screen and (min-width: $screenMinWidthL) {
  .accordion {
    font-size: 20px;
    line-height: 24px;
  }
  .accordionContent {
    line-height: 28px;
  }
}
