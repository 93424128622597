@import "../../css/variables";

.section h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}
.description {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.button {
  margin-top: 24px;
}

@media screen and (min-width: $screenMinWidthM) {
  .description {
    font-size: 18px;
    line-height: 22px;
  }
}
