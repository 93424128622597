.currenciesItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  outline: 0;
  padding: 5px 0;
}
.currenciesItemImage {
  flex-shrink: 0;
  margin-right: 10px;
}
.currenciesItemCoin {
  flex-grow: 1;
}
.currenciesItemTicker {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #13171C;
  text-transform: uppercase;
}
.currenciesItemNetwork {
  margin-left: 6px;
}
.currenciesItemName {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #A4A4A4;
}
