.currencySup {
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #ffffff;
  text-transform: initial;
  border-radius: 2px;
  background-color: #B4BFCE;
  padding: 1px 8px;
}
.currencySupBlinking {
  position: relative;
  overflow: hidden;
}
.currencySupBlinking:after {
  content: "";
  display: block;
  width: 20%;
  height: 150%;
  background: linear-gradient(90deg,rgba(255, 255, 255, 0.5) 10%, rgba(255,255,255,0.7) 50%, rgba(255, 255, 255, 0.5) 90%);
  z-index: 1;
  transform: rotate(30deg);
  position: absolute;
  top: -5px;
  animation: slideBlink 2s ease-in-out infinite;
}
@keyframes slideBlink {
  0% {
    left: -20%;
  }
  20% {
    left: 120%;
  }
  100% {
    left: 120%;
  }
}

