.googleAuthButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #64ACFF;
  background-color: #ffffff;
  border: 1px solid #64ACFF;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 16px;
  transition: background ease .3s;
}
.googleAuthButton:hover {
  background-color: rgba(#73b1fa, .1);
}
.googleAuthButton:disabled {
  opacity: 0.7;
  pointer-events: none;
}
.googleAuthButtonImg {
  display: block;
  margin-right: 10px;
}
.googleAuthButtonError {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #FF7DA0;
  margin-top: 4px;
}
