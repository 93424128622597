@import "../../../../css/variables.scss";

.customerPopup {
  display: flex;
  flex-direction: column;
}
.saveBillingPlanForm {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 175px);
}

@media screen and (min-width: $screenMinWidthM) {
  .customerPopup {
    display: block;
  }
  .saveBillingPlanForm {
    display: block;
    height: auto;
  }
}
