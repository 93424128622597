@import "../../../../css/variables.scss";

.whitelistActivation {
  min-height: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 8px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #EEEEEE;
  padding: 8px 16px 8px 16px;
  box-sizing: border-box;
}
.whitelistActivation img {
  display: block;
}
.whitelistActivationInfoIcon {
  margin-left: auto;
}
.whitelistActivationText {
  margin-right: auto;
}
.whitelistActivationLink {
  margin-left: 32px;
}

@media screen and (min-width: $screenMinWidthL) {
  .whitelistActivationHover {
    transition: border ease .3s;
    &:hover {
      border-color: #64ACFF;
      cursor: pointer;
    }
  }
  .whitelistActivationHover .whitelistActivationInfoIcon {
    transition: transform ease .3s;
    transform: scale(1.1);
  }
  .whitelistActivationHover:hover .whitelistActivationInfoIcon {
    transform: scale(1.4);
  }
}
