@import "../../css/variables.scss";

.payoutWalletsSettingsTitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.payoutWalletsSettingsSuccessPopup {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
}
.payoutWalletsSettingsSuccessPopup [class*="title"] {
  margin-top: 16px;
  font-size: 24px;
  line-height: 30px;
}
.payoutWalletsSettingsSuccessPopup [class*="description"] {
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
}
.payoutWalletsSettingsSuccessPopup [class*="icon"] {
  margin-top: 14vh;
}
.payoutWalletsSettingsSuccessPopup button {
  height: 48px;
  width: 100%;
  margin-top: auto;
  border-radius: 8px;
  font-size: 20px;
  line-height: 28px;
}

@media screen and (min-width: $screenMinWidthM) {
  .payoutWalletsSettingsSuccessPopup {
    display: block;
    max-width: 424px;
    padding: 32px;
  }
  .payoutWalletsSettingsSuccessPopup [class*="icon"] {
    margin-top: 0;
  }
}
