.radio {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #000000;
  cursor: pointer;
}
.radio input[type="radio"] {
  display: none;
}
.radioBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 1px solid #D2D2D2;
  border-radius: 50%;
  margin-right: 5px;
  transition: border-color ease .3s;
}
.radioBox:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #64ACFF;
  border-radius: 50%;
  transform: scale(0);
  transition: transform ease .3s;
}
.radio input:checked + .radioBox {
  border-color: #64ACFF;
}
.radio input:checked + .radioBox:before {
  transform: scale(1);
}
