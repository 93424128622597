@import "../../../css/variables";

.linkContainer {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 8px;
}

a {
  color: $colorBlue;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.link {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.footer {
  position: absolute;
  width: calc(100% - 50px);
  bottom: 25px;
}

.containerInput {
  margin-bottom: 8px;
}

.inputContainer {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 30px;

  .resetError {
    margin: 20px 0;
  }
}
.inputContainerPadding input {
  padding-right: 17px;
}

.dividerContent {
  margin: 30px 0 20px;
}
.footerLine {
  width: 100%;
  height: 1px;
  background-color: #EEEEEE;
  margin: 15px 0;
}
.skip {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #A4A4A4;
  text-align: center;
}
.skipLink {
  color: #64ACFF;
  cursor: pointer;
}

@media screen and (max-width: 374px) {
  .title {
    font-size: 18px !important;
  }
  .description {
    font-size: 16px !important;
  }
}
