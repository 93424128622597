.copyText {
  position: relative;
  display: flex;
  align-items: flex-start;
  vertical-align: top;
  word-break: break-all;
}
.copyText span {
  cursor: pointer;
  padding-right: 8px;
}
.copyTextButton {
  position: relative;
  top: 2px;
  display: inline-flex;
  outline: 0;
}
.copyTextEllipsis {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.copyTextEllipsis span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.copyTextEllipsis .copyTextButton {
  top: 0;
}
