@import "src/css/variables";

.item {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #A4A4A4;
  background-color: transparent;
}
.item td {
  padding: 10px 5px 10px 0;
}
.item td:first-child {
  min-width: 115px;
  padding-left: 3px;
  border-radius: 4px 0 0 4px;
}
.item td:nth-child(2) {
  min-width: 135px;
}
.item td:nth-child(3) {
  min-width: 150px;
}
.item td:nth-child(4) {
  min-width: 170px;
}
.item td:nth-child(5) {
  min-width: 170px;
}
.item td:last-child {
  min-width: 100px;
  padding-right: 3px;
  border-radius: 0 4px 4px 0;
}
.colorBlack {
  color: #13171C;
}
.icon {
  display: inline-block;
  vertical-align: middle;
  margin-left: 3px;
}
.marginBottom {
  margin-bottom: 2px;
}

@media screen and (min-width: $screenMinWidthM) {
  .item {
    cursor: pointer;
    transition: background ease .3s;
  }
  .item:hover {
    background-color: #ECF5FF;
  }
}

@media screen and (min-width: 1400px) {
  .item {
    font-size: 16px;
  }
}
