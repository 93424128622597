@import "../../../../css/variables.scss";

.backupCodeModal {
  max-width: 100%;
  max-width: 400px;
  position: relative;
  .closeButton {
    z-index: 2;
    position: absolute;
    width: 20px;
    height: 20px;
    right: -10px;
    top: -5px;
    cursor: pointer;
    &::after,
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: calc(50% - 1px);
      width: 2px;
      height: 20px;
      background-color: $colorPink;
    }
    &::after {
      transform: rotate(45deg);
    }
    &::before {
      transform: rotate(-45deg);
    }
  }
  .modalHeader {
    margin: 10px 0;
  }
  p {
    color: $colorGray;
    font-size: 16px;
  }
  .backupKey {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
    color: $colorBlue;
    background-color: $notificationBgColor;
    border: 1px solid $notificationBorderColor;
    padding: 10px 15px 10px 15px;
  }
}
