@import "../../../css/variables";

.userDataInfoBox {
  overflow-x: auto;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}
.userDataInfo {
  min-width: 280px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
}
.userDataInfoHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  letter-spacing: 0.6px;
  color: #7E7E7E;
}
.userDataInfoBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
  color: #A4A4A4;
  margin-left: 15px;
}
.userDataInfoHeaderItem,
.userDataInfoBodyItem {
  margin-top: 12px;
}
.userDataInfoHeaderItem:first-child,
.userDataInfoBodyItem:first-child {
  margin-top: 0;
}
.userDataInfoHeaderItemElement,
.userDataInfoBodyItemElement {
  margin-left: 4px;
}
.colorBlack {
  color: #13171C;
}
.wordBreak {
  word-break: break-word;
}
.priceFlex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}
.landscape .userDataInfo {
  display: block;
}
.landscape .userDataInfoHeader {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  letter-spacing: 0.6px;
  color: #7E7E7E;
}
.landscape .userDataInfoBody {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  margin-left: 0;
  border-top: 1px solid #eeeeee;
  margin-top: 10px;
  padding-top: 10px;
}
.landscape .userDataInfoHeaderItem,
.landscape .userDataInfoBodyItem {
  margin-top: 0;
}

@media screen and (min-width: $screenMinWidthM) {
  .userDataInfo {
    padding-left: 24px;
    padding-right: 24px;
  }
  .userDataInfoBodyItem {
    padding-right: 8px;
  }
}
